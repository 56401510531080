import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { listAnnouncementsQuery, getHealthQuery, getLatencyQuery } from "@/common/services/graphql.service";
import { useApiStore } from "./api";

export const useHealthStore = defineStore(
  "health",
  () => {
    const apiStore = useApiStore();

    const list = ref([]);
    const latency = ref(0);
    const latencyError = ref(false);
    const pingLatencyArray = ref([]);
    const HeartbeatLatency = ref(null);
    const HeartbeatLatencyError = ref(false);
    const HeartbeatLatencyStatus = ref(false);

    const getLatencyGetter = computed(() => latency.value);
    const getLatencyError = computed(() => latencyError.value);
    const getHeartBeatLatencyError = computed(() => HeartbeatLatencyError.value);
    const getHeartbeatConnectingStatus = computed(() => HeartbeatLatencyStatus.value);

    function fetchHealth() {
      return getHealthQuery().then((res) => {
        setHealth(res?.getHealth.events);
        apiStore.setHealth(res?.getHealth.events);
        return Promise.resolve(res);
      });
    }
    async function loadMoreAnnouncements(payload) {
      try {
        const res = await listAnnouncementsQuery(payload);
        return res;
      } catch (err) {
        return err;
      }
    }
    function resetPingLatencyArray() {
      resetPingLatencyArrayMutation();
    }
    function setLatency(payload) {
      setLatencyMutation(payload.toFixed(0));
    }
    function setLatencyError(payload) {
      setLatencyErrorMutation(payload);
    }
    function addPingLatency(payload) {
      addPingLatencyMutation(payload);
    }

    async function getLatency() {
      const startTime = performance.now();
      await getLatencyQuery({
        test: "ping",
      });
      const endTime = performance.now();
      const pingLatency = endTime - startTime;
      addPingLatency(pingLatency);
    }
    function calculateLatency() {
      let pingLatencyArray = pingLatencyArray.value;
      pingLatencyArray.shift();
      const total = pingLatencyArray.reduce((a, b) => a + b, 0);
      const avg = total / pingLatencyArray.length;
      setLatency(avg);
    }

    async function runTestEndpoint(payload) {
      setLatency(0);
      setLatencyError(false);
      resetPingLatencyArray();
      let run = payload || 10;
      for (let index = 0; index < run; index++) {
        try {
          await getLatency();
        } catch (error) {
          console.error("latency endpoint error: ", error);
          setLatencyError(true);
          setLatency(0);
          return;
        }
      }
      if (pingLatencyArray.value.length <= 1) {
        setLatencyError(true);
        return;
      }
      if (latencyError.value) return;
      calculateLatency();
    }

    function startHearbeatLatency() {
      setHeartbeatConnectingStatusMutation(true);
      setHeartbeatLatency(0);
    }

    function getHearbeatLatency(pingLatency) {
      const payload = pingLatency;
      updateHeartbeatLatencyMutation(parseInt(payload));
      setHeartbeatLatencyErrorMutation(false);
      setHeartbeatConnectingStatusMutation(false);
    }

    function setHeartbeatLatency(pingLatency) {
      const payload = pingLatency;
      setHeartbeatLatencyMutation(payload);
      setHeartbeatLatencyErrorMutation(false);
    }
    function setHeartbeatLatencyError(error) {
      setHeartbeatLatencyErrorMutation(error);
      setHeartbeatLatencyMutation(0);
    }
    function setHealth(payload) {
      list.value = payload;
    }
    function initHealth() {
      list.value = [];
    }
    function updateState(value) {
      list.value = value.list;
    }
    function setLatencyMutation(payload) {
      latency.value = parseInt(payload);
    }

    function setLatencyErrorMutation(payload) {
      latencyError.value = payload;
    }

    function addPingLatencyMutation(payload) {
      pingLatencyArray.value.push(payload);
    }
    function resetPingLatencyArrayMutation() {
      pingLatencyArray.value = [];
    }

    function updateHeartbeatLatencyMutation(payload) {
      HeartbeatLatency.value = payload;
    }

    function setHeartbeatLatencyMutation(payload) {
      HeartbeatLatency.value = payload;
    }
    function setHeartbeatLatencyErrorMutation(payload) {
      HeartbeatLatencyError.value = payload;
    }
    function setHeartbeatConnectingStatusMutation(payload) {
      HeartbeatLatencyStatus.value = payload;
    }

    return {
      //States
      list,
      latency,
      latencyError,
      pingLatencyArray,
      HeartbeatLatency,
      HeartbeatLatencyError,
      HeartbeatLatencyStatus,
      //Getters
      getLatencyGetter,
      getLatencyError,
      getHeartBeatLatencyError,
      getHeartbeatConnectingStatus,
      //Actions
      fetchHealth,
      loadMoreAnnouncements,
      resetPingLatencyArray,
      setLatency,
      setLatencyError,
      addPingLatency,
      getLatency,
      calculateLatency,
      runTestEndpoint,
      startHearbeatLatency,
      getHearbeatLatency,
      setHeartbeatLatency,
      setHeartbeatLatencyError,
      //Mutations
      setHealth,
      initHealth,
      updateState,
      setLatencyMutation,
      setLatencyErrorMutation,
      addPingLatencyMutation,
      resetPingLatencyArrayMutation,
      updateHeartbeatLatencyMutation,
      setHeartbeatLatencyMutation,
      setHeartbeatLatencyErrorMutation,
      setHeartbeatConnectingStatusMutation,
    };
  },
  {
    persist: true,
  }
);
