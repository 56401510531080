import { defineStore } from "pinia";
import { computed, ref } from "vue";
import CcpService from "@/common/services/ccpService-shim";
let channelService = null;
window.onload = function () {
  import("@/common/services/channel.service").then((module) => {
    channelService = module.channelService;
  });
};
import Vue from "vue";
import { isEmpty } from "lodash";
import router from "@/router";
import { Notice } from "view-design";
import { i18n } from "@/plugins/language";
import { CHANNELS, ERROR } from "@connectpath/common-frontend/src/constants";
import { enableMonitorContactMutation, getInstanceAttributeQuery } from "@/common/services/graphql.service";
import { useTranslationsStore } from "./translations";
import { useSettingsStore } from "./settings";
import { useAgentsStore } from "./agents";
export const useContactStore = defineStore(
  "contact",
  () => {
    const translationStore = useTranslationsStore();
    const settingStore = useSettingsStore();
    const agentStore = useAgentsStore();
    const contact = ref({
      queue: {},
      summary: {},
      state: {},
      isInbound: false,
      isConnected: false,
      isRecording: false,
      hasToggledRecording: false,
    });
    const incomingContacts = ref([]);
    const activeContacts = ref([]);
    const activeVoiceContact = ref(null);
    const allContacts = ref([]);
    const newIncomingContact = ref(null);
    const lastEndedContact = ref(null);
    const previousIncomingContacts = ref([]);
    const smartDialContact = ref({
      status: "init",
      contactInformation: {},
      accountConnected: "init",
    });
    const smartDialStatus = ref("");
    const smartDialDispositions = ref([]);
    const extension = ref("");
    const selectedChatThreadId = ref(null);
    const chatTranscriptions = ref({});
    const chatRejectedContacts = ref([]);
    const chatReadCounts = ref({});
    const chatUnreadCount = ref(0);
    const activeChatContact = ref(null);
    const lastChatEvent = ref({
      timestamp: 0,
      Type: "",
      ParticipantRole: "",
      contactId: "",
    });
    const holdAll = ref(null);
    const monitorMode = ref(false);
    const isSilentMonitor = ref(false);
    const isBarge = ref(false);
    const isUnderSupervision = ref(false);
    const monitorCapabilities = ref([]);
    const outboundAttributes = ref(false);
    const earlyMedia = ref(false);

    const areAnyOfTheIncomingAttributesTimeout = computed(() => {
      if (!isEmpty(activeVoiceContact.value?.attributes)) {
        const attibutesKeys = Object.keys(activeVoiceContact.value?.attributes);
        for (const attributeKey of attibutesKeys) {
          if (activeVoiceContact.value?.attributes[attributeKey].value === "Timeout") return true;
        }
      }
      return false;
    });

    const holdAllGetter = computed(() => holdAll.value);
    const isRecording = computed(() => contact.value.isRecording);

    const incomingContactsGetter = computed(() => incomingContacts.value);
    const activeContactsGetter = computed(() => activeContacts.value || []);
    const activeVoiceContactGetter = computed(() => activeVoiceContact.value);
    const getActiveChatContact = computed(() => activeChatContact.value);
    const getContactState = computed(() => contact.value.state);
    const allContactsGetter = computed(() => allContacts.value);
    const contactGetter = computed(() => contact.value);
    const getInboundAttributesActiveVoiceContact = computed(() => activeVoiceContact.value?.attributes || null);
    const getOutboundAttributesActiveVoiceContact = computed(() => outboundAttributes.value || null);

    const newIncomingContactGetter = computed(() => newIncomingContact.value);
    const lastEndedContactGetter = computed(() => lastEndedContact.value);
    const connections = computed(() => {
      if (activeVoiceContact?.value) {
        return activeVoiceContact.value?.summary.Connections.all;
      }
      return null;
    });
    const initial = computed(() => {
      if (activeVoiceContact.value) {
        return activeVoiceContact.value.summary.Connections.initial;
      }
      return null;
    });
    const thirdParties = computed(() => {
      if (activeVoiceContact.value) {
        let thirdParty = activeVoiceContact.value.summary.Connections.thirdParty;
        return thirdParty.filter((conn) => {
          let status = conn.status.type;
          return status !== "disconnected";
        });
      }
      return [];
    });
    const contactState = computed(() => {
      if (activeVoiceContact.value) {
        const callState = activeVoiceContact.value.state;
        return Object.prototype.hasOwnProperty.call(callState, "type") ? callState.type : null;
      }
      return null;
    });
    const isContactConnected = computed(() => (contact.value ? contact.value.isConnected : null));
    const contactQueueName = computed(() => (contact.value.queue ? contact.value.queue.name : null));
    const contactPhoneNumber = computed(() => (contact.value ? contact.value.phoneNumber : null));
    const isInbound = computed(() => (contact.value ? contact.value.isInbound : null));
    const contactSummary = computed(() => contact.value.summary);
    const extensionNumber = computed(() => extension.value);

    const getSelectedChatThreadId = computed(() => selectedChatThreadId.value);
    const getChatTranscriptions = computed(() => chatTranscriptions.value);
    const getChatReadCounts = computed(() => chatReadCounts.value);
    const getChatUnreadCount = computed(() => chatUnreadCount.value);
    const getChatRejectedContacts = computed(() => chatRejectedContacts.value);

    const isOnCallSmartDial = computed(() => smartDialContact.value.status !== "init");
    const smartDialContactInfo = computed(() => smartDialContact.value.contactInformation);
    const getSmartDialStatus = computed(() => smartDialStatus.value);
    const getSmdDispositions = computed(() => smartDialDispositions.value);
    const getMonitorMode = computed(() => monitorMode.value);
    const getIsSilentMonitor = computed(() => isSilentMonitor.value);
    const getIsBarge = computed(() => isBarge.value);
    const getIsUnderSupervision = computed(() => isUnderSupervision.value);
    const getMonitorCapabilities = computed(() => monitorCapabilities.value);
    const getEarlyMedia = computed(() => earlyMedia.value);

    function setContactStateAction(payload) {
      setContactState(payload);
    }
    function clearContact() {
      return channelService.clearContact();
    }

    function fetchContact(contact) {
      return new Promise((resolve) => {
        let _contact = channelService.getContact(contact);
        setContactState(_contact);
        resolve(_contact);
      });
    }

    function acceptContact(contactId) {
      return new Promise(() => {
        channelService.acceptContact(contactId);
      });
    }

    function destroyContact(contact) {
      return new Promise((resolve) => {
        let _contact = channelService.getContact(contact);
        setContactState(_contact);
        resolve(_contact);
      });
    }

    function addConnection(phoneNumber) {
      return CcpService.addConnection(phoneNumber);
    }

    function conferenceConnections() {
      channelService.conferenceConnections();
    }

    function createContact({ endpoint, queue }) {
      return channelService.createContact(endpoint, "voice", queue);
    }

    function holdConnections() {
      channelService.holdConnections();
    }

    function resumeConnections() {
      channelService.resumeConnections();
    }

    function isSilentMonitorAction() {
      return channelService.isSilentMonitor();
    }

    function isBargeAction() {
      return channelService.isBarge();
    }

    function updateToSilentMonitor() {
      return channelService.updateToSilentMonitor();
    }

    function updateToBarge() {
      return channelService.updateToBarge();
    }

    function isUnderSupervisionAction() {
      return channelService.isUnderSupervision();
    }

    async function transferConnection(endpoint) {
      await channelService.transferConnection(endpoint);
    }

    async function blindTransferConnection(endpoint) {
      await channelService.blindTransferConnection(endpoint);
    }

    function updateReadCount(payload) {
      setChatReadCountById(payload);
    }

    function setSMContact(payload) {
      setSmartDialContact(payload);
    }

    function setExtensionAction(payload) {
      setExtension(payload);
    }

    function setChatTranscriptionById(payload) {
      setChatTranscriptionByIdMutation(payload);
    }
    async function setChatTranslationOnthead({ customerLanguage, transcription, selectedChatThreadId }) {
      try {
        const newData = await Promise.all(
          transcription.map(async (message) => {
            let newObj = Object.assign({}, message);

            if (newObj.Content) {
              let originalMessage = newObj.Content;
              if (originalMessage.includes("|")) {
                const indexofSlash = originalMessage.indexOf("|");
                originalMessage = originalMessage.substring(0, indexofSlash);
              }
              let payload = {
                content: originalMessage,
                targetLang: customerLanguage,
              };

              let agentTranslation = await translationStore.createTranslation(payload);
              let response = agentTranslation.TranslatedText;
              newObj.Content = `${originalMessage}${"|"}${response}`;
              newObj = { ...newObj, Lang: agentTranslation.sourceLang };
            }

            return newObj;
          })
        );

        transcription = newData;
      } catch (error) {
        console.error(error);
      }
      setChatTranscriptionByIdMutation({
        id: selectedChatThreadId,
        transcription,
      });
      return transcription;
    }

    async function getCustomerLanguage(Message) {
      const agentLanguage = settingStore.currentUserLanguage;
      try {
        if (!isEmpty(Message)) {
          if (Message.includes("|")) {
            const indexofSlash = Message.indexOf("|");
            Message = Message.substring(0, indexofSlash);
          }
          let customerPayload = {
            content: Message,
            targetLang: agentLanguage,
          };

          const customertranslation = await translationStore.createTranslation(customerPayload);
          return customertranslation.sourceLang;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    async function monitorContact({ ContactId, Action, InstanceId, UserId }) {
      Notice.success({
        title: `${i18n.t("monitorContact.initializingMonitoring")}...`,
      });
      try {
        setMonitorMode({ status: true });
        await enableMonitorContactMutation({
          ContactId,
          Action,
          InstanceId,
          UserId,
        });
        setActiveChatContacts();
      } catch (error) {
        console.error(error);
        setMonitorMode({ status: false });
        Notice.error({
          title: `${i18n.t("monitorContact.monitoringInitializationFailed")}`,
          duration: 20,
          render: (h) => {
            return h("p", [
              `${i18n.t("monitorContact.pleaseReviewTheDocumentation")} `,
              h(
                "a",
                { attrs: { href: `${process.env.VUE_APP_MONITOR_DOCUMENTATION_URL}`, target: "_blank" } },
                `${i18n.t("monitorContact.here")}`
              ),
            ]);
          },
        });
      }
    }
    function updateMonitorMode({ status }) {
      setMonitorMode(status);
    }

    async function transferTask(payload) {
      const { ContactId, Endpoint } = payload;

      const allContacts = channelService.listContacts();
      const contactToTransfer = allContacts.find((contact) => contact.contactId === ContactId);

      return await contactToTransfer.transfer(Endpoint);
    }
    async function getInstanceAttribute(payload) {
      try {
        const result = await getInstanceAttributeQuery({ input: payload });

        setEarlyMedia(result?.getInstanceAttribute?.Attribute?.Value === true);
      } catch (error) {
        console.error("Error::", error);
        throw error;
      }
    }

    function setContactState(payload) {
      contact.value.state = payload;
    }
    function putAllContactsOnHold() {
      holdAll.value = true;
    }
    function unholdAllContacts() {
      holdAll.value = null;
    }

    function toggleRecording() {
      Vue.set(contact.value, "isRecording", !contact.value.isRecording);
      Vue.set(contact.value, "hasToggledRecording", true);
    }

    function setContact(payloadContact) {
      contact.value = { ...contact.value, ...payloadContact };
      outboundAttributes.value = payloadContact?.root?.getAttributes();
      incomingContacts.value = channelService.listIncomingContacts();
      activeContacts.value = channelService.listActiveContacts();
      const agentStatus = agentStore.agentStatus?.name;
      const activeVoiceContacts = activeContacts.value.find(
        (contact) => (contact.type === "voice" || contact.type === "queue_callback") && contact.state.type !== "error"
      );
      const activeChatContacts = activeContacts.value.find(
        (contact) => contact.type === "chat" && contact.state.type !== "error"
      );

      if (!contact.value.hasToggledRecording) {
        let attributes = payloadContact?.root?.getAttributes();

        if (attributes && Object.prototype.hasOwnProperty.call(attributes, "IsRecording")) {
          Vue.set(contact.value, "isRecording", payloadContact?.root?.getAttributes()?.IsRecording?.value == "true" || false);
        } else {
          Vue.set(contact.value, "isRecording", true);
        }
      }

      if (agentStatus == "Busy" || agentStatus == "AfterContactWork") {
        if (activeVoiceContacts != null) {
          activeVoiceContact.value = activeVoiceContacts;
        }
        if (payloadContact.contactDestroyed) {
          contact.value.hasToggledRecording = false;
          contact.value.isRecording = false;
          activeVoiceContact.value = null;
        }
      } else {
        activeVoiceContact.value = activeVoiceContacts;
        activeChatContact.value = activeChatContacts;
      }

      allContacts.value = channelService.listContacts();
    }

    function resetActiveContact(payload) {
      let i = activeContacts.value.find((contactLocal) => payload.oldcontact.contactId == contactLocal.contactId);
      if (i >= 0) {
        activeContacts.value.splice(i, 1);
      }
      activeVoiceContact.value = payload.newContact;
    }

    function setNewIncomingContact(contact) {
      newIncomingContact.value = contact;
    }

    function setLastEndedContact(contact) {
      lastEndedContact.value = contact;
      previousIncomingContacts.value = [];
    }

    function clearIncomingContact() {
      newIncomingContact.value = null;
    }

    function setSelectedChatThreadId(payload) {
      selectedChatThreadId.value = payload;
    }

    function setChatTranscriptionByIdMutation(payload) {
      chatTranscriptions.value[payload.id] = payload.transcription;
      if (!chatReadCounts.value[payload.id]) {
        chatReadCounts.value[payload.id] = 0;
      }
      let unreadCount = 0;
      if (
        Object.prototype.hasOwnProperty.call(chatReadCounts.value, payload.id) &&
        Object.prototype.hasOwnProperty.call(chatTranscriptions.value, payload.id)
      ) {
        Object.keys(chatTranscriptions.value).forEach((key) => {
          for (let contact of activeContacts.value) {
            if (contact.contactId === key) {
              if (!chatReadCounts.value[key]) {
                unreadCount = unreadCount + chatTranscriptions.value[key].length;
              } else {
                unreadCount = unreadCount + chatTranscriptions.value[key].length - chatReadCounts.value[key];
              }
            }
          }
        });
      }
      if (isNaN(unreadCount) || unreadCount < 0) unreadCount = 0;
      chatUnreadCount.value = unreadCount;
    }

    function setChatReadCountById(payload) {
      chatReadCounts.value[payload.id] = payload.readCount;
      let unreadCount = 0;
      if (
        Object.prototype.hasOwnProperty.call(chatReadCounts.value, payload.id) &&
        Object.prototype.hasOwnProperty.call(chatTranscriptions.value, payload.id)
      ) {
        Object.keys(chatTranscriptions.value).forEach((key) => {
          for (let contact of activeContacts.value) {
            if (contact.contactId === key) {
              if (chatReadCounts.value[key] > chatTranscriptions.value[key].length) {
                chatReadCounts.value[key] = chatTranscriptions.value[key].length;
              }
              if (!chatReadCounts.value[key]) {
                unreadCount = unreadCount + chatTranscriptions.value[key].length;
              } else {
                unreadCount = unreadCount + chatTranscriptions.value[key].length - chatReadCounts.value[key];
              }
            }
          }
        });
      }
      if (isNaN(unreadCount) || unreadCount < 0) unreadCount = 0;
      chatUnreadCount.value = unreadCount;
    }

    function setLastChatEvent(payload) {
      lastChatEvent.value = payload;
    }

    function setChatRejectedContacts(payload) {
      chatRejectedContacts.value = payload;
    }

    function initContact() {
      contact.value = {
        queue: {},
        summary: {},
        state: {},
        isInbound: false,
        isConnected: false,
      };
      incomingContacts.value = [];
      activeContacts.value = [];
      activeVoiceContact.value = null;
      activeChatContact.value = null;
      allContacts.value = [];
      newIncomingContact.value = null;
      lastEndedContact.value = null;
      previousIncomingContacts.value = [];
      selectedChatThreadId.value = null;
      chatTranscriptions.value = {};
      chatRejectedContacts.value = [];
      chatReadCounts.value = {};
      chatUnreadCount.value = 0;
      lastChatEvent.value = {
        timestamp: 0,
        Type: "",
        ParticipantRole: "",
        contactId: "",
      };
      outboundAttributes.value = null;
    }

    function updateState(value) {
      if (value) {
        contact.value = value.contact;
        incomingContacts.value = value.incomingContacts;
        activeContacts.value = value.activeContacts;
        activeVoiceContact.value = value.activeVoiceContact;
        activeChatContact.value = value.activeChatContact;
        allContacts.value = value.allContacts;
        newIncomingContact.value = value.newIncomingContact;
        lastEndedContact.value = value.lastEndedContact;
        previousIncomingContacts.value = value.previousIncomingContacts;
        selectedChatThreadId.value = value.selectedChatThreadId;
        chatTranscriptions.value = value.chatTranscriptions;
        chatRejectedContacts.value = value.chatRejectedContacts;
        chatReadCounts.value = value.chatReadCounts;
        chatUnreadCount.value = value.chatUnreadCount;
        lastChatEvent.value = value.lastChatEvent;
        outboundAttributes.value = value.outboundAttributes;
      }
    }

    function setSmartDialContact(state, payload) {
      smartDialContact.value = { ...(smartDialContact.value ?? {}), ...payload };
      if (payload.status === "preview-incoming") {
        if (router.currentRoute.path !== "/engage") {
          router.push("/engage");
        }
      }
    }

    function setSMDStatus(message) {
      smartDialStatus.value = message;
    }

    function setSmdDispositions(payload) {
      smartDialDispositions.value = [...payload];
    }
    function setIsSilentMonitor(payload) {
      isSilentMonitor.value = payload;
    }

    function setIsBarge(payload) {
      isBarge.value = payload;
    }

    function setIsUnderSupervision(payload) {
      isUnderSupervision.value = payload;
    }

    function setMonitorCapabilities(payload) {
      monitorCapabilities.value = payload;
    }

    function setMonitorMode(payload) {
      monitorMode.value = payload;
    }

    function setExtension(payload) {
      extension.value = payload;
    }
    function setActiveChatContacts() {
      const chatContcts = activeContacts.value.find(
        (contact) => contact.type === CHANNELS.CHAT && contact.state.type !== ERROR
      );
      activeChatContact.value = chatContcts;
    }
    function setEarlyMedia(state, payload) {
      earlyMedia.value = payload;
    }
    return {
      //States
      contact,
      incomingContacts,
      activeContacts,
      activeVoiceContact,
      allContacts,
      newIncomingContact,
      lastEndedContact,
      previousIncomingContacts,
      smartDialContact,
      smartDialStatus,
      smartDialDispositions,
      extension,
      selectedChatThreadId,
      chatTranscriptions,
      chatRejectedContacts,
      chatReadCounts,
      chatUnreadCount,
      activeChatContact,
      lastChatEvent,
      holdAll,
      monitorMode,
      isSilentMonitor,
      isBarge,
      isUnderSupervision,
      monitorCapabilities,
      outboundAttributes,
      earlyMedia,
      //Getters
      areAnyOfTheIncomingAttributesTimeout,
      holdAllGetter,
      isRecording,
      incomingContactsGetter,
      activeContactsGetter,
      activeVoiceContactGetter,
      getActiveChatContact,
      getContactState,
      allContactsGetter,
      contactGetter,
      getInboundAttributesActiveVoiceContact,
      getOutboundAttributesActiveVoiceContact,
      newIncomingContactGetter,
      lastEndedContactGetter,
      connections,
      initial,
      thirdParties,
      contactState,
      isContactConnected,
      contactQueueName,
      contactPhoneNumber,
      isInbound,
      contactSummary,
      extensionNumber,
      getSelectedChatThreadId,
      getChatTranscriptions,
      getChatReadCounts,
      getChatUnreadCount,
      getChatRejectedContacts,
      isOnCallSmartDial,
      smartDialContactInfo,
      getSmartDialStatus,
      getSmdDispositions,
      getMonitorMode,
      getIsSilentMonitor,
      getIsBarge,
      getIsUnderSupervision,
      getMonitorCapabilities,
      getEarlyMedia,
      //Actions
      setContactStateAction,
      clearContact,
      fetchContact,
      acceptContact,
      destroyContact,
      addConnection,
      conferenceConnections,
      createContact,
      holdConnections,
      resumeConnections,
      isSilentMonitorAction,
      isBargeAction,
      updateToSilentMonitor,
      updateToBarge,
      isUnderSupervisionAction,
      transferConnection,
      blindTransferConnection,
      updateReadCount,
      setSMContact,
      setExtensionAction,
      setChatTranscriptionById,
      setChatTranslationOnthead,
      getCustomerLanguage,
      monitorContact,
      updateMonitorMode,
      transferTask,
      getInstanceAttribute,
      //Mutations
      setContactState,
      putAllContactsOnHold,
      unholdAllContacts,
      toggleRecording,
      setContact,
      resetActiveContact,
      setNewIncomingContact,
      setLastEndedContact,
      clearIncomingContact,
      setSelectedChatThreadId,
      setChatTranscriptionByIdMutation,
      setChatReadCountById,
      setLastChatEvent,
      setChatRejectedContacts,
      initContact,
      updateState,
      setSmartDialContact,
      setSMDStatus,
      setSmdDispositions,
      setIsSilentMonitor,
      setIsBarge,
      setIsUnderSupervision,
      setMonitorCapabilities,
      setMonitorMode,
      setExtension,
      setActiveChatContacts,
      setEarlyMedia,
    };
  },
  {
    persist: true,
  }
);
