import { defineStore } from "pinia";
import { computed, ref } from "vue";
import VueCookie from "vue-cookie";
import { Auth } from "aws-amplify";
import AmplifyHandler from "@/plugins/amplify";
import { heartbeatMutation } from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useLogsStore } from "./logs";
import { useIndexStore } from ".";

const amplify = new AmplifyHandler();

export const useAuthenticationStore = defineStore(
  "authentication",
  () => {
    const agentStore = useAgentsStore();
    const logStore = useLogsStore();
    const indexStore = useIndexStore();
    const isAutenticatedInAmplify = ref(false);
    const ccpUrl = ref(null);
    const isEnabledCcp = ref(false);

    const isAutenticatedInAmplifyGetter = computed(() => isAutenticatedInAmplify.value);
    const ccpUrlGetter = computed(() => ccpUrl.value);
    const isEnabledCcpGetter = computed(() => isEnabledCcp.value);

    async function setSession() {
      if (!ccpUrlGetter.value) {
        setCcpUrl();
      }
      await setIsAutenticatedInAmplify();
    }
    async function setIsAutenticatedInAmplify() {
      try {
        await amplify.configure(window.localStorage.getItem("connect-region"));
        const response = await amplify.isAutenticated();
        setIsAutenticatedInAmplifyMutation(response);
      } catch (error) {
        console.error(error);
        setIsAutenticatedInAmplifyMutation(null);
      }
    }
    async function logOut() {
      const offline = agentStore.agentStates.filter((state) => {
        return state.type === "offline";
      });
      agentStore.newStateAction(offline[0]);
      window.isLoggingOut = true;
      logStore.addItem({
        component: "DEXTR",
        level: "INFO",
        text: `LOGOUT - ${agentStore.agentSummary.Username} - ${agentStore.agentSummary.InstanceId}`,
      });
      // await Socket.close();
      const ccpUrl = window.localStorage.getItem("ccpUrl") || ccpUrlGetter;
      let logoutWindow = window.open(`https://${ccpUrl}/connect/logout`, "_blank");

      indexStore.updateAuthentication(false);
      agentStore.setConnectedMutation(false);

      try {
        await heartbeatMutation({
          InstanceId: agentStore.agentSummary.InstanceId,
          Username: agentStore.agentSummary.Username,
          StatusName: "Offline",
          StatusDuration: 1000,
        });

        Auth.configure(Auth._config);
        await Auth.signOut({ global: true });

        if (window.intervalUpdateUser) {
          clearInterval(window.intervalUpdateUser);
          window.intervalUpdateUser = null;
        }

        this.signoutTimeout = setTimeout(() => {
          indexStore.SET_HYBERNATE("SET_HYBERNATE", false);
          let alias = window.localStorage.getItem("ccpInstanceAlias");
          let region = window.localStorage.getItem("connect-region");
          let defaultCountry = window.localStorage.getItem("defaultCountry");
          let showNativeCcp = window.localStorage.getItem("showNativeCcp");
          let language = window.localStorage.getItem("language");

          window.localStorage.clear();
          window.localStorage.setItem("connect-region", region);
          window.localStorage.setItem("ccpInstanceAlias", alias);
          window.localStorage.setItem("defaultCountry", defaultCountry);
          window.localStorage.setItem("showNativeCcp", showNativeCcp);
          window.localStorage.setItem("language", language);

          if (!window.location.origin) {
            window.location.origin = `${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ""
            }`;
          }

          if (VueCookie.get("dextr_subdomain")) {
            window.location.href = `${window.location.origin}/${VueCookie.get("dextr_subdomain")}`;
            return false;
          }

          window.location.href = window.location.origin;
          logoutWindow.close();
        }, 1000);
      } catch (error) {
        console.error(error);
      }
    }
    function updateCcpStatus(payload) {
      setCCPStatus(payload);
    }
    function setIsAutenticatedInAmplifyMutation(value) {
      isAutenticatedInAmplify.value = value;
    }
    function setCcpUrl(url) {
      ccpUrl.value = url;
    }
    function setCCPStatus(payload) {
      isEnabledCcp.value = payload;
    }

    return {
      //States
      isAutenticatedInAmplify,
      ccpUrl,
      isEnabledCcp,
      //Getters
      isAutenticatedInAmplifyGetter,
      ccpUrlGetter,
      isEnabledCcpGetter,
      //Actions
      setSession,
      setIsAutenticatedInAmplify,
      logOut,
      updateCcpStatus,
      //Mutations
      setIsAutenticatedInAmplifyMutation,
      setCcpUrl,
      setCCPStatus,
    };
  },
  {
    persist: true,
  }
);
