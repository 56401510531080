import { defineStore } from "pinia";
import { computed, ref } from "vue";
import _ from "lodash";
import cleanedPhoneNumber from "@/common/filters/phoneNumber.filter";
import {
  listSupportCasesQuery,
  onGlobalContactSubscription,
  onDeleteGlobalContactSubscription,
  listContactsQuery,
  createContactMutation,
  updateContactMutation,
  deleteContactMutation,
  updateFavoriteItemsMutation,
} from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useSupportStore } from "./support";
import { useIndexStore } from ".";
import { useTeamStore } from "./team";

export const useContactsStore = defineStore(
  "contacts",
  () => {
    const agentStore = useAgentsStore();
    const supportStore = useSupportStore();
    const indexStore = useIndexStore();
    const teamStore = useTeamStore();
    const list = ref({
      items: [],
    });
    const favoritesList = ref([]);

    const favorites = computed(() => {
      if (favoritesList.value && "items" in list.value && favoritesList.value.length > 0) {
        return list.value.items.filter((item) => favoritesList.value.includes(item.Id));
      }
      return [];
    });

    const matchContact = (endpoint) => {
      let matched = list.value.items.find((item) => {
        return (
          cleanedPhoneNumber(item.CellPhone) == cleanedPhoneNumber(endpoint) ||
          cleanedPhoneNumber(item.HomePhone) == cleanedPhoneNumber(endpoint) ||
          cleanedPhoneNumber(item.WorkPhone) == cleanedPhoneNumber(endpoint)
        );
      });
      return _.get(matched, "Name", endpoint);
    };
    const getContacts = computed(() => list.value.items);

    async function getSupportCaseData({ account }) {
      let res = await listSupportCasesQuery(account);

      supportStore.setCaseList(res?.listSupportCases);
    }

    async function globalContactsSub(payload) {
      const observable = await onGlobalContactSubscription({
        InstanceId: payload,
        IsGlobal: true,
      });

      const handler = {
        next: (eventData) => {
          const globalContact = eventData.value?.onGlobalContact;

          if (globalContact) {
            delete globalContact.__typename;
            let currentContacts = list.value.items;

            let index = currentContacts.findIndex((x) => x.Id === globalContact.Id);

            if (index === -1) currentContacts.push(globalContact);
            else currentContacts.splice(index, 1, globalContact);
          }

          return Promise.resolve(eventData);
        },
      };

      indexStore.addSubscriptionToList({ id: "globalContactsSub", observable, handler });
    }

    async function deleteGlobalContactsSub(payload) {
      const observable = await onDeleteGlobalContactSubscription({
        InstanceId: payload,
        IsGlobal: true,
      });

      const handler = {
        next: (eventData) => {
          const globalContact = eventData.value?.onDeleteGlobalContact;

          if (globalContact) {
            let currentContacts = list.value.items;

            let index = currentContacts.findIndex((x) => x.Id === globalContact.Id);

            if (index !== -1) currentContacts.splice(index, 1);
          }

          return Promise.resolve(eventData);
        },
      };
      indexStore.addSubscriptionToList({ id: "deleteGlobalContactsSub", observable, handler });
    }
    async function onFetchContacts({ InstanceId, UserId }) {
      try {
        let res = await listContactsQuery({ InstanceId, UserId });

        setContactList(res?.listContacts);
        let customEndpoints = createCustomEndpoints(res?.listContacts.items);
        agentStore.appendCustomEndpoints(customEndpoints);

        return Promise.resolve(res?.listContacts);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    async function onCreateContact({ payload }) {
      try {
        let res = await createContactMutation(payload);
        addContact(res?.createContact);
        let customEndpoints = createCustomEndpoints(getContacts.value);
        agentStore.appendCustomEndpoints(customEndpoints);
        return Promise.resolve(res?.createContact);
      } catch (err) {
        return Promise.reject("Error", err);
      }
    }
    async function onUpdateContact({ payload }) {
      try {
        delete payload.__typename;
        let res = await updateContactMutation(payload);

        updateContact(res?.updateContact);
        let customEndpoints = createCustomEndpoints(getContacts.value);
        agentStore.appendCustomEndpoints(customEndpoints);
        return Promise.resolve(res?.updateContact);
      } catch (err) {
        return Promise.reject("Error", err);
      }
    }
    async function onDeleteContact({ payload }) {
      try {
        delete payload.__typename;
        let res = await deleteContactMutation(payload);
        deleteContact(res?.deleteContact);
        return Promise.resolve(res?.deleteContact);
      } catch (err) {
        return Promise.reject("Error", err);
      }
    }
    async function favoriteDirectoryItem({ InstanceId, Username, DirectoryId }) {
      try {
        toggleFavoriteContact(DirectoryId);
        await updateFavoriteItemsMutation({
          InstanceId,
          Username,
          DirectoryId,
        });
      } catch (ex) {
        console.error(ex);
        teamStore.showErrorMessage("Failed to add to favorite.");
      }
    }
    function createCustomEndpoints(contacts) {
      let _contacts = [];
      contacts.forEach((contact) => {
        if (contact.WorkPhone) {
          let endpoint = window.connect.Endpoint.byPhoneNumber(contact.WorkPhone);
          endpoint.name = `${contact.Name} (Work: ${contact.WorkPhone})`;
          _contacts.push(endpoint);
        }
        if (contact.HomePhone) {
          let endpoint = window.connect.Endpoint.byPhoneNumber(contact.HomePhone);
          endpoint.name = `${contact.Name} (Home: ${contact.HomePhone})`;
          _contacts.push(endpoint);
        }
        if (contact.CellPhone) {
          let endpoint = window.connect.Endpoint.byPhoneNumber(contact.CellPhone);
          endpoint.name = `${contact.Name} (Cell: ${contact.CellPhone})`;
          _contacts.push(endpoint);
        }
      });
      return _contacts;
    }
    function addContact(payload) {
      list.value.items.push(payload);
    }
    function deleteContact(payload) {
      let i = list.value.items.findIndex((itm) => itm.Id === payload.Id);
      i === -1 || list.value.items.splice(i, 1);
    }
    function updateContact(payload) {
      let i = list.value.items.findIndex((itm) => itm.Id === payload.Id);
      i === -1 || list.value.items.splice(i, 1, payload);
    }
    function setContactList(payload) {
      list.value = {
        items: payload.items.sort((a, b) => a.Name.toLowerCase().localeCompare(b.Name.toLowerCase())),
      };
    }
    function setFavoriteContacts(contacts) {
      favoritesList.value = contacts;
    }
    function toggleFavoriteContact(id) {
      const foundIndex = favoritesList.value.findIndex((x) => x === id);

      if (foundIndex === -1) {
        favoritesList.value.push(id);
      } else {
        favoritesList.value.splice(foundIndex, 1);
      }
    }
    function initContacts() {
      list.value = {
        items: [],
      };
      favoritesList.value = [];
    }
    function updateState(value) {
      list.value = value.list;
      favoritesList.value = value.favoritesList;
    }

    return {
      //States
      list,
      favoritesList,
      //Getters
      favorites,
      matchContact,
      getContacts,
      //Actions
      getSupportCaseData,
      globalContactsSub,
      deleteGlobalContactsSub,
      onFetchContacts,
      onCreateContact,
      onUpdateContact,
      onDeleteContact,
      favoriteDirectoryItem,
      //Mutations
      addContact,
      deleteContact,
      updateContact,
      setContactList,
      setFavoriteContacts,
      toggleFavoriteContact,
      initContacts,
      updateState,
    };
  },
  {
    persist: true,
  }
);
