import { defineStore } from "pinia";
import { computed, ref } from "vue";
import _, { get } from "lodash";
import Vue from "vue";
import { useCurrentUserStore } from "./current-user";
export const useApiStore = defineStore(
  "api",
  () => {
    const currentUserStore = useCurrentUserStore();
    const api = ref({
      validCreds: false,
      getUser: {
        Id: null,
        Preferences: {
          JabraGen: "Gen 2",
          CallEndTone: "default",
          ColumnArrangments: [],
          PrimaryRinger: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          SecondaryRinger: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          PrimaryRingerEmail: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          SecondaryRingerEmail: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          PrimaryRingerPeer: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          SecondaryRingerPeer: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          DashboardCards: [],
          TeamStatusView: {
            OfflineHidden: false,
            MinimizeRecent: false,
            MinimizeFavorites: false,
            isOpen: false,
            showAllUsers: false,
          },
          Theme: "",
          FavoriteTabName: "",
        },
      },
      localCopy: {
        DashboardCards: [],
      },
      showTeamStatusSection: false,
    });
    const columnArrangements = ref([]);
    const getUser = ref(null);
    const localCopy = ref(null);
    const showTeamStatusSection = ref(null);

    const validCreds = computed(() => api.value.validCreds);
    const getApi = computed(() => api.value);
    const currentUser = computed(() => api.value.getUser);
    const getShowTeamStatusSection = computed(() => api.value.showTeamStatusSection);
    const minimizeRecent = computed(() => {
      return _.get(api.value.getUser.Preferences, "TeamStatusView.MinimizeRecent", false);
    });
    const minimizeFavorites = computed(() => {
      return _.get(api.value.getUser.Preferences, "TeamStatusView.MinimizeFavorites", false);
    });
    const offlineHidden = computed(() => {
      return _.get(api.value.getUser.Preferences, "TeamStatusView.OfflineHidden", false);
    });
    const showAllUsers = computed(() => {
      return _.get(api.value.getUser.Preferences, "TeamStatusView.showAllUsers", false);
    });
    const showTeamStatus = computed(() => {
      return _.get(api.value.getUser.Preferences, "TeamStatusView.isOpen", false);
    });
    const getJabraGen = computed(() => {
      return get(api.value.getUser.Preferences, "JabraGen", "Gen 2");
    });
    const getColumnsArragements = computed(() => columnArrangements.value);
    const isEnabledGoToApplication = computed(() => api.value.getUser.Preferences.IsEnabledGoToApplication);

    function resyncPreferences() {
      let p = _.cloneDeep(api.value.getUser);
      const payload = {
        InstanceId: p.InstanceId,
        Username: p.Username,
        Preferences: p.Preferences,
      };

      currentUserStore.onUpdateCurrentUser(payload).catch(() => {
        console.error("resyncPreferences failed");
      });
    }
    function resetPreferencesColumnArrangments() {
      updatePreferencesColumnArragments([]);
    }

    function addColumnArragement(payload) {
      updateColumnArragement(payload);
    }
    function updateEnabledGoToApplication(payload) {
      setEnabledGoToApplication(payload);
    }
    function setValidCreds(v) {
      api.value.validCreds = v;
    }
    function setActivity(activity) {
      api.value.listActivity = activity;
    }
    function setCurrentUser(user) {
      api.value.getUser = user;
    }
    function updateCurrentUser(user) {
      api.value.getUser = { ...api.value.getUser, ...user };
    }
    function setHealth(health) {
      api.value.getHealth = health;
    }
    function setTeam(team) {
      api.value.listTeam = team;
    }
    function setQueueMetrics(metrics) {
      api.value.getQueueMetrics = metrics;
    }
    function setUsers(users) {
      api.value.listUsers = users;
    }
    function updateHealthEvents(newData) {
      let index = api.value.getHealth.findIndex((event) => event.arn === newData.arn);

      if (index === -1) {
        return api.value.getHealth.push(newData);
      } else {
        return api.value.getHealth.splice(index, 1, newData);
      }
    }
    function needHelp(payload) {
      api.value.getUser.NeedsHelp = payload;
    }

    function moveDashboardCard(payload) {
      let { DashboardCards } = api.value.getUser.Preferences;
      let { targetContainer, sourceContainer, itemId, siblingId } = payload;
      let itemIndex = DashboardCards[sourceContainer].findIndex((item) => {
        return item.id == itemId;
      });

      if (itemIndex !== -1) {
        let movableItem = DashboardCards[sourceContainer][itemIndex];
        DashboardCards[sourceContainer].splice(itemIndex, 1);
        if (siblingId) {
          let sIndex = DashboardCards[targetContainer].findIndex((item) => {
            return item.id == siblingId;
          });
          if (sIndex !== -1) {
            if (sIndex === 0) {
              DashboardCards[targetContainer].unshift(movableItem);
            } else {
              DashboardCards[targetContainer].splice(sIndex, 0, movableItem);
            }
          }
        } else {
          DashboardCards[targetContainer].push(movableItem);
        }
      }
      api.value.localCopy.DashboardCards = DashboardCards;
    }
    function resyncDashboardCards() {
      api.value.localCopy.DashboardCards = _.cloneDeep(api.value.getUser.Preferences.DashboardCards);
    }
    function tsvOfflineHidden(payload) {
      if (api.value.getUser.Preferences.TeamStatusView === null) {
        api.value.getUser.Preferences.TeamStatusView = {};
      }

      if ("OfflineHidden" in api.value.getUser.Preferences.TeamStatusView) {
        api.value.getUser.Preferences.TeamStatusView.OfflineHidden = payload;
      } else {
        Vue.set(api.value.getUser.Preferences.TeamStatusView, "OfflineHidden", payload);
      }
    }

    function tsvMinimizeRecent(payload) {
      if (api.value.getUser.Preferences.TeamStatusView === null) {
        api.value.getUser.Preferences.TeamStatusView = {};
      }
      if ("MinimizeRecent" in api.value.getUser.Preferences.TeamStatusView) {
        api.value.getUser.Preferences.TeamStatusView.MinimizeRecent = payload;
      } else {
        Vue.set(api.value.getUser.Preferences.TeamStatusView, "MinimizeRecent", payload);
      }
    }

    function tsvMinimizeFavorites(payload) {
      if (api.value.getUser.Preferences.TeamStatusView === null) {
        api.value.getUser.Preferences.TeamStatusView = {};
      }
      if ("MinimizeFavorites" in api.value.getUser.Preferences.TeamStatusView) {
        api.value.getUser.Preferences.TeamStatusView.MinimizeFavorites = payload;
      } else {
        Vue.set(api.value.getUser.Preferences.TeamStatusView, "MinimizeFavorites", payload);
      }
    }
    function tsvIsOpen(payload) {
      Vue.set(api.value, "showTeamStatusSection", payload);
      if (api.value.getUser.Preferences.TeamStatusView === null) {
        api.value.getUser.Preferences.TeamStatusView = {};
      }
      if ("isOpen" in api.value.getUser.Preferences.TeamStatusView) {
        api.value.getUser.Preferences.TeamStatusView.isOpen = payload;
      } else {
        Vue.set(api.value.getUser.Preferences.TeamStatusView, "isOpen", payload);
      }
    }

    function tsvShowAllUsers(payload) {
      if (api.value.getUser.Preferences.TeamStatusView === null) {
        api.value.getUser.Preferences.TeamStatusView = {};
      }
      if ("showAllUsers" in api.value.getUser.Preferences.TeamStatusView) {
        api.value.getUser.Preferences.TeamStatusView.showAllUsers = payload;
      } else {
        Vue.set(api.value.getUser.Preferences.TeamStatusView, "showAllUsers", payload);
      }
    }

    function initApi() {
      validCreds.value = false;
      getUser.value = {
        Id: null,
        Preferences: {
          ColumnArrangments: [],
          PrimaryRinger: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          PrimaryRingerEmail: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          SecondaryRinger: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          SecondaryRingerEmail: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          PrimaryRingerPeer: {
            Sound: null,
            Volume: 55,
            DeviceId: null,
          },
          SecondaryRingerPeer: {
            Sound: null,
            isEnabled: false,
            DeviceId: null,
          },
          DashboardCards: [],
          TeamStatusView: {
            OfflineHidden: false,
            MinimizeRecent: false,
            MinimizeFavorites: false,
            isOpen: false,
            showAllUsers: false,
          },
          Theme: "",
          FavoriteTabName: "",
        },
      };
      localCopy.value = {
        DashboardCards: [],
      };
      showTeamStatusSection.value = false;
    }

    function updateApiState(value) {
      validCreds.value = value.validCreds;
      getUser.value = value.getUser;
      localCopy.value = value.localCopy;
      showTeamStatusSection.value = value.showTeamStatusSection;
    }

    function updateColumnArragement(value) {
      columnArrangements.value = value;
    }

    function updatePreferencesColumnArragments(value) {
      api.value.getUser.Preferences.ColumnArrangments = value;
    }
    function setEnabledGoToApplication(value) {
      api.value.getUser.Preferences.IsEnabledGoToApplication = value;
    }
    return {
      //States
      api,
      columnArrangements,
      getUser,
      localCopy,
      showTeamStatusSection,
      //Getters
      validCreds,
      getApi,
      currentUser,
      getShowTeamStatusSection,
      minimizeRecent,
      minimizeFavorites,
      offlineHidden,
      showAllUsers,
      showTeamStatus,
      getJabraGen,
      getColumnsArragements,
      isEnabledGoToApplication,
      //Actions
      resyncPreferences,
      resetPreferencesColumnArrangments,
      addColumnArragement,
      updateEnabledGoToApplication,
      //Mutations
      setValidCreds,
      setActivity,
      setCurrentUser,
      updateCurrentUser,
      setHealth,
      setTeam,
      setQueueMetrics,
      setUsers,
      updateHealthEvents,
      needHelp,
      moveDashboardCard,
      resyncDashboardCards,
      tsvOfflineHidden,
      tsvMinimizeRecent,
      tsvMinimizeFavorites,
      tsvIsOpen,
      tsvShowAllUsers,
      initApi,
      updateApiState,
      updateColumnArragement,
      updatePreferencesColumnArragments,
      setEnabledGoToApplication,
    };
  },
  {
    persist: true,
  }
);
