import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { PeerCallStatus, Engage } from "@/common/constant";
import { AGENT_TO_AGENT_CHIME_CALL, CHANNELS } from "@connectpath/common";
import {
  createAgentChimeMeetingEndpointMutation,
  updateAgentChimeCallEndpointMutation,
  deleteAgentChimeMeetingEndpointMutation,
  getMeetingChimeInfoQuery,
  createAttendeeChimeCallEndpointMutation,
  cleanupAgentCallStatusMutation,
  newAgentChimeCallEventSubscription,
} from "@/common/services/graphql.service";
import { useTeamStore } from "./team";
import { useAgentsStore } from "./agents";
import { useApiStore } from "./api";
import { useIndexStore } from ".";

export const useAgentCallStore = defineStore(
  "agentCall",
  () => {
    const teamStore = useTeamStore();
    const agentStore = useAgentsStore();
    const apiStore = useApiStore();
    const indexStore = useIndexStore();

    const meetingInfo = ref({});
    const attendeeInfo = ref({});
    const meetingInfoRec = ref({});
    const attendeeInfoRec = ref({});
    const audioVideo = ref(null);
    const meetingController = ref(null);
    const muteToggle = ref(true);
    const speakerToggle = ref(false);
    const selectedSpeaker = ref("");
    const selectedMic = ref("");
    const callIsInProgress = ref(false);
    const agentToAgentTaskId = ref("");

    const getMeetingInfo = computed(() => meetingInfo.value);
    const getAttendeeInfo = computed(() => attendeeInfo.value);
    const getMeetingInfoRec = computed(() => meetingInfoRec.value);
    const getAttendeeInfoRec = computed(() => attendeeInfoRec.value);
    const getAudioVideo = computed(() => audioVideo.value);

    const getMeetingController = computed(() => meetingController.value);
    const getMuteStatus = computed(() => muteToggle.value);
    const getVolumeStatus = computed(() => speakerToggle.value);
    const getSelectedSpeaker = computed(() => selectedSpeaker.value);

    const getSelectedMic = computed(() => selectedMic.value);
    const getCallProgress = computed(() => callIsInProgress.value);
    const getTaskId = computed(() => agentToAgentTaskId.value);

    async function createAgentChimeCallEndpoint({ InstanceId, Receiver, ViewUser }) {
      try {
        const res = await createAgentChimeMeetingEndpointMutation({
          input: {
            InstanceId: InstanceId,
            Receiver: Receiver,
          },
        });

        const voiceData = {
          instanceId: agentStore.agentSummary.InstanceId,
          instanceAlias: ViewUser.instanceAlias,
          callee: ViewUser.Username,
          initiator: agentStore.agentSummary.Username,
          token: ViewUser.token,
          callUUID: ViewUser.uuid,
          action: Engage.Channel.SETUP_VOICE_CALL,
        };
        teamStore.setVoiceCallModalFlag(true);
        teamStore.setVoiceCallData(voiceData);
        teamStore.playOutgoingRingtone("");

        teamStore.setCallPlacedFlag(true);
        teamStore.startCallTimer({
          InstanceId: agentStore.agentSummary.InstanceId,
        });
        if (res.createAgentChimeMeeting) {
          setMeetingInfo(res.createAgentChimeMeeting);
          setTaskId(res.createAgentChimeMeeting.ContactId);
          setCallProgress(true);
          return res.createAgentChimeMeeting;
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }

    async function updateAgentChimeCallEndpoint({ InstanceId, MeetingId, Receiver, Initiator, Status }) {
      try {
        const res = await updateAgentChimeCallEndpointMutation({
          input: {
            InstanceId: InstanceId,
            MeetingId: MeetingId,
            Receiver: Receiver,
            Initiator: Initiator,
            Status: Status,
          },
        });

        if (res.updateAgentChimeMeeting) {
          setMeetingInfo(res.updateAgentChimeMeeting);
          setCallProgress(true);
          return res.updateAgentChimeMeeting;
        }
      } catch (error) {
        console.error(`Error in updating meeting ${error}`);
        throw error;
      }
    }
    async function deleteAgentChimeCallEndpoint({ InstanceId, MeetingId }) {
      try {
        const res = await deleteAgentChimeMeetingEndpointMutation({
          input: { InstanceId: InstanceId, MeetingId: MeetingId },
        });

        if (res) {
          setMeetingInfo({});
          setMeetingInfoRec({});
        }
      } catch (err) {
        console.error("Error Deleting Call Endpoint", err);
        throw err;
      }
    }
    async function getMeetingInfoCallChime(payload) {
      try {
        const res = await getMeetingChimeInfoQuery({ input: { MeetingId: payload } });

        if (res.getMeetingChime) {
          setMeetingInfoRec(res.getMeetingChime);
        }
      } catch (error) {
        console.error(`Error in Getting Meeting ${error}`);
        throw error;
      }
    }
    async function createAttendeeChimeCallEndpoint({ InstanceId, MeetingId, Receiver = false }) {
      try {
        const res = await createAttendeeChimeCallEndpointMutation({ input: { InstanceId: InstanceId, MeetingId: MeetingId } });

        if (!Receiver) {
          setAttendeeInfo(res.createAttendeeChimeMeeting);
        } else {
          setAttendeeInfoRec(res.createAttendeeChimeMeeting);
          teamStore.stopIncomingRingtone();
          teamStore.setCallStatus(PeerCallStatus.Connecting);
        }
        return res.createAttendeeChimeMeeting;
      } catch (err) {
        console.error(`Error in Creating Attendee ${err}`);
        throw err;
      }
    }

    async function createAgentToAgentCTR({ InstanceId, Receiver, ViewUser, MeetingInfo }) {
      let parsedAttributtes = {};
      const attrs = {
        Channel: CHANNELS.AGENT_TO_AGENT,
        InitialContactId: MeetingInfo.ContactId,
      };
      for (let key of Object.keys(attrs)) {
        parsedAttributtes[key] = {
          name: key,
          value: attrs[key],
        };
      }
      const callerCTR = {
        Channel: CHANNELS.TASK,
        InitiationMethod: Engage.InitiationMethod.INBOUND,
        InitiationTimestamp: new Date().toISOString(),
        Type: CHANNELS.AGENT_TO_AGENT,
        Duration: 0,
        Username: agentStore.agentSummary.Username,
        CustomerEndpoint: ViewUser.Username,
        InstanceId: agentStore.agentSummary.InstanceId,
        id: MeetingInfo.ContactId,
        Attributes: JSON.stringify(parsedAttributtes),
      };

      teamStore.createCTR(callerCTR);
    }

    function setSessionInfo(payload) {
      setAudioVideo(payload);
    }

    function setMeetingController(payload) {
      setMeetingcontrollerMutation(payload);
    }

    async function cleanupAgentToAgentCall(payload) {
      await updateAgentChimeCallEndpoint({
        InstanceId: payload.InstanceId,
        MeetingId: payload.MeetingId,
        Receiver: payload.Receiver,
        Initiator: payload.Initiator,
        Status: payload.Status,
      });
      // const updateCTR = {
      //   id: getters.getTaskId,
      //   range: rootState.team.range,
      //   InstanceId: rootGetters.agentSummary.InstanceId,
      //   InitiationMethod: Engage.InitiationMethod.MISSED,
      // };
      // dispatch("team/updateCTR", updateCTR, { root: true });
      await deleteAgentChimeCallEndpoint({
        InstanceId: payload.InstanceId,
        MeetingId: payload.MeetingId,
      });
    }

    async function cleanupAgentCallStatus({ InstanceId }) {
      try {
        const res = await cleanupAgentCallStatusMutation({
          input: { InstanceId: InstanceId },
        });

        if (res) {
          setMeetingInfo({});
          setMeetingInfoRec({});
        }
      } catch (err) {
        console.error("Error Cleaningup Agent State", err);
        throw err;
      }
    }

    async function newAgentChimeMeeting({ InstanceId }) {
      const observable = await newAgentChimeCallEventSubscription({
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          const newData = eventData.value.data.newAgentChimeCallEvent;
          if (
            newData.Receiver === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CONNECTING
          ) {
            const data = {
              initiator: newData.Initiator,
              meetingId: newData.MeetingId,
              callee: agentStore.agentSummary.Username,
            };
            setMeetingInfoRec(newData);
            teamStore.onVoiceCallPeer(data);
            teamStore.destroyCallTimer("");
          } else if (
            newData.Initiator === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CONNECTED
          ) {
            teamStore.stopOutgoingRingtone("");
            teamStore.setCallMissedFlag(false);
            teamStore.destroyCallTimer("");

            teamStore.setCallStatus(PeerCallStatus.Connected);
            teamStore.startVoiceCallDurationCounter("AgentToAgent");
          } else if (
            newData.Initiator === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CALLENDED
          ) {
            teamStore.setCallStatus(PeerCallStatus.InitialState);
            teamStore.setAnswerProcessLoader(false);
            teamStore.setVoiceCallModalFlag(false);
            teamStore.stopOutgoingRingtone("");
            teamStore.stopVoiceCallDurationCounter("AgentToAgent");
            const updateCTR = {
              id: getTaskId.value,
              range: teamStore.range,
              InstanceId: agentStore.agentSummary.InstanceId,
              DisconnectTimestamp: new Date().toISOString(),
            };
            teamStore.updateCTR(updateCTR);
          } else if (
            newData.Receiver === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CALLENDED
          ) {
            teamStore.destroyCallTimer("");
            teamStore.setCallStatus(PeerCallStatus.InitialState);
            teamStore.setAnswerProcessLoader(false);
            teamStore.setVoiceCallModalFlag(false);
            teamStore.stopIncomingRingtone("");
            teamStore.stopVoiceCallDurationCounter("AgentToAgent");
          } else if (
            newData.Initiator === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CALLREJECTED
          ) {
            teamStore.setCallStatus(PeerCallStatus.InitialState);
            teamStore.setAnswerProcessLoader(false);
            teamStore.setVoiceCallModalFlag(false);
            teamStore.stopOutgoingRingtone("");
            teamStore.stopVoiceCallDurationCounter("AgentToAgent");
            const updateCTR = {
              id: getTaskId.value,
              range: teamStore.range,
              InstanceId: agentStore.agentSummary.InstanceId,
              InitiationMethod: Engage.InitiationMethod.REJECTED,
            };
            teamStore.updateCTR(updateCTR);
          } else if (
            newData.Initiator === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CALLMISSED
          ) {
            teamStore.setCallStatus(PeerCallStatus.InitialState);
            teamStore.setAnswerProcessLoader(false);
            teamStore.setVoiceCallModalFlag(false);
            teamStore.stopOutgoingRingtone("");
            teamStore.stopVoiceCallDurationCounter("AgentToAgent");
            const updateCTR = {
              id: getTaskId.value,
              range: teamStore.range,
              InstanceId: agentStore.agentSummary.InstanceId,
              InitiationMethod: Engage.InitiationMethod.MISSED,
            };
            teamStore.updateCTR(updateCTR);
          } else if (
            newData.Receiver === agentStore.agentSummary.Username &&
            newData.Status === AGENT_TO_AGENT_CHIME_CALL.CALLMISSED
          ) {
            teamStore.setCallStatus(PeerCallStatus.InitialState);
            teamStore.setAnswerProcessLoader(false);
            teamStore.setVoiceCallModalFlag(false);
            teamStore.stopOutgoingRingtone("");
            teamStore.stopIncomingRingtone("");
            teamStore.destroyPeer("");

            teamStore.stopVoiceCallDurationCounter("AgentToAgent");
            const updateCTR = {
              id: getTaskId.value,
              range: teamStore.range,
              InstanceId: agentStore.agentSummary.InstanceId,
              InitiationMethod: Engage.InitiationMethod.MISSED,
            };
            teamStore.updateCTR(updateCTR);
          }
          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "newAgentChimeMeeting", observable, handler });
    }

    function setMeetingInfo(payload) {
      meetingInfo.value = payload;
    }
    function setTaskId(payload) {
      agentToAgentTaskId.value = payload;
    }
    function setAttendeeInfo(payload) {
      attendeeInfo.value = payload;
    }
    function setMeetingInfoRec(payload) {
      meetingInfoRec.value = payload;
    }
    function setAttendeeInfoRec(payload) {
      attendeeInfoRec.value = payload;
    }
    function setAudioVideo(payload) {
      audioVideo.value = payload;
    }
    function setMeetingcontrollerMutation(payload) {
      meetingController.value = payload;
    }
    function setMuteStatus(payload) {
      muteToggle.value = payload;
    }

    function setVolumeStatus(payload) {
      speakerToggle.value = payload;
    }

    function setSelectedSpeaker(payload) {
      selectedSpeaker.value = payload;
    }
    function setSelectedMic(payload) {
      selectedMic.value = payload;
    }

    function setCallProgress(payload) {
      callIsInProgress.value = payload;
    }

    function cleanUpMeeting() {
      meetingInfo.value = {};
      attendeeInfo.value = {};
      muteToggle.value = false;
      speakerToggle.value = false;
      selectedMic.value = "";
      selectedSpeaker.value = "";
      callIsInProgress.value = false;
      agentToAgentTaskId.value = "";
    }
    return {
      //States
      meetingInfo,
      attendeeInfo,
      meetingInfoRec,
      attendeeInfoRec,
      audioVideo,
      meetingController,
      muteToggle,
      speakerToggle,
      selectedSpeaker,
      selectedMic,
      callIsInProgress,
      agentToAgentTaskId,
      //Getters
      getMeetingInfo,
      getAttendeeInfo,
      getMeetingInfoRec,
      getAttendeeInfoRec,
      getAudioVideo,
      getMeetingController,
      getMuteStatus,
      getVolumeStatus,
      getSelectedSpeaker,
      getSelectedMic,
      getCallProgress,
      getTaskId,
      //Actions
      createAgentChimeCallEndpoint,
      updateAgentChimeCallEndpoint,
      deleteAgentChimeCallEndpoint,
      getMeetingInfoCallChime,
      createAttendeeChimeCallEndpoint,
      createAgentToAgentCTR,
      setSessionInfo,
      setMeetingController,
      cleanupAgentToAgentCall,
      cleanupAgentCallStatus,
      newAgentChimeMeeting,
      //Mutations
      setMeetingInfo,
      setTaskId,
      setAttendeeInfo,
      setMeetingInfoRec,
      setAttendeeInfoRec,
      setAudioVideo,
      setMeetingcontrollerMutation,
      setMuteStatus,
      setVolumeStatus,
      setSelectedSpeaker,
      setSelectedMic,
      setCallProgress,
      cleanUpMeeting,
    };
  },
  {
    persist: true,
  }
);
