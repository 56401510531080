import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import router from "@/router/index";
import _, { isEmpty } from "lodash";
import { DateTime } from "luxon";
import ClassicRingtone from "@/assets/Ringing_Phone_2.mp3";
import availableTones from "@/assets/tones";
import availableTonesB from "@/assets/tones-b";
import { oneClickSetup } from "@/common/constant";
import CcpService from "@/common/services/ccpService-shim";
import { Notice } from "view-design";
import { i18n } from "@/plugins/language";
import { providers, WEBHOOK_TYPE } from "@/common/constant";
import { CHANNELS, TASK_TEMPLATE, WEBHOOKS_PROVIDERS } from "@connectpath/common/src/constants";
import axios from "axios";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { CONTACT_STATES } from "@connectpath/common-frontend";
import {
  listConversationMessagesQuery,
  closeConversationMutation,
  onNewConversationMessageSubscription,
  listWebhooksQuery,
  createWebhookChannelMutation,
  updateWebhookChannelMutation,
  deleteWebhookChannelMutation,
  endEmailConversationMutation,
  acceptEmailContactMutation,
  deleteEmailServerConfigMutation,
  deleteEmailFlowMutation,
  listEmailServerConfigsQuery,
  listEmailFlowsQuery,
  listEmailMessagesQuery,
  createEmailServerConfigMutation,
  updateEmailServerConfigMutation,
  createEmailFlowMutation,
  updateEmailFlowMutation,
  getSignedS3URLQuery,
  outboundMessagesMutation,
  listTaskTemplatesQuery,
  sendConversationMessageMutation,
} from "@/common/services/graphql.service";

import { useTranslationsStore } from "./translations";
import { useAgentsStore } from "./agents";
import { useCurrentUserStore } from "./current-user";
import { useSettingsStore } from "./settings";
import { useIndexStore } from "./index";
import { useApiStore } from "./api";

const playAudioFile = _.debounce(
  (audio) => {
    const ding = new Audio(audio);
    ding.play();
  },
  500,
  { leading: true, trailing: false }
);

export const useChannelStore = defineStore(
  "channel",
  () => {
    const translationStore = useTranslationsStore();
    const agentStore = useAgentsStore();
    const currentUserStore = useCurrentUserStore();
    const settingStore = useSettingsStore();
    const indexStore = useIndexStore();
    const apiStore = useApiStore();
    const conversations = ref({});
    const webhooks = ref([]);
    const selectedThread = ref(null);
    const selectedEmailThread = ref(null);
    const IncomingRingtone = ref(null);
    const unreadEmailThreadIds = ref([]);
    const loaders = ref({
      isCreatingEmailServer: false,
      isCreatingFlow: false,
      isGettingEmailFlows: false,
      isGettingEmailContacts: false,
      isGettingEmailMessages: false,
      isDeletingEmailFlows: false,
      isGettingEmailServerConfig: false,
      isDeletingEmailServerConfig: false,
      isAcceptingMail: false,
      isEndConversation: false,
    });
    const subscriptions = ref({
      onNewEmailMessage: [],
      onNewEmailContact: null,
    });
    const flows = ref({
      items: [],
    });
    const serverConfigs = ref({
      items: [],
    });
    const emailContacts = ref({
      items: [],
    });
    const emailMessages = ref({
      items: [],
    });
    const modalEmailContact = ref({
      isShow: false,
      newEmailContact: null,
    });
    const unreadSMSThreadIds = ref([]);
    const loadingConversations = ref(true);
    const taskTemplatesList = ref([]);
    const defaultTaskTemplateData = ref({});

    const getListWebhooks = computed(() => webhooks.value);
    const getEmailWebhook = computed(() => {
      if (isEmpty(webhooks.value)) return null;

      const emailWebhook = webhooks.value.find((webhook) => webhook.WebhookType === WEBHOOK_TYPE.EMAIL);

      return emailWebhook ?? null;
    });

    const getSmsTwilioWebhook = computed(() => {
      if (_.isEmpty(webhooks.value)) return null;
      const smsTwilioWebhook = webhooks.value.find(
        (webhook) => webhook.WebhookType === "sms" && webhook.WebhookProvider === "twilio"
      );
      if (_.isEmpty(smsTwilioWebhook)) return null;
      return smsTwilioWebhook;
    });

    const showEmailContactModal = computed(() => modalEmailContact.value.isShow);

    const showChatContactModal = computed(() => modalEmailContact.value.isShow);
    const getNewEmailContact = computed(() => modalEmailContact.value.newEmailContact);
    const listFlows = computed(() => flows.value);
    const getListEmailServerConfigs = computed(() => serverConfigs.value);
    const getListEmailContacts = computed(() => emailContacts.value);

    const getListEmailMessages = computed(() => emailMessages.value);
    const getSelectedEmailThread = computed(() => selectedEmailThread.value);
    const getUnreadEmailThreadIds = computed(() => unreadEmailThreadIds.value);
    const getUnreadSMSThreadIds = computed(() => unreadSMSThreadIds.value);
    const isCreatingEmailServer = computed(() => loaders.value.isCreatingEmailServer);

    const isCreatingFlow = computed(() => loaders.value.isGettingEmailFlows);
    const isGettingEmailFlows = computed(() => loaders.value.isGettingEmailFlows);

    const isGettingEmailContacts = computed(() => loaders.value.isGettingEmailFlows);
    const isGettingEmailMessages = computed(() => loaders.value.isGettingEmailMessages);
    const isDeletingEmailFlows = computed(() => loaders.value.isDeletingEmailFlows);
    const isGettingEmailServerConfig = computed(() => loaders.value.isGettingEmailServerConfig);

    const isDeletingEmailServerConfig = computed(() => loaders.value.isDeletingEmailServerConfig);
    const isEndingConversation = computed(() => loaders.value.isEndConversation);
    const isAcceptingMail = computed(() => loaders.value.isAcceptingMail);
    const isSubscribeOnNewEmailMessageWithContactId = computed((ContactId) => {
      let index = _.findIndex(subscriptions.value.onNewEmailMessage, (e) => e.ContactId === ContactId);

      if (index >= 0) {
        return true;
      }
      return false;
    });
    const getConversations = computed(() => conversations.value);
    const getConnectedConversations = computed(() => {
      let listOfTaskConversations = [];
      for (const keyOfConversation in conversations.value) {
        const conversation = conversations.value[keyOfConversation].conversation;
        if (
          (conversation && conversation.state.type === CONTACT_STATES.CONNECTED) ||
          conversation.state.type === CONTACT_STATES.PAUSED
        ) {
          listOfTaskConversations.push(conversation);
        }
      }
      return listOfTaskConversations;
    });

    const getEmailWebhooks = computed(() => {
      return webhooks.value.filter((webhook) => webhook.WebhookType === CHANNELS.EMAIL);
    });
    const getSmsTwilioWebhooks = computed(() => {
      return webhooks.value.filter(
        (webhook) => webhook.WebhookType === CHANNELS.SMS && webhook.WebhookProvider === WEBHOOKS_PROVIDERS.TWILIO
      );
    });
    const getTaskTemplatesList = computed(() => taskTemplatesList.value);
    const getDefaultTaskTemplateData = computed(() => defaultTaskTemplateData.value);

    function updateConversationStateAction(payload) {
      updateConversationState({
        ConversationId: payload.ConversationId,
        State: payload.State,
      });
    }
    function updateConversationStateWithPayload(payload) {
      updateConversationState(payload);
    }
    async function sendConversationMessage(msg) {
      let getEmailTranslationActive = translationStore.getEmailTranslationActive;
      const customerLanguage = translationStore.getEmailCustomerLanguage;
      let OriginalEmail = translationStore.getAgentOriginalEmail;

      let { sendConversationMessage } = await sendConversationMessageMutation(msg);

      if (getEmailTranslationActive === true) {
        sendConversationMessage.Body = OriginalEmail;
        sendConversationMessage = { ...sendConversationMessage, Lang: customerLanguage };
        updatePendingMessage(sendConversationMessage);
      } else {
        updatePendingMessage(sendConversationMessage);
      }
      return sendConversationMessage;
    }
    async function listTaskConversationMessages(contacts) {
      setLoadingConversations(true);

      let res = await listConversationMessagesQuery({
        contacts,
      });

      let conversations = res?.listConversationMessages.conversations;

      for (let convo of conversations) {
        let contact;
        let messages = convo.messages;
        let conversation = convo.conversation;

        if (conversation) {
          contact = {
            ...conversation,
            CustomerEndpoint: conversation?.From || "",
            SystemEndpoint: conversation.To,
            Channel: conversation.Type,
            type: "task",
            state: {
              timestamp: getTimeObject().getOffsetTime(),
              type: CONTACT_STATES.CONNECTED,
            },
            id: conversation.ContactId,
            ConversationId: conversation.id,
          };
          contact.state = {
            timestamp: getTimeObject().getOffsetTime(),
            type: CONTACT_STATES.CONNECTED,
          };

          if (contact.Channel === "email") {
            for (let msg in messages) {
              try {
                if (messages[msg].BodyUrl) {
                  let url = messages[msg].BodyUrl;

                  let rawMessage = await fetch(url);

                  let jsonMessage = await rawMessage.json();

                  let BodyHTML = jsonMessage.BodyHTML;

                  let attachments = jsonMessage.Attachments;

                  messages[msg].Attachments = attachments;

                  messages[msg].BodyHTML = BodyHTML;
                  messages[msg].Body = jsonMessage.Body;
                } else {
                  messages[msg].BodyHTML = messages[msg].Body;
                }
              } catch (err) {
                console.error("err: ", err);
              }
            }
          }

          await subscribeNewTaskConversationMessage(contact);

          setConversationMessagesMutation({
            conversation: contact,
            messages,
          });
        }
      }
      setLoadingConversations(false);
    }

    async function closeConversation({ ContactId, SendFinalResponse }) {
      try {
        await closeConversationMutation({
          ContactId,
          SendFinalResponse,
        });

        const { InstanceId } = agentStore.getAgent;
        const Username = agentStore.agentUsername;
        await currentUserStore.getTasks({ InstanceId, Username });
        const { tasks } = currentUserStore.getUserTasks;

        listTaskConversationMessages(tasks);
      } catch (err) {
        console.error(err);
      }
    }

    function subscribeNewTaskConversationMessage({ InstanceId, ConversationId }) {
      const observable = onNewConversationMessageSubscription({
        InstanceId,
        ConversationId,
      });

      const handler = {
        next: async (eventData) => {
          let msg = eventData.value.data.onNewConversationMessage;

          if (msg.Body === "_UNSUBSCRIBE_CHANGING_AGENTS_") {
            unsubscribeTaskConversationMessage(msg.ConversationId);

            updateConversationState({
              ConversationId: msg.ConversationId,
              State: "ended",
            });

            setSelectedThread(null);

            let convo = conversations.value[msg.ConversationId].conversation;

            Notice.info({
              title: `${i18n.t("notifications.yourConversationWith")} ${convo.From} ${i18n.t(
                "notifications.hasBeenReassigned"
              )}`,
              desc: i18n.t("notifications.aNewMessageWasReceivedWhileYouWereInAnOfflineState"),
              duration: 0,
            });
          } else {
            let SmstranslationActive = translationStore.getSMSTranslationActive;
            let agentLanguage = settingStore.currentUserLanguage;
            let originalMessage = msg.Body;
            if (msg.Direction === "IN" && SmstranslationActive === true && !_.isEmpty(msg.Body)) {
              let customerPayload = {
                content: msg.Body,
                targetLang: agentLanguage,
              };
              let customerTranslatedSMS = await translationStore.createTranslation(customerPayload);
              translationStore.storecustomerSMSMutation(customerTranslatedSMS.TranslatedText);
              msg.Body = originalMessage + "|" + customerTranslatedSMS.TranslatedText;
              msg = { ...msg, Lang: customerTranslatedSMS.sourceLang };
            }

            addMessageToConversationMutation(msg);

            let { conversation } = conversations.value[msg.ConversationId];
            if (conversation.Channel === "email") {
              try {
                let url = msg.BodyUrl;

                let rawMessage = await fetch(url);

                let jsonMessage = await rawMessage.json();

                let BodyHTML = jsonMessage.BodyHTML;

                msg.Attachments = jsonMessage.Attachments;

                msg.BodyHTML = BodyHTML;
                msg.Body = jsonMessage.Body;
              } catch (err) {
                console.error("err: ", err);
              }
            }

            if (router.history.current.path !== "/engage") {
              Vue.prototype.$Notify.create({
                title: `New ${conversation.Channel} message`,
                body: `From: ${msg.Author}`,
              });
            }

            if (msg.ConversationId !== selectedThread.value) {
              addUnreadSMSThread(msg.ConversationId);
              playNewMessageRingtone();
            }
          }
        },
      };

      indexStore.addSubscriptionToList({
        id: "conversation:" + ConversationId,
        observable,
        handler,
      });
    }

    function unsubscribeTaskConversationMessage(context, ConversationId) {
      indexStore.removeSubscriptionFromList({ id: "conversation:" + ConversationId });
    }
    async function listWebhooks(payload) {
      try {
        let res = await listWebhooksQuery({ InstanceId: payload });
        let items = res?.listWebhooks.items;

        webhooks.value = items;
        return items;
      } catch (err) {
        console.error("err: ", err);
        throw err;
      }
    }

    async function createWebhookChannel(payload) {
      try {
        let res = await createWebhookChannelMutation(payload);

        if (payload.Name !== oneClickSetup.name) {
          addWebhookToList(res?.createWebhookChannel);
        }
        return res;
      } catch (err) {
        console.error("err: ", err);
        throw err;
      }
    }

    async function updateWebhookChannel(payload) {
      delete payload.WebhookUrl;
      try {
        let res = await updateWebhookChannelMutation(payload);
        updateWebhookInList(res?.updateWebhookChannel);
        return res;
      } catch (err) {
        console.error("err: ", err);
        throw err;
      }
    }

    async function deleteWebhookChannel(payload) {
      delete payload.WebhookUrl;
      try {
        let res = await deleteWebhookChannelMutation(payload);

        removeWebhookFromList(res?.deleteWebhookChannel);
        return res;
      } catch (err) {
        console.error("err: ", err);
        throw err;
      }
    }

    async function endEmailConversation(payload) {
      try {
        setLoader({
          loader: "isEndConversation",
          val: true,
        });
        let res = await endEmailConversationMutation(payload);

        setEmailContactEndConvo(res?.endEmailConversation.id);
        return Promise.resolve(res?.endEmailConversation);
      } catch (e) {
        console.error("Error: End Email Convo", e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isEndConversation",
          val: false,
        });
      }
    }

    async function acceptEmailContact(payload) {
      try {
        setLoader({
          loader: "isAcceptingMail",
          val: true,
        });
        let res = await acceptEmailContactMutation(payload);

        let newData = res?.acceptEmailContact;
        if (newData.id) {
          onNewEmailMessage({
            InstanceId: payload.input.InstanceId,
            ContactId: newData.id,
          });
          return Promise.resolve(res?.acceptEmailContact);
        } else {
          Promise.reject(new Error("invalid contact"));
        }
      } catch (e) {
        console.error("Error: acceptEmailContact", e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isAcceptingMail",
          val: false,
        });
      }
    }

    async function deleteEmailServer(payload) {
      delete payload.input.error;
      delete payload.input.IsAssignedToFlow;

      try {
        setLoader({
          loader: "isDeletingEmailServerConfig",
          val: true,
        });
        let res = await deleteEmailServerConfigMutation(payload);

        onDeleteEmailServerConfig(res?.deleteEmailServerConfig);
        return Promise.resolve(res?.deleteEmailServerConfig);
      } catch (e) {
        console.error(e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isDeletingEmailServerConfig",
          val: false,
        });
      }
    }

    async function deleteEmailFlow(payload) {
      try {
        setLoader({
          loader: "isDeletingEmailFlows",
          val: true,
        });
        let res = await deleteEmailFlowMutation(payload);

        onDeleteEmailFlow(res?.deleteEmailFlow);
        onEmailServerConfigUnassigned(payload.input.EmailServerConfigId);
        return Promise.resolve(res?.deleteEmailFlow);
      } catch (e) {
        console.error("Error: Delete Email Flow", e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isDeletingEmailFlows",
          val: false,
        });
      }
    }
    async function listEmailServerConfigs(payload) {
      try {
        setLoader({
          loader: "isGettingEmailServerConfig",
          val: true,
        });
        let res = await listEmailServerConfigsQuery(payload);
        setEmailServerConfigs(res?.listEmailServerConfigs);
      } catch (e) {
        console.error("Error: listEmailServerConfigs", e);
      } finally {
        setLoader({
          loader: "isGettingEmailServerConfig",
          val: false,
        });
      }
    }
    async function listEmailFlows(payload) {
      try {
        setLoader({
          loader: "isGettingEmailFlows",
          val: true,
        });
        let res = await listEmailFlowsQuery(payload);

        setFlows(res?.listEmailFlows);
      } catch (e) {
        console.error("Error: Create Email Address", e);
      } finally {
        setLoader({
          loader: "isGettingEmailFlows",
          val: false,
        });
      }
    }

    function listEmailContacts(tasks) {
      if (tasks?.length > 0) {
        listTaskConversationMessages(tasks);
      }
    }

    async function listEmailMessages(payload) {
      try {
        setLoader({
          loader: "isGettingEmailMessages",
          val: true,
        });
        payload.All = true;
        let res = await listEmailMessagesQuery(payload);
        setEmailMessages(res?.listEmailMessages);
        setContactMessages({
          ContactId: payload.ContactId,
          messages: res?.listEmailMessages.items,
        });
        return Promise.resolve(res?.listEmailMessages);
      } catch (e) {
        console.error("Error: Load List Email Messages", e);
      } finally {
        setLoader({
          loader: "isGettingEmailMessages",
          val: false,
        });
      }
    }

    async function createEmailServer(payload) {
      try {
        setLoader({
          loader: "isCreatingEmailServer",
          val: true,
        });
        let res = await createEmailServerConfigMutation(payload);
        if (res?.createEmailServerConfig.error && res?.createEmailServerConfig.error.id) {
          return Promise.reject(res?.createEmailServerConfig.error);
        }
        onNewEmailServerConfig(res?.createEmailServerConfig);
        return Promise.resolve(res?.createEmailServerConfig);
      } catch (e) {
        console.error("Error: Create Email Address", e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isCreatingEmailServer",
          val: false,
        });
      }
    }

    async function updateEmailServer(payload) {
      delete payload.input.error;

      try {
        setLoader({
          loader: "isCreatingEmailServer",
          val: true,
        });
        let res = await updateEmailServerConfigMutation(payload);
        onUpdateEmailServerConfig(res?.updateEmailServerConfig);
        return Promise.resolve(res?.updateEmailServerConfig);
      } catch (e) {
        console.error("Error: Update Email Address", e);
        return Promise.reject(e);
      } finally {
        setLoader({
          loader: "isCreatingEmailServer",
          val: false,
        });
      }
    }

    async function createEmailFlow(payload) {
      try {
        setLoader({ loader: "isCreatingFlow", val: true });
        let res = await createEmailFlowMutation(payload);

        onNewEmailFlow(res?.createEmailFlow);
        onEmailServerConfigAssigned(res?.createEmailFlow);
      } catch (e) {
        console.error("Error: Create Email Flow", e);
      } finally {
        setLoader({ loader: "isCreatingFlow", val: false });
      }
    }
    async function updateEmailFlow(payload) {
      try {
        setLoader({ loader: "isCreatingFlow", val: true });
        let res = await updateEmailFlowMutation(payload);
        onUpdateEmailFlow(res?.updateEmailFlow);
      } catch (e) {
        console.error("Error: Update Email Flow", e);
      } finally {
        setLoader({ loader: "isCreatingFlow", val: false });
      }
    }
    // TODO: Should we get rid of these???
    function onNewEmailContact(payload) {}
    function onNewEmailMessage(payload) {}
    function onStatusChangeEmailContact() {}
    async function subscribeToAllOngoingEmailContacts() {}
    async function unsubscribeToAllOnNewEmailMessageSubscription() {}

    function playIncomingEmailRingtone() {
      stopIncomingEmailRingtone();

      const ringToneName = _.get(apiStore.getApi, "getUser.Preferences.AgentCallTone") || "default";
      const ringTone = availableTones[ringToneName] || ClassicRingtone;
      const ding = new Audio(ringTone);
      ding.play();
      ding.loop = true;
      IncomingRingtone.value = ding;
    }

    function stopIncomingEmailRingtone() {
      const ding = IncomingRingtone.value;
      if (ding) {
        ding.pause();
        IncomingRingtone.value = null;
      }
    }
    function playNewMessageRingtone() {
      const ringToneName = _.get(apiStore.getApi, "getUser.Preferences.AgentMessageTone") || "default";

      const ringTone = availableTonesB[ringToneName] || ClassicRingtone;

      playAudioFile(ringTone);
    }

    function setConversationMessages({ conversation, messages }) {
      setConversationMessagesMutation({
        conversation,
        messages,
      });
    }
    async function setTranslationOnthread(payload) {
      let customerLanguages = payload.customerLanguage;
      const agentLanguage = settingStore.currentUserLanguage;
      let SmstranslationActive = translationStore.getSMSTranslationActive;
      let messages = getConversations.value[payload.ConversationId].messages;
      let conversation = getConversations.value[payload.ConversationId].conversation;
      let emailTranslationActive = translationStore.getEmailTranslationActive;
      try {
        const newData = await Promise.all(
          messages.map(async (obj) => {
            let newObj = Object.assign({}, obj);
            let originalMessage = newObj.Body;
            if (!_.isEmpty(originalMessage)) {
              let newOriginalMessage;
              if (originalMessage.includes("|") && conversation.Channel === "sms") {
                const indexofSlash = originalMessage.indexOf("|");
                originalMessage = originalMessage.substring(0, indexofSlash);
                newOriginalMessage = originalMessage;
              } else {
                if (originalMessage.includes("[")) {
                  const indexofArr = originalMessage.indexOf("[");
                  newOriginalMessage = originalMessage.substring(0, indexofArr);
                } else {
                  newOriginalMessage = originalMessage;
                }
              }
              let customerPayload = {
                content: newOriginalMessage,
                targetLang: agentLanguage,
              };
              let agentpayload = {
                content: newOriginalMessage,
                targetLang: customerLanguages,
              };

              if (newObj.Direction === "IN") {
                let customertranslation = await translationStore.createTranslation(customerPayload);
                let response = customertranslation.TranslatedText;
                let customerLanguage = customertranslation.sourceLang;
                if (conversation.Channel === "sms") {
                  if (SmstranslationActive === true) {
                    newObj.Body = `${originalMessage}${"|"}${response}`;
                    newObj = { ...newObj, Lang: customerLanguage };
                  }
                  if (SmstranslationActive === false) {
                    newObj.Body = originalMessage;
                  }
                }

                if (conversation.Channel === "email") {
                  if (emailTranslationActive === true) {
                    let customerTranslatedSubject = "";
                    if (newObj.Subject !== "") {
                      let customerSubject = newObj.Subject;
                      let subjectPayload = {
                        content: customerSubject,
                        targetLang: agentLanguage,
                      };
                      const customerSubjectTranslation = await translationStore.createTranslation(subjectPayload);
                      customerTranslatedSubject = customerSubjectTranslation.TranslatedText;
                    }
                    let emailtranslatedMessage = response;
                    newObj = {
                      ...newObj,
                      Lang: customerLanguage,
                      customerTranslatedMessage: emailtranslatedMessage,
                      translatedSubject: customerTranslatedSubject,
                    };
                    newObj.Body = originalMessage;
                  }
                  if (emailTranslationActive === false) {
                    newObj.Body = originalMessage;
                  }
                }
              }
              if (newObj.Direction === "OUT" || newObj.Direction === "FWD") {
                let agentTranslation = await translationStore.createTranslation(agentpayload);
                let response = agentTranslation.TranslatedText;
                let customerLanguage = customerLanguages;
                if (conversation.Channel === "sms") {
                  if (SmstranslationActive === true) {
                    newObj.Body = `${originalMessage}${"|"}${response}`;
                    newObj = { ...newObj, Lang: customerLanguage };
                  }
                  if (SmstranslationActive === false) {
                    newObj.Body = originalMessage;
                  }
                }
                if (conversation.Channel === "email") {
                  let emailtranslatedMessage = response;

                  newObj = {
                    ...newObj,
                    Lang: customerLanguages,
                    agentTranslatedMessage: emailtranslatedMessage,
                  };
                }
              }
            }
            return newObj;
          })
        );
        messages = newData;
      } catch (error) {
        console.error(error);
      }

      setConversationMessagesMutation({
        conversation,
        messages,
      });
    }
    async function getCustomerLanguage(payload) {
      let messages = getConversations.value[payload.ConversationId].messages;
      const agentLanguage = settingStore.currentUserLanguage;
      try {
        if (messages[messages.length - 1].Direction === "IN") {
          let message = messages[messages.length - 1].Body;
          if (!isEmpty(message)) {
            let customerPayload = {
              content: message,
              targetLang: agentLanguage,
            };

            const customertranslation = await translationStore.createTranslation(customerPayload);
            const customerLanguage = customertranslation.sourceLang;

            return customerLanguage;
          }
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    async function getPresignedUrl(payload) {
      try {
        const response = await getSignedS3URLQuery(payload);

        return response.getSignedS3URL;
      } catch (error) {
        console.error(error);
      }
    }
    async function putAttachmentsThroughPresignedURL(payload) {
      try {
        let res = await axios.put(payload.url, payload.file, {
          headers: {
            "Content-Type": payload.file.type,
          },
        });
        return res.status;
      } catch (error) {
        console.error(error);
      }
    }

    async function createEmailConversation(payload) {
      try {
        const isOutboundMessagesEnabled = settingStore.isOutboundMessagesEnabled;
        if (!isOutboundMessagesEnabled) {
          Vue.prototype.$Notice.error({
            title: i18n.t("validationMessages.outboundMessagesDisabled"),
          });
          return false;
        }
        const { To, Cc, Bcc, Subject, Body, BodyHTML, Direction, Attachments } = payload;
        const { InstanceId } = agentStore.agentSummary;
        const webhooksList = getListWebhooks.value;
        if (isEmpty(webhooksList)) {
          await listWebhooks(InstanceId);
        }
        const listEmailWebhooks = getEmailWebhooks.value;
        const defaultOutboundEmailId = settingStore.getDefaultOutboundEmailId;
        const emailWebhookSelected = defaultOutboundEmailId
          ? listEmailWebhooks.find((webhook) => webhook.id === defaultOutboundEmailId)
          : getEmailWebhook.value;

        if (!emailWebhookSelected) {
          Vue.prototype.$Notice.error({
            title: i18n.t("validationMessages.emailWebhookMissing"),
          });
          return false;
        }

        const { id: ChannelId, ProviderEmail, OutboundEmailAddress } = emailWebhookSelected;

        if (emailWebhookSelected.WebhookProvider === providers.amazonSes && !ProviderEmail) {
          Vue.prototype.$Notice.error({
            title: i18n.t("validationMessages.providerEmailAddresNotSet"),
          });
          return false;
        }

        const outboundMessage = {
          input: {
            ChannelId,
            InstanceId,
            To,
            Cc,
            Bcc,
            Subject,
            Body,
            BodyHTML,
            Direction,
            Attachments,
            From: ProviderEmail || OutboundEmailAddress,
          },
        };

        await outboundMessagesMutation(outboundMessage);
      } catch (error) {
        console.error(error);
      }
    }

    function setChannelSelectedThread(payload) {
      setSelectedThread(payload);
    }

    function addMessageToConversation(payload) {
      addMessageToConversationMutation(payload);
    }

    async function createTask({ agent, params }) {
      const { scheduledDate, scheduledTime, ...task } = params;

      let newTaskParams = {
        ...task,
      };

      newTaskParams.taskTemplateId = settingStore.getDefaulTaskTemplateId;

      if (scheduledDate && scheduledTime) {
        const InstanceAccount = currentUserStore.instanceAccount;
        const timezone = InstanceAccount.TimeZone.value;

        const day = scheduledDate.getDate();
        const month = scheduledDate.getMonth() + 1;
        const year = scheduledDate.getFullYear();
        const [hour, minute] = scheduledTime.split(":");

        const millisecondTimestamp = DateTime.fromObject({ day, month, year, hour, minute }).setZone(timezone).toMillis();
        const epochTimestamp = Math.floor(millisecondTimestamp / 1000);
        newTaskParams.scheduledTime = epochTimestamp;
      }

      await CcpService.createTask(agent, newTaskParams);
    }

    async function fetchTaskTemplates({ InstanceId }) {
      try {
        const response = await listTaskTemplatesQuery({
          InstanceId,
          Status: TASK_TEMPLATE.ACTIVE,
        });

        setTaskTemplatesList(response.listTaskTemplates?.TaskTemplates || []);
      } catch (error) {
        console.error(`store > settings > fetchTaskTemplates`, error);
        setTaskTemplatesList([]);
      }
    }

    function getTaskTemplateData({ agent, taskTemplateId }) {
      CcpService.getTaskTemplate({ agent, taskTemplateId });
    }

    function cleanTaskTemplateData() {
      setDefaultTaskTemplateData({});
    }

    function updateTaskContact({ agent, references, contactId }) {
      CcpService.updateTaskContact({ agent, references, contactId });
    }

    function setConversationMessagesMutation({ conversation, messages }) {
      Vue.set(conversations.value, conversation.ConversationId, {
        conversation,
        messages: messages.sort((a, b) => {
          return new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime();
        }),
      });
    }

    async function addMessageToConversationMutation(v) {
      let messages = conversations.value[v.ConversationId].messages;

      if (v.BodyUrl) {
        let rawMessage = await fetch(v.BodyUrl);

        let jsonMessage = await rawMessage.json();

        let attachments = jsonMessage.Attachments;

        v.Attachments = attachments;
        if (v.msg) {
          v.BodyHTML = jsonMessage.BodyHTML;
          v.Body = jsonMessage.Body;
        }
      }
      messages.splice(messages.length, 0, v);
    }

    function updatePendingMessage(v) {
      let messages = conversations.value[v.ConversationId].messages;
      messages.sort((msg1, msg2) => {
        const time1 = new Date(msg1.CreatedAt).getTime();
        const time2 = new Date(msg2.CreatedAt).getTime();

        return time1 - time2;
      });
      messages[messages.length - 1].Status = "Sent";
    }
    function setSelectedThread(v) {
      selectedThread.value = null;
      selectedThread.value = v;
    }
    function updateConversationState(v) {
      conversations.value[v.ConversationId].conversation.state.type = v.State;
      if (v.isTransfered) {
        conversations.value[v.ConversationId].conversation.state.isTransfered = v.isTransfered;
      }
    }
    function updateConversationIsActive(v) {
      conversations.value[v.ConversationId].conversation.IsActive = v.IsActive;
    }
    function setWebhookslist(v) {
      webhooks.value = v;
    }

    function addWebhookToList(v) {
      webhooks.value.unshift(v);
    }
    function updateWebhookInList(v) {
      let index = webhooks.value.findIndex((w) => w.id === v.id);
      webhooks.value.splice(index, 1, v);
    }

    function removeWebhookFromList(v) {
      let index = webhooks.value.findIndex((w) => w.id === v.id);
      webhooks.value.splice(index, 1);
    }

    function addUnreadEmailThread(v) {
      const index = unreadEmailThreadIds.value.findIndex((id) => id == v);
      if (index < 0) {
        unreadEmailThreadIds.value.push(v);
      }
    }
    function addUnreadSMSThread(v) {
      unreadSMSThreadIds.value.push(v);
    }
    function removeUnreadEmailThread(v) {
      unreadEmailThreadIds.value = unreadEmailThreadIds.value.filter((threadId) => threadId !== v);
    }
    function removeUnreadSMSThread(v) {
      unreadSMSThreadIds.value = unreadSMSThreadIds.value.filter((threadId) => threadId !== v);
    }
    function clearUnreadEmailThread() {
      unreadEmailThreadIds.value = [];
    }
    function setShowEmailContactModal(v) {
      modalEmailContact.value.isShow = v;
    }
    function setNewEmailContact(v) {
      modalEmailContact.value.newEmailContact = v;
    }
    function setSelectedEmailThread(v) {
      selectedEmailThread.value = v;
    }
    function setLoader(v) {
      loaders.value[v.loader] = v.val;
    }
    function setEmailSubscription({ name, subscription }) {
      subscriptions.value[name] = subscription;
    }
    function addOnNewEmailMessageSubscription({ ContactId, subscription }) {
      let onNewEmailMessage = {
        ContactId,
        subscription,
      };

      let index = _.findIndex(subscriptions.value.onNewEmailMessage, (e) => e.ContactId === ContactId);

      if (index < 0) {
        subscriptions.value.onNewEmailMessage.push(onNewEmailMessage);
      } else if (subscription) {
        subscription.unsubscribe();
      }
    }
    function endEmailSubscription({ name }) {
      if (subscriptions.value[name]) {
        subscriptions.value[name].unsubscribe();
      }
      subscriptions.value[name] = null;
    }
    function endOnNewEmailMessageSubscription({ ContactId }) {
      let index = _.findIndex(subscriptions.value.onNewEmailMessage, (e) => e.ContactId === ContactId);

      if (index >= 0 && subscriptions.value.onNewEmailMessage[index]) {
        subscriptions.value.onNewEmailMessage[index].subscription.unsubscribe();

        subscriptions.value.onNewEmailMessage.splice(index, 1);
      }
    }
    function setFlows(v) {
      flows.value = v;
    }
    function onNewEmailFlow(v) {
      flows.value.items.unshift(v);
    }
    function onUpdateEmailFlow(v) {
      let index = _.findIndex(flows.value.items, (e) => {
        return e.id === v.id;
      });

      if (index >= 0) {
        flows.value.items.splice(index, 1, v);
      }
    }
    function onDeleteEmailFlow(v) {
      let index = _.findIndex(flows.value.items, (e) => {
        return e.id === v.id;
      });

      if (index >= 0) {
        flows.value.items.splice(index, 1);
      }
    }
    function setEmailServerConfigs(v) {
      serverConfigs.value = v;
    }
    function addEmailServerConfigs(v) {
      for (var i = 0; i < v.length; i++) {
        serverConfigs.value.items.push(v[i]);
      }
    }
    function onNewEmailServerConfig(v) {
      serverConfigs.value.items.unshift(v);
    }
    function onUpdateEmailServerConfig(v) {
      let index = _.findIndex(serverConfigs.value.items, (e) => {
        return e.id === v.id;
      });

      if (index >= 0) {
        serverConfigs.value.items.splice(index, 1, v);
      }
    }
    function onDeleteEmailServerConfig(v) {
      let index = _.findIndex(serverConfigs.value.items, (e) => {
        return e.id === v.id;
      });

      if (index >= 0) {
        serverConfigs.value.items.splice(index, 1);
      }
    }
    function onEmailServerConfigAssigned(v) {
      let index = _.findIndex(serverConfigs.value.items, (e) => {
        return e.id === v.EmailServerConfigId;
      });

      if (index >= 0) {
        serverConfigs.value.items[index].IsAssignedToFlow = true;
      }
    }
    function onEmailServerConfigUnassigned(EmailServerConfigId) {
      let index = _.findIndex(serverConfigs.value.items, (e) => {
        return e.id === EmailServerConfigId;
      });

      if (index >= 0) {
        serverConfigs.value.items[index].IsAssignedToFlow = true;
      }
    }
    function setEmailContacts(v) {
      emailContacts.value = v;
    }
    function onNewEmailContactMutation(v) {
      let index = _.findIndex(emailContacts.value.items, (e) => e.id === v.id);

      if (index >= 0) {
        emailContacts.value.items.splice(index, 1, v);
      } else {
        emailContacts.value.items.unshift(v);
      }
    }
    function setEmailMessages(messages) {
      emailMessages.value = messages;
    }
    function setContactMessages({ ContactId, messages }) {
      let indx = emailContacts.value.items.findIndex((e) => e.id === ContactId);

      if (indx >= 0) {
        emailContacts.value.items[indx].Messages = messages;
      }
    }
    function onNewEmailMessageMutation(message) {
      let index = _.findIndex(emailMessages.value.items, (m) => {
        return m.id === message.id;
      });

      if (index < 0) {
        emailMessages.value.items.unshift(message);
      } else {
        emailMessages.value.items.splice(index, 1, message);
      }
    }
    function onNewEmailMessagesForContact(message) {
      let contactIndex = _.findIndex(emailContacts.value.items, (contact) => contact.id === message.ContactId);

      if (contactIndex >= 0) {
        if (
          emailContacts.value.items[contactIndex].Messages.length == 1 &&
          emailContacts.value.items[contactIndex].Messages[0] == null
        ) {
          return emailContacts.value.items[contactIndex].Messages.unshift(message);
        }

        let index = _.findIndex(emailContacts.value.items[contactIndex].Messages, (m) => {
          return m.id === message.id;
        });

        if (index < 0) {
          emailContacts.value.items[contactIndex].Messages.unshift(message);
        } else {
          emailContacts.value.items[contactIndex].Messages.splice(index, 1, message);
        }
      }
    }
    function setEmailContactEndConvo(ContactId) {
      let index = _.findIndex(emailContacts.value.items, (e) => {
        return e.id === ContactId;
      });

      if (index >= 0) {
        let emailContacts = _.cloneDeep(emailContacts.value);
        emailContacts.items[index].IsActive = false;
        emailContacts.value = emailContacts;
      }
    }

    function setLoadingConversations(value) {
      loadingConversations.value = value;
    }
    function initChannel() {
      selectedEmailThread.value = null;
      IncomingRingtone.value = null;
      unreadEmailThreadIds.value = [];
      loaders.value = {
        isCreatingEmailServer: false,
        isCreatingFlow: false,
        isGettingEmailFlows: false,
        isGettingEmailContacts: false,
        isGettingEmailMessages: false,
        isDeletingEmailFlows: false,
        isGettingEmailServerConfig: false,
        isDeletingEmailServerConfig: false,
        isAcceptingMail: false,
        isEndConversation: false,
      };
      subscriptions.value = {
        onNewEmailMessage: [],
        onNewEmailContact: null,
      };
      flows.value = {
        items: [],
      };
      serverConfigs.value = {
        items: [],
      };
      emailContacts.value = {
        items: [],
      };
      emailMessages.value = {
        items: [],
      };
      modalEmailContact.value = {
        isShow: false,
        newEmailContact: null,
      };
    }
    function updateState(value) {
      selectedEmailThread.value = value.selectedEmailThread;
      IncomingRingtone.value = value.IncomingRingtone;
      unreadEmailThreadIds.value = value.unreadEmailThreadIds;
      loaders.value = value.loaders;
      subscriptions.value = value.subscriptions;
      flows.value = value.flows;
      serverConfigs.value = value.serverConfigs;
      emailContacts.value = value.emailContacts;
      emailMessages.value = value.emailMessages;
      modalEmailContact.value = value.modalEmailContact;
    }
    function setTaskTemplatesList(taskTemplatesList) {
      taskTemplatesList.value = taskTemplatesList;
    }
    function setDefaultTaskTemplateData(defaultTaskTemplateData) {
      defaultTaskTemplateData.value = defaultTaskTemplateData;
    }

    return {
      //States
      conversations,
      webhooks,
      selectedThread,
      selectedEmailThread,
      IncomingRingtone,
      unreadEmailThreadIds,
      loaders,
      subscriptions,
      flows,
      serverConfigs,
      emailContacts,
      emailMessages,
      modalEmailContact,
      unreadSMSThreadIds,
      loadingConversations,
      taskTemplatesList,
      defaultTaskTemplateData,
      //Getters
      getListWebhooks,
      getEmailWebhook,
      getSmsTwilioWebhook,
      showEmailContactModal,
      showChatContactModal,
      getNewEmailContact,
      listFlows,
      getListEmailServerConfigs,
      getListEmailContacts,
      getListEmailMessages,
      getSelectedEmailThread,
      getUnreadEmailThreadIds,
      getUnreadSMSThreadIds,
      isCreatingEmailServer,
      isCreatingFlow,
      isGettingEmailFlows,
      isGettingEmailContacts,
      isGettingEmailMessages,
      isDeletingEmailFlows,
      isGettingEmailServerConfig,
      isDeletingEmailServerConfig,
      isEndingConversation,
      isAcceptingMail,
      isSubscribeOnNewEmailMessageWithContactId,
      getConversations,
      getConnectedConversations,
      getEmailWebhooks,
      getSmsTwilioWebhooks,
      getTaskTemplatesList,
      getDefaultTaskTemplateData,
      //Actions
      updateConversationStateAction,
      updateConversationStateWithPayload,
      sendConversationMessage,
      listTaskConversationMessages,
      closeConversation,
      subscribeNewTaskConversationMessage,
      unsubscribeTaskConversationMessage,
      listWebhooks,
      createWebhookChannel,
      updateWebhookChannel,
      deleteWebhookChannel,
      endEmailConversation,
      acceptEmailContact,
      deleteEmailServer,
      deleteEmailFlow,
      listEmailServerConfigs,
      listEmailFlows,
      listEmailContacts,
      listEmailMessages,
      createEmailServer,
      updateEmailServer,
      createEmailFlow,
      updateEmailFlow,
      onStatusChangeEmailContact,
      subscribeToAllOngoingEmailContacts,
      unsubscribeToAllOnNewEmailMessageSubscription,
      playIncomingEmailRingtone,
      stopIncomingEmailRingtone,
      playNewMessageRingtone,
      setConversationMessages,
      setTranslationOnthread,
      getCustomerLanguage,
      getPresignedUrl,
      putAttachmentsThroughPresignedURL,
      createEmailConversation,
      setChannelSelectedThread,
      addMessageToConversation,
      fetchTaskTemplates,
      createTask,
      getTaskTemplateData,
      cleanTaskTemplateData,
      updateTaskContact,
      onNewEmailContact,
      onNewEmailMessage,
      //Mutations
      setConversationMessagesMutation,
      addMessageToConversationMutation,
      updatePendingMessage,
      setSelectedThread,
      updateConversationState,
      updateConversationIsActive,
      setWebhookslist,
      addWebhookToList,
      updateWebhookInList,
      removeWebhookFromList,
      addUnreadEmailThread,
      addUnreadSMSThread,
      removeUnreadEmailThread,
      removeUnreadSMSThread,
      clearUnreadEmailThread,
      setShowEmailContactModal,
      setNewEmailContact,
      setSelectedEmailThread,
      setLoader,
      setEmailSubscription,
      addOnNewEmailMessageSubscription,
      endEmailSubscription,
      endOnNewEmailMessageSubscription,
      setFlows,
      onNewEmailFlow,
      onUpdateEmailFlow,
      onDeleteEmailFlow,
      setEmailServerConfigs,
      addEmailServerConfigs,
      onNewEmailServerConfig,
      onUpdateEmailServerConfig,
      onDeleteEmailServerConfig,
      onEmailServerConfigAssigned,
      onEmailServerConfigUnassigned,
      setEmailContacts,
      onNewEmailContactMutation,
      setEmailMessages,
      setContactMessages,
      onNewEmailMessageMutation,
      onNewEmailMessagesForContact,
      setEmailContactEndConvo,
      setLoadingConversations,
      initChannel,
      updateState,
      setTaskTemplatesList,
      setDefaultTaskTemplateData,
    };
  },
  {
    persist: true,
  }
);
