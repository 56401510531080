import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import _, { get } from "lodash";
import CcpService from "@/common/services/ccpService-shim";
import { PeerCallStatus } from "@/common/constant";
import { defaultConnectStatuses } from "@connectpath/common";
import { AGENT_STATUSES } from "@connectpath/common";
import { i18n } from "@/plugins/language";
import { useTeamStore } from "./team";
import { useApiStore } from "./api";
import { useCurrentUserStore } from "./current-user";

const systemStates = ["Busy", "PendingBusy", "Connected"];

function assertAllowedStates(states) {
  return states.filter((state) => !systemStates.includes(state.name));
}

const initialState = {
  agent: {
    configuration: {
      agentStates: [
        {
          name: "",
        },
      ],
      role: "",
      routingProfile: {
        queues: [],
      },
    },
    status: {
      type: "test",
    },
    AWSAccountID: false,
    availabilityState: null,
  },
};

export const useAgentsStore = defineStore("agents", () => {
  const teamStore = useTeamStore();
  const apiStore = useApiStore();
  const currentUserStore = useCurrentUserStore();

  const agent = ref({
    configuration: {
      agentStates: [
        {
          name: "",
        },
      ],
      role: "",
      routingProfile: {
        queues: [],
      },
    },
    status: {
      type: "test",
    },
    AWSAccountID: false,
  });
  const customEndpoints = ref([]);
  const quickConnects = ref([]);
  const queuetags = ref([]);
  const isFirstLogin = ref(false);
  const user = ref({});
  const agentActivity = ref([]);
  const isConnected = ref(false);
  const recentDeskphone = ref([]);
  const newState = ref({});
  const getAgent = computed(() => agent.value);
  const AWSAgentID = computed(() => agent.value.AWSAgentID);
  const agentStates = computed(() => assertAllowedStates(agent.value.configuration.agentStates));
  const agentStatesNamesList = computed(() =>
    assertAllowedStates(agent.value.configuration.agentStates).map((state) => state.name)
  );
  const agentCustomStatuses = computed(() =>
    assertAllowedStates(agent.value.configuration.agentStates).filter((status) => !defaultConnectStatuses.includes(status.name))
  );
  const endpoints = computed(() => {
    return [...(agent.value?.endpoints ? agent.value.endpoints : []), ...customEndpoints.value];
  });
  const customEndpointsGetter = computed(() => [customEndpoints.value]);
  const quickConnectsGetter = computed(() => [quickConnects.value]);
  const agentStatus = computed(() => agent.value.state);
  const availabilityState = computed(() => agent.value.availabilityState);
  const agentUsername = computed(() => (agent.value.configuration ? agent.value.configuration.username : null));
  const agentFirstName = computed(() => (agent.value.configuration ? agent.value.configuration.name : null));

  const isConnectedGetter = computed(() => isConnected.value);
  const isFirstLoginGetter = computed(() => isFirstLogin.value);
  const isAuthenticated = computed(() => isConnected.value);
  const agentStatusDuration = computed(() => agent.value.stateDuration);
  const agentActivityGetter = computed(() => agentActivity.value);
  const AWSAccountID = computed(() => agent.value.AWSAccountID);
  const agentSummary = computed(() => agent.value.summary);
  const getInstanceId = computed(() => agent.value.summary.InstanceId);
  const queueTags = computed(() => queuetags.value);
  const onCall = computed(() => {
    let states = ["CallingCustomer", "PendingBusy", "Busy", PeerCallStatus.Connecting, PeerCallStatus.Connected];
    let agentStatus = agentStatus.value;
    return (states.includes(get(agentStatus, "name")) && apiStore.getUser.Contact) || states.includes(teamStore.callStatus);
  });
  const recentDeskphoneGetter = computed(() => recentDeskphone.value);
  const customAgentStates = computed(() => {
    let agentStates = assertAllowedStates(agent.value.configuration.agentStates);
    return agentStates.filter((state) => state.type === AGENT_STATUSES.NOT_ROUTABLE);
  });
  const getMaximumOfTasks = computed(() => {
    return agent.value.data?.configuration.routingProfile.channelConcurrencyMap;
  });
  const getNewState = computed(() => newState.value);

  function isConnectedAction(connected) {
    return setConnectedMutation(connected);
  }

  async function fetchAgentAction(agent) {
    try {
      const response = await CcpService.getAgent(agent);
      setAgentMutation(response);
      return response;
    } catch (error) {
      console.error("Error fetching agent: ", error);
      throw error;
    }
  }

  async function doesRoutingProfileHasQueuesAction(agent) {
    if (await CcpService.doesRoutingProfileHasQueues(agent)) return;
    const agentRoutingProfileName = await CcpService.getAgentRoutingProfileName(agent);
    Vue.prototype.$Notice.error({
      title: i18n.t("common.error"),
      desc: i18n.t("agentCcp.errorRoutingProfileDoensthaveAnyQueue", [agentRoutingProfileName]),
      duration: 0,
    });
  }
  function setCurrentUserAction(user) {
    return saveCurrentUserMutation(user);
  }

  async function newStateAction(newState) {
    try {
      const updatedState = await CcpService.setState(newState);
      setStateMutation(updatedState);
      return updatedState;
    } catch (error) {
      console.error("Error changing state: ", error);
    }
  }

  function setRoleAction(role) {
    setRoleMutation(role);
  }
  function muteAgentAction() {
    return CcpService.muteAgent();
  }

  function unMuteAgentAction() {
    return CcpService.unMuteAgent();
  }

  async function sendDigitsAction(digits) {
    try {
      await CcpService.sendDigits(digits);
    } catch (error) {
      console.error(error);
    }
  }

  function updateQueuetagIndex(selectedQueue) {
    let index = _.findIndex(queuetags.value, (i) => {
      return i.queueId == selectedQueue;
    });

    let tag = queuetags.value[index];
    removeQueuetagAt(index);

    const timeout = setTimeout(() => {
      pushQueuetagAtFront(tag);
      clearTimeout(timeout);
    }, 600);
  }

  function getAgentRecentDeskphone() {
    try {
      let d = JSON.parse(localStorage.getItem(agent.value.AWSAgentID));

      initializeAgentRecentDeskphone(d);
    } catch (e) {
      console.error("syncAgentRecentDeskphone", e);
    }
  }
  function updateAgentRecentDeskphone() {
    try {
      localStorage.setItem(agent.value.AWSAgentID, JSON.stringify(recentDeskphone.value));
    } catch (error) {
      console.error(error);
    }
  }

  function updateAgentConfiguration(payload) {
    updateConfigurationLocal(payload);
  }
  function updateAgentAvailabilityState(payload) {
    setAvailabilityState(payload);
  }
  async function fetchQuickConnects({ agent }) {
    const endpoints = await CcpService.getEndpoints(agent);
    setQuickConnects(endpoints);
  }

  function setConnectedMutation(connected) {
    isConnected.value = connected;
  }

  function isFirstLoginMutation(firstLogin) {
    isFirstLogin.value = firstLogin;
  }
  function saveCurrentUserMutation(user) {
    apiStore.api.getUser = { ...apiStore.api.getUser, ...user };
  }

  function commitRoleMutation(role) {
    agent.value.configuration.role = role;
  }

  function setAgentMutation(payloadAgent) {
    let data = payloadAgent;

    if (currentUserStore?.profile?.Contacts && currentUserStore?.profile?.Contacts[0]?.State === "CONNECTED_ONHOLD") {
      data.summary.StatusName = "Hold";
      data.summary.StatusStartTimestamp = currentUserStore?.profile?.Contacts[0]?.StateStartTimestamp;
    }
    agent.value = data;

    let globalQueue = {
      name: "Global View",
      queueId: "global",
    };

    if (
      currentUserStore &&
      currentUserStore.securityProfile.Realm !== "instance" &&
      !(currentUserStore.profile.Username || "").includes("IAM@")
    ) {
      queuetags.value = [
        globalQueue,
        ...data.configuration.routingProfile.queues
          .filter((queue) => {
            return queue.name;
          })
          .sort((a, b) => {
            var nameA = a.name.toLowerCase(),
              nameB = b.name.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
          }),
      ];
    }
  }

  function setRoleMutation(val) {
    agent.value.configuration.role = val;
  }
  function setStateMutation(payload) {
    newState.value = payload;
  }
  function setQueueTags(queues) {
    queuetags.value = [
      {
        name: "Global View",
        queueId: "global",
      },
      ...queues
        .map((q) => {
          return {
            name: q.Name,
            queueARN: q.Arn,
            queueId: q.id,
          };
        })
        .sort((a, b) => {
          var nameA = a.name.toLowerCase(),
            nameB = b.name.toLowerCase();
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
          return 0;
        }),
    ];
  }

  function updateAgentStatus(payload) {
    agent.value = { ...agent.value, summary: payload };
  }
  function removeQueuetagAt(index) {
    queuetags.value.splice(index, 1);
  }
  function pushQueuetagAtFront(tag) {
    queuetags.value.unshift(tag);
  }
  function setAgentSoftPhoneEnabled(payload) {
    agent.value.configuration.softphoneEnabled = payload;
  }
  function setAgentConfigExtension(payload) {
    agent.value.configuration.extension = payload;
  }
  function setAvailabilityState(payload) {
    agent.value.availabilityState = payload;
  }
  function appendCustomEndpoints(payload) {
    customEndpoints.value = payload;
  }
  function initializeAgentRecentDeskphone(payload) {
    if (payload !== null) {
      payload.forEach((p) => {
        recentDeskphone.value.push(p);
      });
    }
  }

  function addAgentRecentDeskphone(payload) {
    recentDeskphone.value.push(payload);
  }
  function initAgent() {
    agent.value = initialState.agent;
    customEndpoints.value = [];
    queuetags.value = [];
    isFirstLogin.value = false;
    user.value = {};
    agentActivity.value = [];
    isConnected.value = false;
    recentDeskphone.value = [];
  }
  function updateAgentState(value) {
    agent.value = { ...value.agent };
    customEndpoints.value = value.customEndpoints;
    queuetags.value = value.queuetags;
    isFirstLogin.value = value.isFirstLogin;
    user.value = value.user;
    agentActivity.value = value.agentActivity;
    isConnected.value = value.isConnected;
    recentDeskphone.value = value.recentDeskphone;
  }

  function updateConfigurationLocal(payload) {
    agent.value.configuration = payload;
  }
  function setQuickConnects(endpoints) {
    quickConnects.value = endpoints;
  }

  return {
    //States
    agent,
    customEndpoints,
    quickConnects,
    queuetags,
    isFirstLogin,
    user,
    agentActivity,
    isConnected,
    recentDeskphone,
    //Getters
    getAgent,
    AWSAgentID,
    agentStates,
    agentStatesNamesList,
    agentCustomStatuses,
    endpoints,
    customEndpointsGetter,
    quickConnectsGetter,
    agentStatus,
    availabilityState,
    agentUsername,
    agentFirstName,
    isConnectedGetter,
    isFirstLoginGetter,
    isAuthenticated,
    agentStatusDuration,
    agentActivityGetter,
    AWSAccountID,
    agentSummary,
    getInstanceId,
    queueTags,
    onCall,
    recentDeskphoneGetter,
    customAgentStates,
    getMaximumOfTasks,
    getNewState,
    //Actions
    isConnectedAction,
    fetchAgentAction,
    doesRoutingProfileHasQueuesAction,
    setCurrentUserAction,
    newStateAction,
    setRoleAction,
    muteAgentAction,
    unMuteAgentAction,
    sendDigitsAction,
    updateQueuetagIndex,
    getAgentRecentDeskphone,
    updateAgentRecentDeskphone,
    updateAgentConfiguration,
    updateAgentAvailabilityState,
    fetchQuickConnects,
    //Mutations
    setConnectedMutation,
    isFirstLoginMutation,
    saveCurrentUserMutation,
    commitRoleMutation,
    setAgentMutation,
    setStateMutation,
    setQueueTags,
    updateAgentStatus,
    removeQueuetagAt,
    pushQueuetagAtFront,
    setAgentSoftPhoneEnabled,
    setAgentConfigExtension,
    setAvailabilityState,
    appendCustomEndpoints,
    initializeAgentRecentDeskphone,
    addAgentRecentDeskphone,
    initAgent,
    updateAgentState,
    updateConfigurationLocal,
    setQuickConnects,
    setRoleMutation,
  };
});
