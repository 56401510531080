import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import VueCookie from "vue-cookie";
import { Storage } from "aws-amplify";
import { Notice } from "view-design";
import { cloneDeep, get } from "lodash";
import { PRESIGNED_S3_METHODS } from "@connectpath/common";
import NeedHelp from "@/assets/alert_1.mp3";
import { i18n } from "@/plugins/language";
import { DateTime } from "luxon";
import { smartdialcore } from "@/common/services/smart-dial/smartdial.js";
import { IDLE_UPDATE_RESTRICTED_STATUSES, SECURITY_PROFILES } from "@connectpath/common";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { INSTANCE_LOGO_PATH, LANGUAGE, PROMISE, REALM, THEME } from "@connectpath/common-frontend";
import { ForbiddenError } from "@/Errors/ForbidenAccessError";
import {
  getCurrentGmtEpochTimeStampQuery,
  getNetworkTraversalQuery,
  deleteSecurityProfileMutation,
  getTasksQuery,
  listSecurityProfilesQuery,
  listRoutingProfilesQuery,
  createSecurityProfileMutation,
  updateSecurityProfileMutation,
  getAccountQuery,
  needsHelpMutation,
  updateUserMutation,
  onCreateUserSubscription,
  onFinishedReportSubscription,
  onUpdateSecurityProfileSubscription,
  onNeedsHelpSubscription,
  onLoginSubscription,
  onNewAnnouncementSubscription,
  updateAccountMutation,
  onIntegrationChangedSubscription,
  onIntegrationDeletedSubscription,
  onUpdateUsersSubscription,
  onUpdateUserSubscription,
  onUpdateUserSecurityProfileSubscription,
  onNewActivitySubscription,
  onNewActivityCustomerSubscription,
  createAnnouncementMutation,
  listAnnouncementsQuery,
  getSecurityProfileQuery,
  deleteAnnouncementMutation,
  onDeletedAnnouncementSubscription,
  getSignedS3URLQuery,
  onDeleteUserSubscription,
} from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useSettingsStore } from "./settings";
import { useIndexStore } from "./index";
import { useApiStore } from "./api";
import { useLogsStore } from "./logs";
import { useUsersStore } from "./users";
import { useTeamStore } from "./team";
import { useHidStore } from "./hid";
import { usePermissionsStore } from "./permissions";
import { useChannelStore } from "./channel";
import { useContactsStore } from "./contacts";
import { useLivelookStore } from "./livelook";
import { useChatStore } from "./chat";
import { usePhoneStore } from "./phone";
import { useReportsStore } from "./reports";
import { useAnnouncementStore } from "./announcement";
import { useIntegrationsStore } from "./integrations";
import { useActivityStore } from "./activity";
import { useHealthStore } from "./health";
import { useAgentCallStore } from "./agent-call";

const SMD = new smartdialcore();

let updateUsersList = [];

export const useCurrentUserStore = defineStore(
  "current-user",
  () => {
    const agentStore = useAgentsStore();
    const settingStore = useSettingsStore();
    const indexStore = useIndexStore();
    const apiStore = useApiStore();
    const logStore = useLogsStore();
    const userStore = useUsersStore();
    const teamStore = useTeamStore();
    const hidStore = useHidStore();
    const permissionStore = usePermissionsStore();
    const channelStore = useChannelStore();
    const contactsStore = useContactsStore();
    const livelookStore = useLivelookStore();
    const chatStore = useChatStore();
    const phoneStore = usePhoneStore();
    const reportStore = useReportsStore();
    const announcementStore = useAnnouncementStore();
    const integrationsStore = useIntegrationsStore();
    const activityStore = useActivityStore();
    const healthStore = useHealthStore();
    const agentCallStore = useAgentCallStore();
    const instanceTimezone = ref({
      abbr: "",
      isdst: true,
      offset: 0,
      text: "",
      value: "",
    });
    const clockOffset = ref(0);
    const adminLogoUrl = ref(null);
    const logoMediaURL = ref(null);
    const CognitoPoolId = ref("");
    const agentOnCall = ref("");
    const securityProfiles = ref({});
    const routingProfiles = ref({});
    const account = ref({});
    const profile = ref({
      Roles: [],
    });
    const postAnnouncement = ref(false);
    const newNotif = ref(false);
    const announcements = ref({
      listAnnouncements: {
        items: [],
      },
    });
    const tasks = ref([]);
    const loadingTasks = ref(true);
    const securityProfile = ref({
      Name: null,
      Description: null,
      SoftPhone: null,
      Realm: null,
    });
    const currentSecurityProfile = ref({
      Name: null,
      Description: null,
      PermissionGroups: [],
      Realm: null,
      SoftPhone: "dextr",
    });
    const statusAfterContactWork = ref("");
    const currentRoutingProfile = ref(null);
    const currentUser = ref(null);
    const instanceData = ref(null);
    const getClockOffset = computed(() => clockOffset.value);
    const acwTimeLimit = computed(() => profile.value?.PhoneConfig?.AfterContactWorkTimeLimit || 0);
    const userRole = computed(() => profile.value.Roles);
    const agentStatus = computed(() => {
      return {
        name: profile.value.StatusName,
        stateStartTimestamp: profile.value.StatusStartTimestamp,
      };
    });
    const listSecurityProfiles = computed(() => securityProfiles.value);
    const instanceAccount = computed(() => account.value);
    const getAdminLogoUrl = computed(() => adminLogoUrl.value);
    const getLogoMediaURL = computed(() => logoMediaURL.value);
    const userInstanceTimezone = computed(() => instanceTimezone.value);
    const instanceTimezoneOffset = computed(() => {
      if (instanceTimezone.value.offset >= 0 && instanceTimezone.value.isdst) {
        return instanceTimezone.value.offset + 1;
      } else if (instanceTimezone.value.offset < 0 && instanceTimezone.value.isdst) {
        return instanceTimezone.value.offset - 1;
      } else {
        return instanceTimezone.value.offset;
      }
    });
    const isShowDrawer = computed(() => postAnnouncement.value);
    const onNewNotif = computed(() => newNotif.value);
    const isUserAnInstanceAdmin = computed(() => {
      if (profile.value && profile.value.Username && profile.value.Username.includes("IAM@")) return true;
      return false;
    });

    const getProfileData = computed(() => profile.value || {});
    const isAllowPostAnnouncement = computed(() => {
      if (profile.value && profile.value.Roles && profile.value.Roles.indexOf("supervisors") > -1) {
        return true;
      }
      if (profile.value && profile.value.Roles && profile.value.Roles.indexOf("admins") > -1) {
        return true;
      }
      if (profile.value && profile.value.Roles && profile.value.Roles.indexOf("instance-admins") > -1) {
        return true;
      }

      return false;
    });
    const getUserTasks = computed(() => {
      return { loading: loadingTasks.value, tasks: tasks.value || [] };
    });
    const instanceId = computed(() => profile.value?.InstanceId || "");
    const getAllRoutingProfiles = computed(() => routingProfiles.value?.items || []);
    const getUsername = computed(() => profile.value?.Username || "");
    const getRoutingProfilesList = computed(() => {
      let results = [];
      if (routingProfiles.value?.items) {
        const routeProfiles = new Set();
        for (const profile of routingProfiles.value.items) {
          const { Arn, Id, Name } = profile;
          const routeKey = `${Arn}_${Id}_${Name}`;
          routeProfiles.add(routeKey);
        }
        results = Array.from(routeProfiles).map((routeKey) => {
          const [Arn, Id, Name] = routeKey.split("_");
          return { Arn, Id, Name };
        });
      }
      return results;
    });
    const securityProfileName = computed(() => {
      return securityProfile.value.Name || SECURITY_PROFILES.INSTANCE_ADMIN;
    });
    const isTheSecurityProfileAdmin = computed(() => {
      return (
        securityProfileName.value === SECURITY_PROFILES.DEXTR_ADMIN ||
        securityProfileName.value === SECURITY_PROFILES.INSTANCE_ADMIN
      );
    });

    const getDashboardSecurityGroup = computed(() => {
      if (currentSecurityProfile.value.PermissionGroups.length) {
        return currentSecurityProfile.value.PermissionGroups.find((item) => item.Name === "Dashboard");
      }
      return null;
    });
    const getStatusAfterContactWork = computed(() => statusAfterContactWork.value);
    const listSecurityGroup = computed(() => securityProfiles.value.items);
    const getSecurityProfile = computed(() => securityProfile.value);
    const getMediaConcurrencies = computed(() => account.value?.RoutingProfile?.MediaConcurrencies);
    const getCurrentSecurityProfile = computed(() => currentSecurityProfile.value);
    const getCustomerBucketName = computed(() => account.value?.BucketName);
    const getInstanceData = computed(() => {
      return instanceData.value;
    });
    async function fetchClockOffset() {
      try {
        let serverTime = await getCurrentGmtEpochTimeStampQuery();
        let delay = new Date().getTime() - serverTime.getCurrentGmtEpochTimeStamp;
        setClockOffset(delay);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    function setLocalStateAction(payload) {
      setLocalState({
        name: payload.name,
        startTimestamp: payload.startTimestamp,
      });
    }
    async function hydrateApp(user) {
      try {
        await setCurrentUserData({ userData: user });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    async function getNetworkTraversal() {
      const res = await getNetworkTraversalQuery();
      return res?.getNetworkTraversal;
    }
    function signout() {
      try {
        if (!window.location.origin) {
          window.location.origin =
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "");
        }

        if (VueCookie.get("dextr_subdomain")) {
          window.location.href = window.location.origin + "/" + VueCookie.get("dextr_subdomain");
          return false;
        }
        window.location.href = window.location.origin;
      } catch (error) {
        console.error(error);
      }
    }

    async function deleteSecurityProfiles(payload) {
      const { deleteSecurityProfile } = await deleteSecurityProfileMutation({
        id: payload.data.id,
        InstanceId: payload.InstanceId,
      });

      for (let x = 0; x < securityProfile.value.items.length; x++) {
        if (deleteSecurityProfile.id == securityProfile.value.items[x].id) {
          securityProfile.value.items.splice(x, 1);
          break;
        }
      }
    }
    async function getTasks({ InstanceId, Username }) {
      setLoadingTasks(true);
      let res = await getTasksQuery({
        InstanceId,
        Username,
      });
      setLoadingTasks(false);
      setTasks(res?.getUser.Tasks);
      return res?.getUser?.Tasks;
    }

    async function getSecurityProfiles(payload) {
      const res = await listSecurityProfilesQuery({
        InstanceId: payload,
      });

      let response = res?.listSecurityProfiles;
      response.items.forEach((item) => {
        item.PermissionGroups.forEach((element) => {
          if (element.Name === "Users and Permissions") {
            element.PermissionItems = element.PermissionItems.filter((perItem) => perItem.Name !== "Global");
            element.PermissionItems.forEach((permissionItem) => {
              if (permissionItem.Name === "Global Contact") {
                permissionItem.Values.Create = null;
                permissionItem.Values.View = null;
              }
            });
          }
        });
      });
      setSecurityProfiles(response, false);
      return res;
    }
    async function getRoutingProfiles(payload) {
      try {
        let res = await listRoutingProfilesQuery({
          InstanceId: payload,
        });

        setRoutingProfiles(res?.listRoutingProfiles);
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    }
    async function createSecurityProfile({ profile, instanceId }) {
      const res = await createSecurityProfileMutation({
        InstanceId: instanceId,
        ...profile,
      });

      securityProfile.value.items.push(res?.createSecurityProfile);
      return res;
    }
    async function updateSecurityProfile(payload) {
      delete payload.data._index;
      delete payload.data._rowKey;
      try {
        const res = await updateSecurityProfileMutation({
          InstanceId: payload.InstanceId,
          ...payload.data,
        });
      } catch (err) {
        console.error(err);
      }
    }

    function updateStateSecurityProfiles(payload) {
      updateSecurityProfiles(payload);
    }
    async function setCurrentUserData({ userData }) {
      try {
        await fetchAmazonConnectData({ userData });
        await fetchApplicationData({ userData });
        await applyDataInApplication({ userData });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function applyDataInApplication({ userData }) {
      try {
        await setUserDataApplication({ userData });
        await applyUserPreferences({ userData });
        await applyAccountData({ userData });
        await handleSubscriptions({ userData });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    function setUserDataApplication({ userData }) {
      try {
        apiStore.setCurrentUser(userData);
        setCurrentUser(userData);
        logStore.setLogger({
          alias: userData.InstanceAlias || userData.Alias,
          username: userData.Username,
          region: userData.Region || agentStore?.agent?.Region,
          instance_id: userData.InstanceId,
          rtc_enabled: window.useConnectRTC,
        });
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function applyUserPreferences({ userData }) {
      try {
        hidStore.registerSupportedDevice(null);
        const isDarkMode = userData.Preferences.Theme === THEME.DARK;
        const language = userData.Preferences.Language || LANGUAGE.ENGLISH;
        settingStore.setDarkMode(isDarkMode);
        settingStore.setLanguage(language);
        window.localStorage.setItem("language", language);

        if (userData.Username.includes("IAM@")) {
          let supreme = permissionStore.getInstanceAdmin;

          indexStore.SET_CURRENT_USER_RULES(supreme);
        } else {
          await getSecurityProfileById({
            InstanceId: userData.InstanceId,
            id: userData.SecurityProfileId,
            ConnectProfiles: userData.SecurityProfileIds,
          }).then((res) => {
            setSecurityProfile(res.getSecurityProfile);
            indexStore.SET_CURRENT_USER_RULES(res.getSecurityProfile);
          });
        }
        contactsStore.setFavoriteContacts(userData.FavoriteDirectoryIds || []);

        channelStore.listEmailContacts(tasks.value);

        let favoriteMembers = get(userData, "FavoriteTeamMembers", []) || [];
        teamStore.addNewFavoriteUser(favoriteMembers);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function applyAccountData({ userData }) {
      try {
        const res = await getAccountQuery({
          InstanceId: userData.InstanceId,
        });

        setInstanceAccount(res?.getAccount);

        if (!SMD.isInitialized() && res?.getAccount.CognitoPoolId) {
          SMD.init(Vue.prototype.$agent);
        }

        const { AdminLogo, LogoMediaURL } = res?.getAccount;

        if (AdminLogo) {
          Storage.get(AdminLogo, {
            level: "public",
          }).then((pic) => {
            setAdminLogoUrl(pic);
          });
        } else {
          setAdminLogoUrl(null);
        }
        if (LogoMediaURL) {
          try {
            const URL = await fetchLogoMediaURL();
            setLogoMediaURL(URL[PRESIGNED_S3_METHODS.GET]);
          } catch (error) {
            console.error("Error fetching logo prStoree-signed url", error);
          }
        } else {
          setLogoMediaURL(null);
        }

        if (securityProfile.value.Realm === REALM.INSTANCE || profile.value.Username.includes("IAM@")) {
          agentStore.setQueueTags(res?.getAccount.Queues);
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    function handleSubscriptions({ userData }) {
      try {
        const { InstanceId, Username, SecurityProfileId } = userData;

        livelookStore.onUpdateLivelookContacts(InstanceId);
        const userSessionPayload = userData.session.idToken.payload;
        agentCallStore.cleanupAgentCallStatus({ InstanceId: InstanceId });
        agentCallStore.newAgentChimeMeeting({ InstanceId: InstanceId, ConnectingTo: Username });
        setInstanceTimezone(userSessionPayload);
        settingStore.settingsSubs(InstanceId);
        settingStore.dispositionSubs(InstanceId);
        contactsStore.globalContactsSub(InstanceId);
        contactsStore.deleteGlobalContactsSub(InstanceId);
        if (SecurityProfileId) {
          onUpdateSecurityProfile({
            InstanceId: InstanceId,
            id: SecurityProfileId,
          });
        }
        chatStore.onNewAgentChatParticipantEvent({
          InstanceId,
          Username,
        });
        reportSubs({
          InstanceId,
          Owner: Username,
        });
        createUserSubs({ InstanceId });
        deleteUserSubs({ InstanceId });
        deleteAnnouncementSubs({
          InstanceId,
        });
        updateUsersSubs({ InstanceId });
        updateUserSubs({ InstanceId });
        updateUserSecuritySubs({
          InstanceId,
          Username,
        });
        onLoginSubs({
          InstanceId,
          Username,
        });

        onNewAnnouncementSubs({
          InstanceId,
          userData,
        });
        channelStore.onNewEmailContact({
          InstanceId,
          Username,
        });
        onNeedsHelp({ InstanceId });

        newActivitySubs({
          Username,
          InstanceId,
        });
        newActivityFromPeer({
          CustomerEndpoint: Username,
          InstanceId,
        });
        integrationsSubs({ instanceId: InstanceId });
        integrationsDeletedSubs({ instanceId: InstanceId });
        phoneStore.onNewSmsContact({
          InstanceId,
          Username,
        });
        window.Broadcast.$emit("hydratingAppSetCurrentUserDone");
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function onSendHelp(payload) {
      let api = apiStore.api;
      let p = cloneDeep(api.getUser);

      p.NeedsHelp = Object.prototype.hasOwnProperty.call(api.getUser, "NeedsHelp") ? !api.getUser.NeedsHelp : true;

      let dataSend = {
        InstanceId: p.InstanceId,
        Username: p.Username,
        NeedsHelp: p.NeedsHelp,
      };

      if (payload) {
        dataSend.NeedsHelp = payload.needHelp;
        dataSend.Username = payload.userName;
      } else {
        apiStore.needHelp(p.NeedsHelp);
      }

      let input = cloneDeep(dataSend);
      const res = await needsHelpMutation({
        input,
      });
      if (res?.needsHelp.Username == p.Username) {
        apiStore.needHelp(p.NeedsHelp);
      }
      return res;
    }

    async function onUpdateCurrentUser(payload) {
      let input = cloneDeep(payload);
      delete input.Password;
      delete input.Groups;
      delete input.Queues;
      delete input.Region;
      const res = await updateUserMutation(input);

      apiStore.updateCurrentUser(res?.updateUser);
      let favoriteMembers = get(res, "updateUser.FavoriteTeamMembers", []) || [];
      teamStore.addNewFavoriteUser(favoriteMembers);
      return res;
    }

    function createUserSubs({ InstanceId }) {
      const observable = onCreateUserSubscription({
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onCreateUser;
          userStore.newUser(newData);
          teamStore.newTeamMember(newData);
          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "createUserSubs", observable, handler });
    }

    function deleteUserSubs({ InstanceId }) {
      const observable = onDeleteUserSubscription({
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onDeleteUser;
          if (newData.Username === agentStore.agentSummary.Username) {
            VueCookie.set("deletedForceLogout", true, { expires: "1M" });
            signout();
          } else {
            userStore.deleteUser(newData);
            teamStore.deleteTeamMember(newData);
            return Promise.resolve(newData);
          }
        },
      };

      indexStore.addSubscriptionToList({ id: "deleteUserSubs", observable, handler });
    }

    function reportSubs({ InstanceId, Owner }) {
      const observable = onFinishedReportSubscription({
        InstanceId,
        Owner,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onFinishedReport;
          let temp = reportStore.list.find((element) => element.id === newData.id);
          newData.HideNA = temp?.HideNA;
          if (!newData.Parent) {
            if (newData.Status === "complete") {
              Notice.success({
                title: `${i18n.t("notifications.report")} '${newData.Name}' ${i18n.t("notifications.isFinished")}`,
                desc: i18n.t("notifications.viewItInTheReportsPage"),
                duration: 5,
              });
            }
            if (newData.Status === "error") {
              Notice.error({
                title: `${i18n.t("notifications.report")} '${newData.Name}' ${i18n.t("notifications.failedToComplete")}`,
                desc: newData.Error || i18n.t("notifications.pleaseCheckYourInputsAndTryAgain"),
                duration: 10,
              });
            }
            let tempParent = reportStore.list.find((element) => element.id === newData.ParentId);
            let temp = tempParent.ChildReports.find((element) => element.id === newData.id);
            newData.HideNA = temp?.HideNA;
          }

          reportStore.updateReport(newData);

          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "reportSubs", observable, handler });
    }

    function onUpdateSecurityProfile({ InstanceId, id }) {
      const observable = onUpdateSecurityProfileSubscription({
        InstanceId,
        id,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateSecurityProfile;
          updateSecurityProfileData(newData);
          indexStore.UPDATE_RULES({ profile: newData });
          let inputData = {
            InstanceId: profile.value.InstanceId,
            Username: profile.value.Username,
            Id: profile.value.Id,
            SecurityProfile: omitDeep(newData, "__typename"),
          };
          userStore.onUpdateUser(inputData).catch((err) => console.error("err: ", err));

          return Promise.resolve(newData);
        },
      };
      indexStore.addSubscriptionToList({ id: "onUpdateSecurityProfile", observable, handler });
    }

    function onNeedsHelp({ InstanceId }) {
      const observable = onNeedsHelpSubscription({
        InstanceId,
      });

      const handler = {
        next: async (eventData) => {
          let newData = eventData.value.data.onNeedsHelp;

          if (profile.value.Id !== newData.Id) {
            if (this._vm.$can("view", "raise_hand") && newData?.NeedsHelp) {
              let user = teamStore.team.filter((user) => user.Username === newData.Username);

              let ding = new Audio(NeedHelp);
              ding.play();

              if (!("Notification" in window)) {
                alert("This browser does not support desktop notification");
              } else if (Notification.permission === "'granted'") {
                Vue.prototype.$Notify.create({
                  title: `${user[0].Username} ${i18n.t("notifications.needsHelp")}`,
                });
              } else if (Notification.permission !== "'denied'") {
                const permission = await Notification.requestPermission();
                if (permission === "granted") {
                  Vue.prototype.$Notify.create({
                    title: `${user[0].Username ? user[0].Username : "N/A"} ${i18n.t("notifications.needsHelp")}`,
                  });
                }
              }

              Notice.open({
                title: "",
                name: "announcement-notice-info",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                        cursor: "pointer",
                      },
                      on: {
                        click: () => {
                          Notice.close("announcement-notice-info");
                          chatStore.setDrawer(true);
                          chatStore.setUserSelected(user[0]);
                          teamStore.clearUserUnreadMessageStatus({
                            Username: user[0].Username,
                          });
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          [
                            h(
                              "p",
                              {
                                style: {
                                  marginBottom: "10px",
                                },
                              },
                              [
                                h("i", {
                                  style: {
                                    fontSize: "20px",
                                    color: "#05668d",
                                  },
                                  class: ["fa", "fa-megaphone"],
                                }),
                                ` ${i18n.t("notifications.needsHelp")}`,
                              ]
                            ),

                            h(
                              "p",
                              {
                                class: ["txt-ellipsis"],
                                style: {
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                },
                              },
                              [newData.Title]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  textDecoration: "underline",
                                },
                              },
                              [newData.Username]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  color: "#aaa",
                                  marginTop: "10px",
                                },
                              },
                              [
                                h("i-time", {
                                  props: {
                                    time: getTimeObject().getOffsetTime(),
                                  },
                                  style: {
                                    display: "block",
                                  },
                                }),
                              ]
                            ),
                          ],
                        ]
                      ),
                    ]
                  );
                },
              });
            }
          }
          teamStore.teamNeedHelp(newData);
          if (profile.value.Id == newData.Id) {
            apiStore.needHelp(newData.NeedsHelp);
          }
          return Promise.resolve(newData);
        },
      };
      indexStore.addSubscriptionToList({ id: "onNeedsHelp", observable, handler });
    }

    function onLoginSubs({ InstanceId, Username }) {
      const observable = onLoginSubscription({
        InstanceId,
        Username,
      });

      const handler = {
        next: () => {},
      };
      indexStore.addSubscriptionToList({ id: "onLoginSubs", observable, handler });
    }

    function onNewAnnouncementSubs({ InstanceId, userData }) {
      const observable = onNewAnnouncementSubscription({
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onNewAnnouncement;
          announcementStore.getList(null);
          let annoucementQueues = newData.Queues;
          let agentQueues = [];
          //TODO: refactor this abomination
          for (let x = 0; x < agentStore.queueTags.length; x++) {
            if (agentStore.queueTags[x].queueId !== "global") {
              agentQueues.push(agentStore.queueTags[x].queueARN);
            }
          }

          let shouldDisplay = false;
          if (agentQueues && newData.DeliveryMethods.indexOf("screen_pop") > -1) {
            shouldDisplay = agentQueues.some((queue) => annoucementQueues.includes(queue));
          }

          if (userData.Username === newData.From) {
            return false;
          }

          if (shouldDisplay) {
            announcements.value.listAnnouncements.items.unshift(newData);

            let ding = new Audio(NeedHelp);
            setNewNotif(true);
            ding.play();

            var length = 500;
            var trimmedString = newData.Message.substring(0, length);
            let msgTruncated = trimmedString + "...";

            if (newData.Urgency === "Emergency") {
              Notice.open({
                title: "",
                name: "announcement-notice-emergency",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                      },
                      on: {
                        click: () => {
                          setNewNotif(true);
                          Notice.close("announcement-notice-emergency");
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          h(
                            "i-col",
                            {
                              style: {
                                display: "inline-block",
                              },
                              props: {
                                span: 20,
                              },
                            },
                            [
                              [
                                h(
                                  "p",
                                  {
                                    style: {
                                      marginBottom: "10px",
                                    },
                                  },
                                  [
                                    h("i", {
                                      style: {
                                        fontSize: "20px",
                                        color: "#05668d",
                                      },
                                      class: ["fa", "fa-exclamation-triangle"],
                                    }),
                                    ` ${i18n.t("common.new")}  ${i18n.t("common.announcement")}`,
                                  ]
                                ),

                                h(
                                  "p",
                                  {
                                    class: ["txt-ellipsis"],
                                    style: {
                                      fontWeight: "bolder",
                                      fontSize: "15px",
                                    },
                                  },
                                  [newData.Title]
                                ),

                                h(
                                  "small",
                                  {
                                    style: {
                                      display: "block",
                                      textDecoration: "underline",
                                    },
                                  },
                                  [newData.From]
                                ),

                                h(
                                  "p",
                                  {
                                    style: {
                                      fontSize: "14px",
                                      wordBreak: "break-all",
                                      textAlign: "justify",
                                      marginTop: "15px",
                                    },
                                  },
                                  [msgTruncated]
                                ),

                                h(
                                  "p",
                                  {
                                    style: {
                                      marginTop: "10px",
                                    },
                                  },
                                  renderNoticeTag(h, newData.Queues)
                                ),

                                h(
                                  "small",
                                  {
                                    style: {
                                      display: "block",
                                      color: "#aaa",
                                    },
                                  },
                                  [
                                    h("p", [
                                      DateTime.fromISO(newData.Date)
                                        .setLocale(i18n.locale === "es" ? "es" : "")
                                        .toRelative(),
                                    ]),
                                  ]
                                ),
                              ],
                            ]
                          ),
                        ]
                      ),
                    ]
                  );
                },
              });
            } else {
              Notice.open({
                title: "",
                name: "announcement-notice-info",
                duration: 15,
                render: (h) => {
                  return h(
                    "i-row",
                    {
                      style: {
                        fontSize: "17px",
                      },
                      on: {
                        click: () => {
                          announcementStore.setSelectedItem(newData);
                          setShowDrawer(!isShowDrawer.value);
                          setNewNotif(true);
                          Notice.close("announcement-notice-info");
                        },
                      },
                    },
                    [
                      h(
                        "i-col",
                        {
                          style: {
                            display: "inline-block",
                          },
                          props: {
                            span: 24,
                          },
                        },
                        [
                          [
                            h(
                              "p",
                              {
                                style: {
                                  marginBottom: "10px",
                                },
                              },
                              [
                                h("i", {
                                  style: {
                                    fontSize: "20px",
                                    color: "#05668d",
                                  },
                                  class: ["fa", "fa-megaphone"],
                                }),
                                ` ${i18n.t("common.new")}  ${i18n.t("common.announcement")}`,
                              ]
                            ),

                            h(
                              "p",
                              {
                                class: ["txt-ellipsis"],
                                style: {
                                  fontWeight: "bolder",
                                  fontSize: "15px",
                                },
                              },
                              [newData.Title]
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  textDecoration: "underline",
                                },
                              },
                              [newData.From]
                            ),

                            h(
                              "p",
                              {
                                style: {
                                  fontSize: "14px",
                                  wordBreak: "break-all",
                                  textAlign: "justify",
                                  marginTop: "15px",
                                },
                              },
                              [msgTruncated]
                            ),

                            h(
                              "p",
                              {
                                style: {
                                  marginTop: "10px",
                                },
                              },
                              renderNoticeTag(h, newData.Queues)
                            ),

                            h(
                              "small",
                              {
                                style: {
                                  display: "block",
                                  color: "#aaa",
                                },
                              },
                              [
                                h("p", [
                                  DateTime.fromISO(newData.Date)
                                    .setLocale(i18n.locale === "es" ? "es" : "")
                                    .toRelative(),
                                ]),
                              ]
                            ),
                          ],
                        ]
                      ),
                    ]
                  );
                },
              });
            }
            return Promise.resolve(newData);
          }
        },
      };
      indexStore.addSubscriptionToList({ id: "onNewAnnouncementSubs", observable, handler });
    }

    async function onSaveTimezone(payload) {
      const res = await updateAccountMutation(payload);

      return res;
    }

    function integrationsSubs({ instanceId }) {
      const observable = onIntegrationChangedSubscription({ InstanceId: instanceId });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onIntegrationChanged;
          integrationsStore.integrationChanged({ instanceId, integration: newData });
          return;
        },
        error: (err) => {
          console.error("Error >  integrationsSubs >  ", err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "integrationsSubs",
        observable,
        handler,
        linkedQuery: { name: "integrations/listIntegrations", params: { instanceId } },
      });
    }

    function integrationsDeletedSubs({ instanceId }) {
      const observable = onIntegrationDeletedSubscription({ InstanceId: instanceId });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onIntegrationDeleted;
          integrationsStore.integrationDeleted({ integration: newData });
          return;
        },
        error: (err) => {
          console.error("Error >  integrationsDeletedSubs >  ", err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "integrationsDeletedSubs",
        observable,
        handler,
        linkedQuery: { name: "integrations/listIntegrations", params: { instanceId } },
      });
    }

    function updateUsersSubs({ InstanceId }) {
      const observable = onUpdateUsersSubscription({
        InstanceId,
      });
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUsers;

          if (newData?.items?.length > 0) {
            for (let user of newData.items) {
              if (user.Contacts) {
                let contacts = JSON.parse(user.Contacts).filter((contact) => contact.State !== "ERROR");

                user.NumberOfActiveContacts = contacts.length;
                user.Contacts = contacts;
              }

              if (user.Id === profile.value.Id) {
                if (
                  newData?.items?.length > 0 &&
                  newData?.items[0]?.Contacts &&
                  newData?.items[0]?.Contacts[0]?.State === "CONNECTED_ONHOLD"
                ) {
                  newData.items[0].StatusName = "Hold";
                  user.StatusName = "Hold";
                }
                apiStore.updateCurrentUser(newData);

                apiStore.needHelp(user.NeedsHelp);
              }
            }

            newData.items.forEach((item) => {
              updateUsersList.push(item);
            });
          }
          return;
        },
        error: (err) => {
          console.error(err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "updateUsersSubs",
        observable,
        handler,
        linkedQuery: { name: "team/onFetchTeam", params: { InstanceId } },
      });
    }

    function updateUserSubs({ InstanceId }) {
      const observable = onUpdateUserSubscription({
        InstanceId,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUser;

          if (newData.Id === profile.value.Id) {
            let statusFromAPI = newData.StatusName;
            if (statusFromAPI.includes(";remote")) {
              statusFromAPI = statusFromAPI.replace(";remote", "");
              const state = agentStore.agentStates.filter((state) => {
                return state.name === statusFromAPI;
              })[0];

              if (state) {
                let agent = agentStore.agent;
                agent.state.name = `Pending${statusFromAPI}`;
                agentStore.setAgentMutation(agent);
                agentStore.newStateAction(state);
              }
            }
            if (
              newData?.items?.length > 0 &&
              newData?.items[0]?.Contacts.length > 0 &&
              newData?.items[0]?.Contacts[0]?.State === "CONNECTED_ONHOLD"
            ) {
              newData.items[0].StatusName = "Hold";
            }
            apiStore.updateCurrentUser(newData);

            setCurrentUser(newData);
            apiStore.needHelp(newData.NeedsHelp);
          } else {
            updateUsersList.push(newData);
          }
        },
        error: (err) => {
          console.error(err);
        },
      };
      indexStore.addSubscriptionToList({
        id: "updateUserSubs",
        observable,
        handler,
        linkedQuery: { name: "team/onFetchTeam", params: { InstanceId } },
      });
    }

    function onOfferedMonitor(data) {
      try {
        getProfileData.value?.Username;
        teamStore.onInitiatePeer(data);
      } catch (error) {
        console.error("Error in OfferedMonitor: ", error);
      }
    }

    function onOfferedVoiceCall(data) {
      try {
        teamStore.onVoiceCallPeer(data);
      } catch (error) {
        console.error("Error in onOfferedVoiceCall: ", error);
      }
    }

    function onDestroyedVoiceCall() {
      try {
        teamStore.destroyVoiceCall(null);
        teamStore.destroyPeer(null);

        agentOnCall.value = "";
      } catch (error) {
        console.error("Error in onDestroyedVoiceCall: ", error);
      }
    }

    function onAnsweredMonitor(data) {
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data,
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredMonitor] - Signaling the peer to establish a direct connection`;
        logStore.addSocketLog(payload);
        window.mPeerInitiator.signal(data.AnswerSDP);
      } catch (error) {
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredMonitor] - Error while trying to signal the the peer.`;
        logStore.addSocketLog(payload);
        console.error("Error in AnsweredMonitor: ", error);
      }
    }

    function onAnsweredVoiceCall(data) {
      const payload = {
        component: "LAMBDA_SOCKET",
        level: "INFO",
        messageContext: {
          data: data,
        },
      };
      try {
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${data.initiator}] [onAnsweredVoiceCall] - Signaling the peer to establish a direct connection`;
        logStore.addSocketLog(payload);
        window.vPeerInitiator.signal(data.AnswerSDP);
        agentOnCall.value = data.callee;
      } catch (error) {
        payload.level = "ERROR";
        payload.messageContext.error = JSON.stringify(error, ["message", "arguments", "type", "name"]);
        payload.text = `[LAMBDA_SOCKET_CLIENT] [${payload.messageContext.data.initiator}] [onAnsweredVoiceCall] - Error while trying to signal the the peer.`;
        logStore.addSocketLog(payload);
      }
    }

    function updateUserSecuritySubs({ InstanceId, Username }) {
      const observable = onUpdateUserSecurityProfileSubscription({
        InstanceId,
        Username,
      });

      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onUpdateUserSecurityProfile;

          if (newData) {
            indexStore.UPDATE_RULES({ profile: newData });
          }
          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "updateUserSecuritySubs", observable, handler });
    }
    function newActivitySubs({ Username, InstanceId }) {
      let payload = {
        Username,
        InstanceId,
      };
      onNewActivitySubscription(payload);
    }
    function newActivityFromPeer({ CustomerEndpoint, InstanceId }) {
      let payload = {
        CustomerEndpoint,
        InstanceId,
      };

      const observable = onNewActivityCustomerSubscription(payload);
      const handler = {
        next: (eventData) => {
          let newData = eventData.value.data.onNewActivity;
          newData["InitiationMethod"] = "INBOUND";

          const tempCustomerEndPoint = newData.CustomerEndpoint;
          const tempUsername = newData.Username;
          newData["CustomerEndpoint"] = tempUsername;
          newData["Username"] = tempCustomerEndPoint;

          activityStore.setHangingActivity(newData);
          return Promise.resolve(newData);
        },
      };

      indexStore.addSubscriptionToList({ id: "newActivityFromPeer", observable, handler });
    }
    async function updateAccount(payload) {
      let data = {
        input: {
          InstanceId: apiStore.getUser.InstanceId,
          ...payload,
        },
      };

      const removeObjectsWithNull = (obj) => {
        const o = JSON.parse(JSON.stringify(obj));

        Object.keys(o).forEach((key) => {
          if (o[key] && typeof o[key] === "object") o[key] = removeObjectsWithNull(o[key]);
          else if (
            (o[key] === undefined || o[key] === "" || o[key] === null) &&
            key !== "AdminLogo" &&
            key !== "LogoMediaURL" &&
            key !== "CognitoPoolId"
          )
            delete o[key];
        });

        return o;
      };

      delete data.input.Username;

      const res = await updateAccountMutation(removeObjectsWithNull(data?.input));
      setInstanceAccount(res?.updateAccount);
      if (res?.updateAccount.LogoMediaURL) {
        setLogoMediaURL(res?.updateAccount.LogoMediaURL);
      } else {
        setLogoMediaURL(null);
      }
      return res;
    }

    async function onNewAnnouncement(payload) {
      const res = await createAnnouncementMutation(payload);

      return res;
    }

    async function listOfAnnouncement(payload) {
      const res = await listAnnouncementsQuery(payload);
      announcements.value = res;
      return res;
    }
    async function getSecurityProfileById(payload) {
      const res = await getSecurityProfileQuery(payload);
      return res;
    }
    async function deleteAnnouncement(payload) {
      const res = await deleteAnnouncementMutation(payload);

      return res;
    }
    function deleteAnnouncementSubs({ InstanceId }) {
      const observable = onDeletedAnnouncementSubscription({
        InstanceId,
      });

      const handler = {
        next: () => {
          announcementStore.getList(null);
        },
      };
      indexStore.addSubscriptionToList({ id: "deleteAnnouncementSubs", observable, handler });
    }
    function updateCurrentSecurityProfileSoftPhone(ccp) {
      setCurrentSecurityProfileSoftPhone(ccp);
    }

    function setAgentStatus({ agentStatus }) {
      setLocalState(agentStatus);
      agentStore.newStateAction(agentStatus);
    }

    function setAgentStatusByIdle({ idleAgentStatusName }) {
      const currentAgentStatus = agentStore.agentStatus;
      const idleAgentStatus = agentStore.agentStates.find((agentState) => agentState.name === idleAgentStatusName);
      if (!IDLE_UPDATE_RESTRICTED_STATUSES.includes(currentAgentStatus.name) && idleAgentStatus) {
        setAgentStatus({ agentStatus: idleAgentStatus });
      }
    }
    function updateStatusAfterContactWork(payload) {
      setStatusAfterContactWork(payload.agentStatus);
    }
    async function uploadMediaLogoToS3({ URL, image }) {
      const response = await fetch(URL[PRESIGNED_S3_METHODS.PUT], {
        method: PRESIGNED_S3_METHODS.PUT,
        headers: {
          "Content-Type": "image/jpg",
        },
        body: image,
      });

      if (response.status === 403) {
        throw new ForbiddenError({ status: response.status, message: "Access Forbidden" });
      }

      return response;
    }

    async function fetchLogoMediaURL() {
      const customerBucket = getCustomerBucketName.value;

      if (!customerBucket) {
        throw new Error("Customer bucket not found");
      }

      const {
        getSignedS3URL: { items },
      } = await getSignedS3URLQuery({
        bucketName: customerBucket,
        key: INSTANCE_LOGO_PATH,
        method: PRESIGNED_S3_METHODS.ALL,
        contentType: "image/jpg",
      });

      if (!items?.length) {
        throw new Error("Logo media URL not found");
      }

      const logoMediaURL = items.reduce((acc, item) => {
        acc[item.Method] = item.Url;
        return acc;
      }, {});

      return logoMediaURL;
    }

    async function fetchAmazonConnectData({ userData }) {
      try {
        const getSecurityProfilesPromise = getSecurityProfiles(userData.InstanceId);
        const getRoutingProfilesPromise = getRoutingProfiles(userData.InstanceId);
        const promises = [getRoutingProfilesPromise, getSecurityProfilesPromise];

        const promiseResults = await Promise.allSettled(promises);
        const errors = promiseResults.filter((result) => result.status === PROMISE.REJECTED);
        if (errors.length > 0) {
          errors.forEach((error) => {
            throw error.reason;
          });
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function fetchApplicationData({ userData }) {
      try {
        const getConfigPromise = settingStore.getConfigs(userData.InstanceId);
        const getTasksPromise = getTasks(userData);
        const getTeamPromise = teamStore.onFetchTeam({ InstanceId: userData.InstanceId });
        const getIntegrationsPromise = integrationsStore.listIntegrations({ instanceId: userData.InstanceId });
        const getUsersPromise = userStore.onFetchUsers({ InstanceId: userData.InstanceId });
        const getAnnouncementsPromise = announcementStore.getList(userData.InstanceId);
        const getHealthPromise = healthStore.fetchHealth(null);
        const getChatConversationsPromise = chatStore.onFetchAgentChatConversations({
          InstanceId: userData.InstanceId,
          Participants: [userData.Username],
          Username: userData.Username,
        });
        const getContactsPromise = contactsStore.onFetchContacts({ InstanceId: userData.InstanceId, UserId: userData.Id });
        const getRecentActivityPromise = activityStore.onFetchRecentActivity({
          InstanceId: userData.InstanceId,
          Username: userData.Username,
        });
        const getCurrentGmtEpochTimeStampPromise = fetchClockOffset();

        const promises = [
          getConfigPromise,
          getTasksPromise,
          getTeamPromise,
          getIntegrationsPromise,
          getUsersPromise,
          getAnnouncementsPromise,
          getHealthPromise,
          getChatConversationsPromise,
          getContactsPromise,
          getRecentActivityPromise,
          getCurrentGmtEpochTimeStampPromise,
        ];

        const promiseResults = await Promise.allSettled(promises);

        const errors = promiseResults.filter((result) => result.status === PROMISE.REJECTED);
        if (errors.length > 0) {
          errors.forEach((error) => {
            console.error(error.reason);
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    function setClockOffset(payload) {
      clockOffset.value = payload;
    }
    function setLocalState(v) {
      profile.value.StatusName = v.name;
      profile.value.StatusStartTimestamp = v.startTimestamp
        ? new Date(v.startTimestamp).toISOString()
        : new Date(getTimeObject().offsetGetTime()).toISOString();
    }
    function setAdminLogoUrl(payload) {
      adminLogoUrl.value = payload;
    }
    function setLogoMediaURL(payload) {
      logoMediaURL.value = payload;
    }
    function updateSecurityProfileData(v) {
      profile.value.SecurityProfile = v;
    }
    function setSecurityProfile(v) {
      securityProfile.value = v;
    }
    function setCurrentSecurityProfile(v) {
      currentSecurityProfile.value = v;
    }
    function resetCurrentSecurityProfile() {
      currentSecurityProfile.value = {
        Name: null,
        Description: null,
        PermissionGroups: [],
        Realm: null,
        SoftPhone: "dextr",
      };
    }
    function setCurrentSecurityProfileName(v) {
      currentSecurityProfile.value.Name = v;
    }
    function setCurrentSecurityProfileDescription(v) {
      currentSecurityProfile.value.Description = v;
    }

    function setCurrentSecurityProfileRealm(v) {
      currentSecurityProfile.value.Realm = v;
    }
    function setCurrentSecurityProfileSoftPhone(v) {
      currentSecurityProfile.value.SoftPhone = v;
    }
    function setInstanceAccount(v) {
      account.value = {
        ...v,
      };
    }
    function setInstanceTimezone(v) {
      instanceTimezone.value.text = v.text || v._tz_text;
      instanceTimezone.value.value = v.value || v._tz_value;
    }
    function setNewNotif(v) {
      newNotif.value = v;
    }
    function setCurrentUser(payload) {
      let data = payload;
      if (payload?.Contacts && payload?.Contacts[0]?.State === "CONNECTED_ONHOLD") {
        data.StatusName = "Hold";
        data.StatusStartTimestamp = payload?.Contacts[0]?.StateStartTimestamp;
      }
      profile.value = { ...profile.value, ...data };
    }

    function setShowDrawer(payload) {
      postAnnouncement.value = payload;
      if (payload) newNotif.value = false;
    }
    function setTasks(task) {
      tasks.value = task;
    }
    function setSecurityProfiles(v, isNew) {
      if (!isNew) {
        securityProfiles.value = v;
      } else {
        securityProfiles.value.items.push(v);
      }
    }
    function setRoutingProfiles(v, isNew) {
      if (!isNew) {
        routingProfiles.value = v;
      } else {
        routingProfiles.value.items.push(v);
      }
      routingProfiles.value.items.sort((a, b) => {
        const nameA = a.Name.toUpperCase();
        const nameB = b.Name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    function setLoadingTasks(value) {
      loadingTasks.value = value;
    }
    function updateSecurityProfiles(payload) {
      for (let index = 0; index < securityProfiles.value.items.length; index++) {
        const element = securityProfiles.value.items[index];

        if (payload.id === element.id) {
          element.PermissionGroups = payload.PermissionGroups;
        }
      }
    }
    function setStatusAfterContactWork(payload) {
      statusAfterContactWork.value = payload;
    }
    function updateState(value) {
      instanceTimezone.value = value.instanceTimezone;
      securityProfiles.value = value.securityProfiles;
      adminLogoUrl.value = value.adminLogoUrl;
      profile.value = value.profile;
      postAnnouncement.value = value.postAnnouncement;
      newNotif.value = value.newNotif;
      announcements.value = value.announcements;
      securityProfile.value = value.securityProfile;
      currentSecurityProfile.value = value.currentSecurityProfile;
    }
    setInterval(() => {
      if (updateUsersList.length === 0) return;

      let arrayUsed = JSON.parse(JSON.stringify(updateUsersList));

      userStore.updateUser(arrayUsed);
      teamStore.updateTeam(arrayUsed);
      chatStore.updateUserSelected(arrayUsed);

      updateUsersList = [];
    }, 3000);

    window.onunload = () => {
      if (window.intervalUpdateUser) {
        clearInterval(window.intervalUpdateUser);
      }
    };

    function getQueueName(arn) {
      for (let x = 0; x < agentStore.queueTags.length; x++) {
        if (arn === agentStore.queueTags[x].queueARN) {
          return agentStore.queueTags[x].name;
        }
      }
    }

    function omitDeep(obj, key) {
      if (Array.isArray(obj)) return omitDeepArrayWalk(obj, key);
      const keys = Object.keys(obj);
      const newObj = {};
      keys.forEach((i) => {
        if (i !== key) {
          const val = obj[i];
          if (Array.isArray(val)) newObj[i] = omitDeepArrayWalk(val, key);
          else if (typeof val === "object" && val !== null) newObj[i] = omitDeep(val, key);
          else newObj[i] = val;
        }
      });
      return newObj;
    }

    function omitDeepArrayWalk(arr, key) {
      return arr.map((val) => {
        if (Array.isArray(val)) return omitDeepArrayWalk(val, key);
        else if (typeof val === "object") return omitDeep(val, key);
        return val;
      });
    }

    function isQueueExist(arn) {
      for (let x = 0; x < agentStore.queueTags.length; x++) {
        if (arn === agentStore.queueTags[x].queueARN) {
          return true;
        }
      }
      return false;
    }

    function renderNoticeTag(h, tags) {
      let list = [];

      if (!tags) {
        list.push(
          h(
            "Tag",
            {
              class: ["ivu-tag-blue"],
              prop: {
                bordered: true,
              },
            },
            ["None"]
          )
        );
        return list;
      }

      for (let x = 0; x < tags.length; x++) {
        if (!isQueueExist(tags[x])) continue;
        list.push(
          h(
            "Tag",
            {
              class: ["ivu-tag-blue"],
              prop: {
                bordered: true,
              },
            },
            [getQueueName(tags[x])]
          )
        );
      }

      return list;
    }

    function setInstanceData(payload) {
      instanceData.value = payload;
    }
    return {
      //States
      instanceTimezone,
      clockOffset,
      adminLogoUrl,
      logoMediaURL,
      CognitoPoolId,
      agentOnCall,
      securityProfiles,
      routingProfiles,
      account,
      profile,
      postAnnouncement,
      newNotif,
      announcements,
      tasks,
      loadingTasks,
      securityProfile,
      currentSecurityProfile,
      statusAfterContactWork,
      currentRoutingProfile,
      instanceData,
      //Getters
      getClockOffset,
      acwTimeLimit,
      userRole,
      agentStatus,
      listSecurityProfiles,
      instanceAccount,
      getAdminLogoUrl,
      getLogoMediaURL,
      userInstanceTimezone,
      instanceTimezoneOffset,
      isShowDrawer,
      onNewNotif,
      isUserAnInstanceAdmin,
      getProfileData,
      isAllowPostAnnouncement,
      getUserTasks,
      instanceId,
      getAllRoutingProfiles,
      getUsername,
      getRoutingProfilesList,
      securityProfileName,
      isTheSecurityProfileAdmin,
      getDashboardSecurityGroup,
      getStatusAfterContactWork,
      listSecurityGroup,
      getSecurityProfile,
      getMediaConcurrencies,
      getCurrentSecurityProfile,
      getCustomerBucketName,
      getInstanceData,
      //Actions
      fetchClockOffset,
      setLocalStateAction,
      hydrateApp,
      getNetworkTraversal,
      signout,
      deleteSecurityProfiles,
      getTasks,
      getSecurityProfiles,
      getRoutingProfiles,
      createSecurityProfile,
      updateSecurityProfile,
      updateStateSecurityProfiles,
      setCurrentUserData,
      applyDataInApplication,
      setUserDataApplication,
      applyUserPreferences,
      applyAccountData,
      handleSubscriptions,
      onSendHelp,
      onUpdateCurrentUser,
      createUserSubs,
      deleteUserSubs,
      reportSubs,
      onUpdateSecurityProfile,
      onNeedsHelp,
      onLoginSubs,
      onNewAnnouncementSubs,
      onSaveTimezone,
      integrationsSubs,
      integrationsDeletedSubs,
      updateUsersSubs,
      updateUserSubs,
      onOfferedMonitor,
      onOfferedVoiceCall,
      onDestroyedVoiceCall,
      onAnsweredMonitor,
      onAnsweredVoiceCall,
      updateUserSecuritySubs,
      newActivitySubs,
      newActivityFromPeer,
      updateAccount,
      onNewAnnouncement,
      listOfAnnouncement,
      getSecurityProfileById,
      deleteAnnouncement,
      deleteAnnouncementSubs,
      updateCurrentSecurityProfileSoftPhone,
      setAgentStatus,
      setAgentStatusByIdle,
      updateStatusAfterContactWork,
      uploadMediaLogoToS3,
      fetchLogoMediaURL,
      fetchAmazonConnectData,
      fetchApplicationData,
      //Mutations
      setClockOffset,
      setLocalState,
      setAdminLogoUrl,
      setLogoMediaURL,
      updateSecurityProfileData,
      setSecurityProfile,
      setCurrentSecurityProfile,
      resetCurrentSecurityProfile,
      setCurrentSecurityProfileName,
      setCurrentSecurityProfileDescription,
      setCurrentSecurityProfileRealm,
      setCurrentSecurityProfileSoftPhone,
      setInstanceAccount,
      setInstanceTimezone,
      setNewNotif,
      setCurrentUser,
      setShowDrawer,
      setTasks,
      setSecurityProfiles,
      setRoutingProfiles,
      setLoadingTasks,
      updateSecurityProfiles,
      setStatusAfterContactWork,
      updateState,
      setInstanceData,
    };
  },
  {
    persist: true,
  }
);
