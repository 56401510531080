import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { getTimeObject } from "@/utils/time/getTimeObject";
import { DateTime } from "luxon";
import { useCurrentUserStore } from "./current-user";
export const useDashboardStore = defineStore(
  "dashboard",
  () => {
    const currentUserStore = useCurrentUserStore();

    const timeSeriesType = ref("");
    const agentStatusFilter = ref("");
    const startDate = ref(getTimeObject().getOffsetTime());
    const endDate = ref(getTimeObject().getOffsetTime());
    const selectedFilter = ref({
      label: "Today",
      value: "today",
    });
    const isPresent = ref(true);
    const agentPerformanceTable = ref([]);
    const queuePerformanceTable = ref([]);
    const agentPerformanceOfflineFilter = ref(false);
    const selectedDropdownIndex = ref(0);

    const dateRange = computed(() => {
      const timezone = currentUserStore.instanceTimezone.value;

      let start = DateTime.fromJSDate(startDate.value, { zone: timezone });
      let end = DateTime.fromJSDate(endDate.value, { zone: timezone });
      if (end.day !== start.day) {
        start = start.set({ day: end.day });
      }

      return [start.toISO(), end.toISO()];
    });
    const getAgentPerformanceOfflineFilter = computed(() => agentPerformanceOfflineFilter.value);
    const getTimeSeriesType = computed(() => timeSeriesType.value);
    const getSelectedDropdownIndex = computed(() => selectedDropdownIndex.value);

    function updateAgentPerformanceOfflineFilter(payload) {
      setAgentPerformanceOfflineFilter(payload);
    }
    function updateTimeSeriesType(payload) {
      setTimeSeriesType(payload);
    }
    function setTimeSeriesType(value) {
      timeSeriesType.value = value;
    }
    function setAgentStatusFilter(value) {
      agentStatusFilter.value = value;
    }
    function setAgentPerformanceOfflineFilter(value) {
      agentPerformanceOfflineFilter.value = value;
    }

    function setStartDate(value) {
      startDate.value = value;
    }
    function setEndDate(value) {
      endDate.value = value;
    }
    function setSelectedFilter(value) {
      selectedFilter.value = value;
    }
    function setIsPresent(value) {
      isPresent.value = value;
    }
    function setAgentPerformanceTable(value) {
      agentPerformanceTable.value = value;
    }
    function setQueuePerformanceTable(value) {
      queuePerformanceTable.value = value;
    }
    function setDropdownIndex(value) {
      selectedDropdownIndex.value = value;
    }
    return {
      //States
      timeSeriesType,
      agentStatusFilter,
      startDate,
      endDate,
      selectedFilter,
      isPresent,
      agentPerformanceTable,
      queuePerformanceTable,
      agentPerformanceOfflineFilter,
      selectedDropdownIndex,
      //Getters
      dateRange,
      getAgentPerformanceOfflineFilter,
      getTimeSeriesType,
      getSelectedDropdownIndex,
      //Actions
      updateAgentPerformanceOfflineFilter,
      updateTimeSeriesType,
      //Mutations
      setTimeSeriesType,
      setAgentStatusFilter,
      setAgentPerformanceOfflineFilter,
      setStartDate,
      setEndDate,
      setSelectedFilter,
      setIsPresent,
      setAgentPerformanceTable,
      setQueuePerformanceTable,
      setDropdownIndex,
    };
  },
  {
    persist: true,
  }
);
