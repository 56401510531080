import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { HIDHandler, supportedDevices } from "@/common/services/hid.service.js";
import { Notice } from "view-design";
import { useApiStore } from "./api";

export const useHidStore = defineStore(
  "hid",
  () => {
    const apiStore = useApiStore();
    const mute = ref(false);
    const onhook = ref(false);
    const offhook = ref(false);
    const available = ref(false);
    const activeHeadsetAdapter = ref(null);

    const isActiveHeadsetAdapter = computed(() => {
      if (!activeHeadsetAdapter.value) {
        return false;
      } else {
        return true;
      }
    });

    function sendCommand(cmd) {
      activeHeadsetAdapter?.value[cmd];
    }
    function sendCommandWithArguments({ cmd, value }) {
      activeHeadsetAdapter.value?.[cmd](value);
    }
    async function registerSupportedDevice(vue) {
      let supportedHandler;
      let devices = await navigator.mediaDevices.enumerateDevices();

      if (activeHeadsetAdapter.value) {
        await showNotice("info", "disconnected");
        activeHeadsetAdapter.value.unsubscribe();
        activeHeadsetAdapter.value = null;
        return;
      }
      let gen;
      if (apiStore.getJabraGen === null || apiStore.getJabraGen === "Gen 2") {
        gen = 2;
      } else if (apiStore.getJabraGen === "Gen 3") {
        gen = 3;
      }
      devices.some((device) => {
        for (let supportedDevice of supportedDevices) {
          if (device.label?.includes(supportedDevice.model)) {
            supportedHandler = HIDHandler(supportedDevice.type, supportedDevice.oem, gen)();
            break;
          }
        }
      });

      if (supportedHandler) {
        activeHeadsetAdapter.value = new (await supportedHandler).default();
        await activeHeadsetAdapter.value.register(vue);
        showNotice("success", "connected");
      }
    }
    function showNotice(type, status) {
      Notice[type]({
        title: `${activeHeadsetAdapter.value.getDeviceName()} ${status}.`,
        duration: 5,
        render: (h) => {
          return h(
            "div",
            {
              style: {
                textAlign: "center",
              },
            },
            [
              h("span", [
                h("img", {
                  style: {
                    width: "75px",
                  },
                  attrs: {
                    src: activeHeadsetAdapter.value.getLogo(),
                  },
                }),
              ]),
            ]
          );
        },
      });
    }
    function setActiveHeadsetAdapter(payload) {
      activeHeadsetAdapter.value = payload;
    }

    return {
      //States
      mute,
      onhook,
      offhook,
      available,
      activeHeadsetAdapter,
      //Getters
      isActiveHeadsetAdapter,
      //Actions
      sendCommand,
      sendCommandWithArguments,
      registerSupportedDevice,
      //Mutations
      setActiveHeadsetAdapter,
    };
  },
  {
    persist: true,
  }
);
