import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { listAnnouncementsQuery } from "@/common/services/graphql.service";
import { useAgentsStore } from "./agents";
import { useCurrentUserStore } from "./current-user";
export const useAnnouncementStore = defineStore(
  "announcement",
  () => {
    const agentStore = useAgentsStore();
    const currentUserStore = useCurrentUserStore();
    const list = ref([]);
    const selectedItem = ref({});

    const activeCalls = computed(() => {
      if (list.value) {
        return list.value.filter((announcement) => {
          if (announcement.DeliveryMethods) {
            return announcement.DeliveryMethods.indexOf("active_call") > -1;
          }
          return false;
        });
      }
      return [];
    });
    const marqueScrollers = computed(() => {
      if (list.value) {
        return list.value.filter((announcement) => {
          if (announcement.DeliveryMethods) {
            return announcement.DeliveryMethods.indexOf("marquee_scroller") > -1;
          }
          return false;
        });
      }
      return [];
    });

    async function getList(InstanceId) {
      const instanceId = agentStore.agentSummary.InstanceId || InstanceId;
      const queues = currentUserStore?.profile?.Queues;

      let userQuery = await listAnnouncementsQuery({
        InstanceId: instanceId,
        Queues: queues,
        limit: 50,
      });
      const agentQueue = agentStore?.queueTags.filter((tag) => tag.queueId != "global");
      const announcements = userQuery?.listAnnouncements?.items.filter((announcement) => {
        const { Queues } = announcement;
        return agentQueue.some((queue) => Queues.includes(queue.queueARN));
      });

      setList(announcements);
    }
    function setList(value) {
      list.value = value;
    }
    function addItem(value) {
      list.value.push(value);
    }
    function setSelectedItem(value) {
      selectedItem.value = value;
    }
    function initAnnouncement() {
      list.value = [];
      selectedItem.value = {};
    }
    function updateState(value) {
      list.value = value.list;
      selectedItem.value = value.selectedItem;
    }

    return {
      //States
      list,
      selectedItem,
      //Getters
      activeCalls,
      marqueScrollers,
      //Actions
      getList,
      //Mutations
      setList,
      addItem,
      setSelectedItem,
      initAnnouncement,
      updateState,
    };
  },
  {
    persist: true,
  }
);
