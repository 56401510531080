import { defineStore } from "pinia";
import { ref } from "vue";
import { DateTime } from "luxon";
import {
  listScheduleEventsQuery,
  createScheduleEventMutation,
  updateScheduleEventMutation,
  deleteScheduleEventMutation,
} from "@/common/services/graphql.service";
import { useCurrentUserStore } from "./current-user";
export const useHolidayStore = defineStore(
  "holiday",
  () => {
    const currentUserStore = useCurrentUserStore();
    const list = ref({
      listScheduleEvents: {
        items: [],
      },
    });

    async function onFetchHolidays({ InstanceId, Queues }) {
      try {
        const res = await listScheduleEventsQuery({
          InstanceId,
          Queues: Queues,
        });
        listSched(res);
      } catch (err) {
        return err;
      }
    }
    async function onCreateHolidays(payload) {
      const zone = currentUserStore.account.TimeZone.value;
      payload.startTime = DateTime.fromISO(new Date(payload.startTime).toISOString())
        .setZone(zone, { keepLocalTime: true })
        .startOf("minute")
        .toUTC()
        .toISO()
        .toString();

      payload.endTime = DateTime.fromISO(new Date(payload.endTime).toISOString())
        .setZone(zone, { keepLocalTime: true })
        .endOf("minute")
        .toUTC()
        .toISO()
        .toString();

      try {
        const res = await createScheduleEventMutation(payload);
        createSched(res);
      } catch (err) {
        return err;
      }
    }
    async function onUpdateHolidays(payload) {
      let zone = currentUserStore.account.TimeZone.value;

      payload.startTime = DateTime.fromISO(new Date(payload.startTime).toISOString())
        .setZone(zone, { keepLocalTime: true })
        .startOf("minute")
        .toUTC()
        .toISO()
        .toString();

      payload.endTime = DateTime.fromISO(new Date(payload.endTime).toISOString())
        .setZone(zone, { keepLocalTime: true })
        .endOf("minute")
        .toUTC()
        .toISO()
        .toString();

      delete payload._index;
      delete payload._rowKey;
      delete payload._isHover;
      delete payload.isRootInsert;
      payload.InstanceId = currentUserStore.profile.InstanceId;

      try {
        const res = await updateScheduleEventMutation(payload);
        updateSched(res);
      } catch (err) {
        console.error("Update Failed", err);
        return err;
      }
    }
    async function onDeleteHolidays(payload) {
      delete payload._index;
      delete payload._rowKey;
      try {
        const res = await deleteScheduleEventMutation(payload);
        deleteSched(res);
      } catch (err) {
        return err;
      }
    }
    function listSched(v) {
      list.value = v;
    }
    function createSched(v) {
      list.value.listScheduleEvents.items.unshift(v.createScheduleEvent);
    }
    function updateSched(v) {
      for (let x = 0; x < list.value.listScheduleEvents.items.length; x++) {
        if (list.value.listScheduleEvents.items[x].id === v.updateScheduleEvent.id) {
          list.value.listScheduleEvents.items.splice(x, 1, v.updateScheduleEvent);
          break;
        }
      }
    }
    function deleteSched(v) {
      let index = list.value.listScheduleEvents.items.findIndex((item) => item.id === v.deleteScheduleEvent.id);
      list.value.listScheduleEvents.items.splice(index, 1);
    }
    function initHoliday() {
      list.value = {
        listScheduleEvents: {
          items: [],
        },
      };
    }
    function updateState(value) {
      list.value = value.list;
    }
    return {
      //States
      list,
      //Actions
      onFetchHolidays,
      onCreateHolidays,
      onUpdateHolidays,
      onDeleteHolidays,
      //Mutations
      listSched,
      createSched,
      updateSched,
      deleteSched,
      initHoliday,
      updateState,
    };
  },
  {
    persist: true,
  }
);
