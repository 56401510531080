import { defineStore } from "pinia";
import { computed, ref } from "vue";
import { get, orderBy, isEmpty } from "lodash";
import { isValidPhoneNumber } from "@/common/support";
import { i18n } from "@/plugins/language";
import { copyToClipboard } from "@connectpath/common-frontend/src/helpers/navigator";
import { DateTime } from "luxon";
import subDays from "date-fns/sub_days";
import { encodeToBase64 } from "@connectpath/common/src/helpers/base64";
import { getTimeObject } from "@/utils/time/getTimeObject";
import {
  getLevelListsQuery,
  describeActivityQuery,
  listActivityQuery,
  listTranscriptsHistoryQuery,
  updateActivityMutation,
  listCtrQuery,
  listRelatedActivityQuery,
} from "@/common/services/graphql.service";
import { CHANNELS } from "@connectpath/common-frontend/src";
import { useSettingsStore } from "./settings";
import { useQueueStore } from "./queue";
import { useAgentsStore } from "./agents";
import { useCurrentUserStore } from "./current-user";
import { useIndexStore } from "./index";

export const useActivityStore = defineStore(
  "activity",
  () => {
    const settingStore = useSettingsStore();
    const queueStore = useQueueStore();
    const agentStore = useAgentsStore();
    const currentUserStore = useCurrentUserStore();
    const indexStore = useIndexStore();

    const list = ref({ items: [], cursor: null, limit: null, count: null });
    const listAGGrid = ref({ items: [], cursor: null, limit: null, count: null });
    const listRecent = ref({
      cursor: null,
      items: [],
      limit: null,
    });
    const areThereMoreResults = ref(false);
    const activityLimit = ref(50);
    const showCallDispositionModal = ref(false);
    const isRecordingCallDisposition = ref(false);
    const hangingActivity = ref(null);
    const fetchedPages = ref([]);
    const detailedActivity = ref(null);
    const version = ref(null);
    const DynamicsEntityId = ref(null);

    const areThereMoreResultsGetter = computed(() => !!areThereMoreResults.value);

    const detailedActivityGetter = computed(() => detailedActivity.value);
    const mCursorGetter = computed(() => get(list.value, "cursor", null));
    const initiationMethodFilterGetter = (e) => {
      if (e.Channel === "chat") return e.InitiationMethod;
      if (e.Channel === "email") return e.InitiationMethod;
      if (e.Channel === "sms") return e.InitiationMethod;

      if (isValidPhoneNumber(e.CustomerEndpoint)) {
        return e.InitiationMethod;
      }
      if (e.InitiationTimestamp === e.DisconnectTimestamp) {
        return "MISSED";
      }
      return e.InitiationMethod;
    };

    const initiationMethodLabelGetter = (item) => {
      if (item.Channel === "queue_callback" && item.InitiationMethod === "MISSED") return "MISSED CALLBACK";
      if (item.Channel == "queue_callback" && item.InitiationMethod == "INBOUND") return "CALLBACK";
      return item.InitiationMethod;
    };
    const myActivityGetter = computed(() => list.value);
    const myActivityAGGridGetter = computed(() => listAGGrid.value);
    const todaysActivity = computed(() => {
      const { EMAIL, SMS, TASK, VOICE, CHAT } = CHANNELS;
      let recent = listRecent.value?.items?.filter((event) => {
        if (
          event.Channel === EMAIL ||
          event.Channel === SMS ||
          event.Channel === TASK ||
          event.Channel === VOICE ||
          event.Channel === CHAT
        ) {
          return event;
        }
      });

      return orderBy(recent, "InitiationTimestamp", "desc");
    });
    const myListRecentGetter = computed(() => listRecent.value);
    const myRecentGetter = computed(() => {
      return listRecent.value?.items
        .filter((item) => item.Channel === "voice" || item.Channel === "queue_callback")
        .slice(0, 20);
    });
    const myRecentChatsGetter = computed(() => []);

    const recentCallsGetter = computed(() => orderBy(list.value?.items, "InitiationTimestamp", "desc"));
    const getVersionGetter = computed(() => version.value);
    const getShowCallDispositionModalGetter = computed(() => showCallDispositionModal.value);

    async function shouldWeShowMultiLevelSeriesDispositions({ InstanceId, Level, Queues }) {
      try {
        const payload = {
          InstanceId,
          Level,
          Queues,
        };
        const response = await getLevelListsQuery(payload);

        if (response.getLevelLists?.items?.length) {
          await settingStore.updateAvailableCallDispositions({
            items: response.getLevelLists.items,
            isMultiLevel: true,
          });

          return true;
        }
      } catch (error) {
        console.error(`Error > activity.js > actions > shouldWeShowMultiLevelSeriesDispositions > error > `, error);
      }
      return false;
    }
    function getActivityById({ activityId }) {
      const listActivities = myListRecentGetter.value.items;
      if (!listActivities.length) return null;
      return listActivities.find((activity) => activity.id === activityId);
    }
    async function getQueueForDisposition({ contactId, isOutbound, DispositionOutboundQueue }) {
      if (isOutbound) {
        if (!DispositionOutboundQueue) return;
        return await queueStore.getQueueByQueueName({ queueName: DispositionOutboundQueue });
      } else {
        const activity = await getActivityById({ activityId: contactId });
        const { Queue: queueName } = activity;
        if (!queueName) return;
        return await queueStore.getQueueByQueueName({ queueName });
      }
    }
    async function checkShowDispositionModal({ contactId, isOutbound, DispositionOutboundQueue }) {
      if (isEmpty(contactId)) return;
      const isCallDispositionEnabled = settingStore.isCallDispositionEnabled;
      if (!isCallDispositionEnabled) return;
      const isCustomInputDispositionEnabled = settingStore.isCustomInputDispositionEnabled;
      const isMultilevelDispositionEnabled = settingStore.isMultilevelDispositionEnabled;

      const queue = await getQueueForDisposition({ contactId, isOutbound, DispositionOutboundQueue });
      if (!queue) return;

      if (isMultilevelDispositionEnabled) {
        await showMultilevelDispositionModal(queue);
        return;
      }
      if (isCustomInputDispositionEnabled) {
        await showCustomInputDispositionModal(queue);
        return;
      }
      await showSingleDispositionModal(queue);
    }
    async function showSingleDispositionModal(queue) {
      await settingStore.setAvailableCallDispositionsByQueue({ queueArn: queue.Arn });
      const availableCallDispositions = settingStore.isAvailableCallDispositions;
      if (!availableCallDispositions) return;
      showCallDispositionModal.value = true;
    }
    async function showCustomInputDispositionModal(queue) {
      await settingStore.setAvailableCallDispositionsByQueue({ queueArn: queue.Arn });
      showCallDispositionModal.value = true;
    }
    async function showMultilevelDispositionModal(queueContact) {
      const queue = agentStore.queueTags.find((tag) => tag.queueARN === queueContact.Arn);
      const InstanceId = currentUserStore.account.InstanceId;
      const shouldWeShowMultiLevelSeriesDispositions = await shouldWeShowMultiLevelSeriesDispositions({
        InstanceId,
        Level: 1,
        Queues: [
          {
            Arn: queue.queueARN,
            id: queue.queueId,
          },
        ],
      });
      if (!shouldWeShowMultiLevelSeriesDispositions) return;
      showCallDispositionModal.value = true;
    }
    async function onDescribeActivity(payload) {
      try {
        const res = await describeActivityQuery(payload);
        return res?.describeActivity;
      } catch (err) {
        return err;
      }
    }
    async function onFetchRecentActivity(payload) {
      let now = getTimeObject().offsetToISOString();
      let defaultPayload = {
        limit: 150,
        filter: JSON.stringify({
          dateTimeRange: {
            start: subDays(now, 1),
            end: now,
          },
          keyword: `${payload.Username}`,
        }),
        InstanceId: payload.InstanceId,
      };
      try {
        const res = await listActivityQuery(defaultPayload);
        listRecent.value.items = res?.listActivity?.items;
        return res?.listActivity;
      } catch (err) {
        return err;
      }
    }
    async function onNextPageFetchRecentActivity({ InstanceId, Username, cursor, filter }) {
      let defaultPayload = {
        InstanceId,
        Username,
        cursor,
        filter,
        limit: 20,
      };

      let rules = indexStore.rules.filter((rule) => rule.actions === "view");
      let isShowAll = rules.some((rule) => rule.subject === "others_activity");
      let isSameQueue = rules.some((rule) => rule.subject === "same_queue");
      if (isShowAll) delete defaultPayload.Username;
      if (isSameQueue) {
        defaultPayload.queues = agentStore.queueTags.filter((queue) => queue.queueId !== "global").map((queue) => queue.name);
      }
      try {
        const res = await listActivityQuery(defaultPayload);
        const listActivityResp = res?.listActivity;
        listRecent.value.cursor = listActivityResp.cursor;
        listActivityResp.items.forEach((item) => {
          listRecent.value.items.push(item);
        });
        listRecent.value.limit = listActivityResp.limit;
      } catch (err) {
        return err;
      }
    }
    async function onFetchActivity(payload) {
      let defaultPayload = {
        ...payload,
      };

      try {
        const res = await listActivityQuery(defaultPayload);
        setActivityList(res?.listActivity?.items);
        list.value.cursor = res?.listActivity?.cursor;
        list.value.count = Number(res?.listActivity?.count);
        return res?.listActivity;
      } catch (err) {
        return err;
      }
    }
    async function listTrascriptsHistory(payload) {
      let defaultPayload = {
        ...payload,
      };
      try {
        const res = await listTranscriptsHistoryQuery(defaultPayload);
        return res?.listTranscriptsHistory || [];
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    async function onNextPageFetchActivity({ InstanceId, Username, cursor, filter }) {
      let defaultPayload = {
        InstanceId,
        Username,
        cursor,
        filter,
        limit: filter.pageNumber * 100,
      };

      let rules = indexStore.rules.filter((rule) => rule.actions === "view");
      let isShowAll = rules.some((rule) => rule.subject === "others_activity");
      let isSameQueue = rules.some((rule) => rule.subject === "same_queue");
      if (isShowAll) delete defaultPayload.Username;
      if (isSameQueue) {
        defaultPayload.queues = agentStore.queuetags.filter((queue) => queue.queueId !== "global").map((queue) => queue.name);
      }
      try {
        const res = await listActivityQuery(defaultPayload);
        if (list.value.cursor === res?.listActivity?.cursor) {
          list.value.cursor = null;
        } else {
          list.value.cursor = res?.listActivity?.cursor;
        }
        list.value.limit = res?.listActivity?.limit;

        list.value.items = list.value.items.concat(res?.listActivity?.items);
      } catch (err) {
        return err;
      }
    }
    async function getRecentActivity(payload) {
      let queueSelectedArn = queueStore.selectedQueue;

      let defaultPayload = {
        limit: 100,
        filter: {
          daterange: {
            start: DateTime.local().startOf("day").toUTC().toISO(),
            end: DateTime.local().endOf("day").toUTC().toISO(),
          },
        },
        ...payload,
      };

      if (queueSelectedArn !== "global") {
        let tags = agentStore.queueTags;
        const tag = tags.find((tag) => tag.queueARN === queueSelectedArn);
        if (tag) defaultPayload.filter.queue = tag.name;
      }

      const res = await listActivityQuery(defaultPayload);

      return res;
    }
    async function recordCallDisposition(payload) {
      const resp = await updateActivityMutation(payload);
      return resp;
    }

    function setDetailedActivity(payload) {
      let detailedActivity = payload;
      if (payload && payload.length > 0) {
        detailedActivity = payload[0];
      }
      setDetailedActivityMutation(detailedActivity);
    }
    async function onDescribeActivityAGGrid(payload) {
      try {
        const response = await describeActivityQuery(payload);
        return response.describeActivity;
      } catch (error) {
        console.error(`Error > activity.js > actions > onDescribeActivityAGGrid > error > `, error);
      }
    }

    async function onFetchActivityAGGrid({ payload, callback }) {
      try {
        const limit = 2500;
        listAGGrid.value.items = [];
        areThereMoreResults.value = false;

        let response = await listCtrQuery({ ...payload, limit });

        setActivityListAGGrid(response.listCtr);

        callback();
        let iterations = 0;
        while (response.listCtr?.areTherePendingItems && iterations < 3) {
          const lastRange = JSON.parse(atob(response.listCtr.cursor)).range;
          const newEnd = DateTime.fromMillis(Number(lastRange) - 1)
            .setZone(currentUserStore.instanceTimezone.value)
            .toUTC()
            .toISO()
            .toString();
          let payload2 = payload;
          let filterJSON = JSON.parse(payload.filter);
          filterJSON.dateTimeRange.end = newEnd;
          payload2.filter = JSON.stringify(filterJSON);
          let response2 = await listCtrQuery({ ...payload2, limit });
          let items = response.listCtr.items.concat(response2.listCtr.items);
          response = response2;
          response.listCtr.items = items;
          listAGGrid.value.items = response.listCtr;
          iterations++;
        }

        areThereMoreResults.value = !!response.listCtr?.areTherePendingItems;

        return response.listCtr;
      } catch (error) {
        console.error(`Error > activity.js > actions > shouldWeShowMultiLevelSeriesDispositions > error > `, error);
      }
    }
    function saveVersion(payload) {
      version.value = payload;
    }
    async function shareActivity({ dispatch }, paramsToShareActivity) {
      try {
        const paramsEncodedToBase64 = encodeToBase64(JSON.stringify(paramsToShareActivity));
        const urlToShareActivity = `${window.location.origin}/activitynew?search=${paramsEncodedToBase64}`;
        await copyToClipboard(urlToShareActivity);
        dispatch("ui/messageEndpointSuccess", { message: i18n.t("activity.successCopyUrlToClipboard") }, { root: true });
      } catch (error) {
        dispatch("ui/messageEndpointError", { message: i18n.t("activity.errorCopyUrlToClipboard") }, { root: true });
        console.error(`Error > activity.js > actions > shareActivity > error >`, error);
      }
    }
    async function getRelatedActivity(payload) {
      try {
        const limit = 100;
        const response = await listRelatedActivityQuery({ ...payload, limit });
        return response?.listRelatedActivity;
      } catch (error) {
        console.error(`Error > activity.js > actions > relatedActivity > error > `, error);
      }
    }
    // Mutations

    function setAreThereMoreResults(payload) {
      areThereMoreResults.value = payload;
    }
    function setActivityLimit(payload) {
      activityLimit.value = payload;
    }
    function setActivityList(payload) {
      list.value.items = payload.items;
    }
    function setActivityListAGGrid(payload) {
      listAGGrid.value.items = payload.items;
    }
    function setRecentActivityList(payload) {
      listRecent.value.items = payload.items;
    }
    function concatListRecent(payload) {
      listRecent.value.cursor = payload.cursor;
      payload.items.forEach((item) => {
        listRecent.value.items.push(item);
      });
      listRecent.value.limit = payload.limit;
    }
    function newActivity(payload) {
      list.value.items.push(payload);
    }
    function setHangingActivity(payload) {
      hangingActivity.value = payload;
    }
    function setDynamicsUrl(payload) {
      DynamicsEntityId.value = payload;
    }
    function newListRecentActivity(payload) {
      if (payload) {
        let index = listRecent.value?.items?.findIndex((i) => guid(i) === guid(payload));
        if (index === -1) listRecent.value.items.unshift(payload);
        DynamicsEntityId.value = payload.DynamicsEntityId;
      }
    }
    function updateListRecentActivity(payload) {
      let c = listRecent.value.items.findIndex((i) => i.id == payload.id);
      if (c !== -1) {
        let p = { ...listRecent.value?.items[c], ...payload };
        listRecent.value.items.splice(c, 1, p);
      }
    }
    function updateOrNewListRecentActivity(payload) {
      let c = listRecent.value?.items?.findIndex((i) => guid(i) === guid(payload));
      if (c && c !== -1) {
        let p = { ...listRecent.value?.items[c], ...payload };
        listRecent.value?.items?.splice(c, 1, p);
      } else {
        listRecent?.value.items?.unshift(payload);
      }
    }
    function updateOrNewActivity(payload) {
      let c = list.value?.items.findIndex((i) => guid(i) === guid(payload));
      if (c !== -1) {
        let p = { ...list.value.items[c], ...payload };
        list.value.items.splice(c, 1, p);
      } else {
        list.value.items.push(payload);
      }
    }
    function hideDispositionModal() {
      showCallDispositionModal.value = false;
    }
    function showDispositionModal() {
      showCallDispositionModal.value = true;
    }
    function setRecordingDispositionFlag(payload) {
      isRecordingCallDisposition.value = payload;
    }
    function setFetchedPages(value) {
      fetchedPages.value = value;
    }
    function initActivity() {
      list.value = { items: [], cursor: null, limit: null };
      listRecent.value = {
        cursor: null,
        items: [],
        limit: null,
      };
      activityLimit.value = 50;
      showCallDispositionModal.value = false;
      isRecordingCallDisposition.value = false;
      hangingActivity.value = null;
    }
    function updateState(value) {
      list.value = value.list;
      listRecent.value = value.listRecent;
      activityLimit.value = value.activityLimit;
      showCallDispositionModal.value = value.showCallDispositionModal;
      isRecordingCallDisposition.value = value.isRecordingCallDisposition;
      hangingActivity.value = value.hangingActivity;
    }
    function setDetailedActivityMutation(value) {
      detailedActivity.value = value;
    }
    function setTotalCount(value) {
      list.value.totalCount = value;
    }
    function updateVersion(value) {
      version.value = value;
    }

    function guid(payload) {
      return payload.id;
    }
    return {
      //States
      list,
      listAGGrid,
      listRecent,
      activityLimit,
      showCallDispositionModal,
      isRecordingCallDisposition,
      hangingActivity,
      fetchedPages,
      detailedActivity,
      version,
      DynamicsEntityId,
      //Getters
      areThereMoreResultsGetter,
      detailedActivityGetter,
      mCursorGetter,
      initiationMethodFilterGetter,
      initiationMethodLabelGetter,
      myActivityGetter,
      myActivityAGGridGetter,
      todaysActivity,
      myListRecentGetter,
      myRecentGetter,
      myRecentChatsGetter,
      recentCallsGetter,
      getVersionGetter,
      getShowCallDispositionModalGetter,
      //Actions
      shouldWeShowMultiLevelSeriesDispositions,
      getActivityById,
      getQueueForDisposition,
      checkShowDispositionModal,
      showSingleDispositionModal,
      showCustomInputDispositionModal,
      showMultilevelDispositionModal,
      onDescribeActivity,
      onFetchRecentActivity,
      onNextPageFetchRecentActivity,
      onFetchActivity,
      listTrascriptsHistory,
      onNextPageFetchActivity,
      getRecentActivity,
      recordCallDisposition,
      setDetailedActivity,
      onDescribeActivityAGGrid,
      onFetchActivityAGGrid,
      saveVersion,
      shareActivity,
      getRelatedActivity,
      //Mutations
      setAreThereMoreResults,
      setActivityLimit,
      setActivityList,
      setActivityListAGGrid,
      setRecentActivityList,
      concatListRecent,
      newActivity,
      setHangingActivity,
      setDynamicsUrl,
      newListRecentActivity,
      updateListRecentActivity,
      updateOrNewListRecentActivity,
      updateOrNewActivity,
      hideDispositionModal,
      showDispositionModal,
      setRecordingDispositionFlag,
      setFetchedPages,
      initActivity,
      updateState,
      setDetailedActivityMutation,
      setTotalCount,
      updateVersion,
    };
  },
  {
    persist: true,
  }
);
