import { defineStore } from "pinia";
import { computed, ref } from "vue";
import Vue from "vue";
import ClassicRingtone from "@/assets/Ringing_Phone_2.mp3";
import availableTones from "@/assets/tones-b";
import addSeconds from "date-fns/add_seconds";
import format from "date-fns/format";
import _ from "lodash";
import { i18n } from "@/plugins/language";
import { getTimeObject } from "@/utils/time/getTimeObject";
const fetchQuickResponses = () => import("@/common/services/channel.service").then((module) => module.fetchQuickResponses);
import {
  getChatSuggestionsQuery,
  newAgentChatParticipantEventSubscription,
  getAgentChatConversationQuery,
  newAgentChatMessageEventSubscription,
  newAgentChatMessageEventReceiverSubscription,
  listAgentChatConversationsQuery,
  CreateAgentChatConversationMutation,
  listAgentChatMessagesQuery,
  getAvatarQuery,
  UpdateAgentChatMessageMutation,
  MarkAllMessagesReadMutation,
} from "@/common/services/graphql.service";
import { useUiStore } from "./ui";
import { useIndexStore } from "./index";
import { useCurrentUserStore } from "./current-user";
import { useTeamStore } from "./team";
import { useApiStore } from "./api";

const playAudioFile = _.debounce(
  (audio) => {
    const ding = new Audio(audio);
    ding.play();
  },
  500,
  { leading: true, trailing: false }
);

export const useChatStore = defineStore(
  "chat",
  () => {
    const uiStore = useUiStore();
    const indexStore = useIndexStore();
    const currentUserStore = useCurrentUserStore();
    const teamStore = useTeamStore();
    const apiStore = useApiStore();
    const showChatDrawer = ref(false);
    const userSelected = ref({});
    const showProfileDetail = ref(false);
    const chatConversations = ref([]);
    const messages = ref({});
    const suggestions = ref([]);
    const quickResponses = ref({
      isEnabled: false,
      items: [],
    });
    const recentUsers = ref([]);

    const showSuggestions = computed(() => suggestions.value);
    const showChatDrawerGetter = computed(() => showChatDrawer.value);
    const viewUser = computed(() => userSelected.value);
    const showDetails = computed(() => showProfileDetail.value);
    const getConversationByParticipants = (participants) => {
      const flattenConversations = chatConversations.value.map((conversation) => {
        return {
          id: conversation.id,
          conversation,
          participants: conversation.Participants.map((participant) => participant.Username),
        };
      });

      const participantsSorted = _.sortBy(participants);

      let conversationIndex = flattenConversations.findIndex((conversation) =>
        _.isEqual(_.sortBy(conversation.participants), participantsSorted)
      );

      if (conversationIndex >= 0) {
        return chatConversations.value[conversationIndex];
      }
      return null;
    };
    const getMessagesByConversationId = (conversationId) => {
      const index = chatConversations.value.findIndex((conversation) => conversation.id === conversationId);

      if (index >= 0) {
        return _.get(chatConversations.value[index], "Messages", []);
      }
      return [];
    };
    const quickResponsesItems = computed(() => quickResponses.value.items || []);
    const quickResponsesIsEnabled = computed(() => quickResponses.value.isEnabled || false);

    const getChatConversations = computed(() => chatConversations.value);
    const getRecentUsers = computed(() => recentUsers.value);

    async function getSupportAI(payload) {
      try {
        let suggestionsResponse = await getChatSuggestionsQuery({ question: payload?.question, isSummary: payload?.isSummary });
        setSuggestions(suggestionsResponse.getChatSuggestions.items || []);
      } catch (error) {
        let errorCode = error.toString().split(" ")[1];
        uiStore.messageEndpointError({ message: i18n.t(`engage.${errorCode}`) });
        setSuggestions([]);
        console.error(`Error store chat listSuggestions`, error);
      }
    }

    function setDrawer(payload) {
      setDrawerMutation(payload);
    }
    function setUserSelected(payload) {
      setUserSelectedMutation(payload);
    }
    async function onNewAgentChatParticipantEvent({ InstanceId, Username }) {
      const observable = await newAgentChatParticipantEventSubscription({
        InstanceId,
        Username,
      });

      const handler = {
        next: async (eventData) => {
          let newAgentChatParticipantEventResponse = null;
          try {
            newAgentChatParticipantEventResponse = eventData.value.data.newAgentChatParticipantEvent;

            const response = await getAgentChatConversationQuery({
              InstanceId,
              id: newAgentChatParticipantEventResponse.ConversationId,
            });

            const newConversation = _.get(response, "getAgentChatConversation");

            addNewConversation({
              InstanceId,
              id: newConversation.id,
              Messages: null,
              Participants:
                newConversation.Participants.map((x) => {
                  return {
                    ConversationId: null,
                    IsTyping: false,
                    Username: x.Username,
                  };
                }) || [],
              EventType: null,
            });

            subscribeToNewAgentChatMessageEventReceiver({
              Username,
              InstanceId,
            });
            return Promise.resolve(newAgentChatParticipantEventResponse);
          } catch (ex) {
            return Promise.reject(ex);
          }
        },
      };

      indexStore.addSubscriptionToList({ id: "onNewAgentChatParticipantEvent", observable, handler });
    }

    function subscribeToNewAgentChatMessageEvent({ InstanceId, conversationIds }) {
      for (let i = 0; i < conversationIds.length; i++) {
        let conversationId = conversationIds[i];

        const observable = newAgentChatMessageEventSubscription({
          InstanceId,
          ConversationId: conversationId,
        });

        const handler = {
          next: (eventData) => {
            const newMessage = eventData.value.data.newAgentChatMessageEvent;
            const author = newMessage.Author;
            const currentUser = _.get(currentUserStore.getProfileData, "Username");

            if (author === currentUser) {
              return;
            }

            if (newMessage.EventType === "Update") {
              updateAgentChatMessage({
                conversationId: _.get(newMessage, "ConversationId"),
                tempMessageId: _.get(newMessage, "id"),
                newMessage: {
                  ...newMessage,
                },
              });
            } else if (newMessage.EventType === "Delete") {
              deleteAgentChatMessage({
                conversationId: _.get(newMessage, "ConversationId"),
                tempMessageId: _.get(newMessage, "id"),
              });
            } else {
              const currentSentAt = new Date(newMessage.SentAt);
              const newSentAt = addSeconds(currentSentAt, 1);
              addNewChatMessage({
                conversationId: _.get(newMessage, "ConversationId"),
                message: { ...newMessage, SentAt: newSentAt },
              });

              updateConversationUpdatedAt({
                conversationId: _.get(newMessage, "ConversationId"),
              });

              teamStore.setUserUnreadMessageStatus({
                Username: author,
              });

              markMessageReadIfOpen({
                InstanceId,
                newMessage: newMessage,
              });
              playRingtone();
            }

            return Promise.resolve(newMessage);
          },
        };

        indexStore.addSubscriptionToList({
          id: `subscribeToNewAgentChatMessageEvent:${conversationId}`,
          observable,
          handler,
        });
      }
    }

    function subscribeToNewAgentChatMessageEventReceiver({ InstanceId, Username }) {
      const observable = newAgentChatMessageEventReceiverSubscription({
        InstanceId,
        Receiver: Username,
      });

      const handler = {
        next: (eventData) => {
          const newMessage = eventData.value.data.newAgentChatMessageEventReceiver;
          const author = newMessage.Author;
          const currentUser = _.get(currentUserStore.getProfileData, "Username");

          if (author === currentUser) {
            return;
          }

          if (newMessage.EventType === "Update") {
            updateAgentChatMessage({
              conversationId: _.get(newMessage, "ConversationId"),
              tempMessageId: _.get(newMessage, "id"),
              newMessage: {
                ...newMessage,
              },
            });
          } else if (newMessage.EventType === "Delete") {
            deleteAgentChatMessage({
              conversationId: _.get(newMessage, "ConversationId"),
              tempMessageId: _.get(newMessage, "id"),
            });
          } else {
            const currentSentAt = new Date(newMessage.SentAt);
            const newSentAt = addSeconds(currentSentAt, 1);
            addNewChatMessage({
              conversationId: _.get(newMessage, "ConversationId"),
              message: { ...newMessage, SentAt: newSentAt },
            });

            updateConversationUpdatedAt({
              conversationId: _.get(newMessage, "ConversationId"),
            });

            teamStore.setUserUnreadMessageStatus({
              Username: author,
              Key: true,
            });

            markMessageReadIfOpen({
              InstanceId,
              newMessage: newMessage,
            });
            playRingtone();
          }

          return Promise.resolve(newMessage);
        },
      };

      indexStore.addSubscriptionToList({
        id: `subscribeToNewAgentChatMessageEvent:${Username}`,
        observable,
        handler,
      });
    }
    async function onFetchAgentChatConversations({ InstanceId, Participants, Username }) {
      const res = await listAgentChatConversationsQuery({
        InstanceId,
        Participants,
      });

      setConversations(_.get(res, "listAgentChatConversations.items", []));
      if (chatConversations.value) {
        subscribeToNewAgentChatMessageEventReceiver({
          Username,
          InstanceId,
        });
        setConversationCount({
          chatConversations: chatConversations.value,
        });
        updateRecentUsers({ username: Username });
      }
      return res;
    }

    async function createAgentChatConversation({ participants, InstanceId }) {
      const newParticipants = participants.map((participant) => {
        return {
          Username: participant,
          IsTyping: false,
        };
      });
      const res = await CreateAgentChatConversationMutation({
        InstanceId,
        Participants: newParticipants,
      });

      addNewConversation(res?.createAgentChatConversation);
      return res;
    }

    function updateRecentUsers({ username }) {
      const currentDay = getTimeObject().offsetDayToISOString();
      let usersFromToday = [];
      const getDay = (UpdatedAt) => {
        if (UpdatedAt) {
          return UpdatedAt.split("T")[0];
        }
        return null;
      };
      if (chatConversations.value.length) {
        let usersByUpdated = chatConversations.value.filter((conversation) => conversation.UpdatedAt !== null);
        usersFromToday = usersByUpdated.filter((conversation) => getDay(conversation.UpdatedAt) === currentDay);

        let listOfUsername = usersFromToday.map((conversation) => conversation.Participants).flat();
        let useresFiltered = listOfUsername.filter(
          (participant) => participant.Username !== username && participant.Username !== null
        );
        setRecentUsers(useresFiltered);
      }
    }
    async function loadAgentChatMessages({ conversationId, InstanceId }) {
      const res = await listAgentChatMessagesQuery({
        InstanceId: InstanceId,
        ConversationId: conversationId,
      });

      setMessages({
        conversationId,
        messages: _.get(res, "listAgentChatMessages.items"),
      });
      setConversationLoaded({
        conversationId,
      });
      return res;
    }

    async function getAvatar({ InstanceId, Username }) {
      const res = await getAvatarQuery({
        InstanceId,
        Username,
      });

      return res;
    }

    function markMessageReadIfOpen({ newMessage }) {
      const author = newMessage.Author;

      let conversationId = newMessage.ConversationId;

      let userSelected = viewUser.value;

      if (conversationId && userSelected && userSelected.Username === author) {
        markSingleMessageRead({
          InstanceId: currentUserStore.getProfileData?.InstanceId,
          Author: newMessage.Author,
          ConversationId: conversationId,
          MessageId: newMessage.id,
          ContentType: newMessage.ContentType,
          Body: newMessage.Body,
        });
      }
    }

    function markSingleMessageRead({ InstanceId, ConversationId, MessageId, ContentType, Author, Body }) {
      return UpdateAgentChatMessageMutation({
        InstanceId,
        ConversationId,
        ContentType,
        Author,
        Body,
        id: MessageId,
        MessageStatus: "read",
      });
    }
    async function markAllMessagesRead({ InstanceId, ConversationId }) {
      const payload = {
        InstanceId,
        ConversationId,
      };

      const res = await MarkAllMessagesReadMutation(payload);
      return res;
    }

    function setConversationCount({ chatConversations }) {
      const currentUser = currentUserStore.getProfileData?.Username;

      chatConversations.forEach((conversation) => {
        let unreadMessages = conversation.UnreadMessages || [];

        let currentUserUnreadMessages = unreadMessages.filter((message) => message.Author !== currentUser);

        if (currentUserUnreadMessages.length > 0) {
          teamStore.setUserUnreadMessageStatus({
            Username: currentUserUnreadMessages[0].Author,
            SetCount: currentUserUnreadMessages.length,
          });
        }
      });
    }

    function playRingtone() {
      const ringToneName = _.get(apiStore.getApi, "getUser.Preferences.AgentMessageTone") || "default";

      const ringTone = availableTones[ringToneName] || ClassicRingtone;

      playAudioFile(ringTone);
    }

    async function setQuickResponses({ QuickResponses }) {
      const quickResponsesData = await fetchQuickResponses({ QuickResponses });
      const { isEnabled = false, quickResponses = [] } = quickResponsesData;
      const quickResponsesCleaned = cleanQuickResponsesData({ quickResponses });

      setQuickResponsesIsEnabled({ isEnabled });
      setQuickResponsesItems({ items: quickResponsesCleaned });
    }

    function setSuggestions(payload) {
      suggestions.value = payload;
    }

    function setDrawerMutation(payload) {
      showChatDrawer.value = payload;
    }

    function setUserSelectedMutation(payload) {
      userSelected.value = payload;
    }

    function updateUserSelected(payload) {
      if (!payload || payload.length === undefined) return;

      let updatedUser = userSelected.value;

      payload.forEach((user) => {
        if (userSelected.value && userSelected.value.Username === user.Username) {
          updatedUser = { ...userSelected.value, ...user };
        }
      });

      userSelected.value = updatedUser;
    }

    function showProfileDetailMutation(payload) {
      showProfileDetail.value = payload;
    }
    function setConversations(payload) {
      chatConversations.value = payload;
    }

    function addNewConversation(payload) {
      if (chatConversations.value.find((x) => x.id === payload.id)) {
        return;
      }
      chatConversations.value.push(payload);
    }

    function addNewChatMessage({ conversationId, message }) {
      let conversationIndex = chatConversations.value.findIndex((conversation) => conversation.id === conversationId);

      if (conversationIndex >= 0) {
        let currentMessages = _.get(chatConversations.value[conversationIndex], "Messages", []);

        if (!currentMessages || currentMessages.length === 0) {
          Vue.set(chatConversations.value[conversationIndex], "Messages", [message]);
        } else {
          let foundIndex = currentMessages.findIndex((e) => e.id === message.id);

          if (foundIndex >= 0) {
            chatConversations.value[conversationIndex].Messages.splice(foundIndex, 1, message);
          } else {
            Vue.set(chatConversations.value[conversationIndex], "Messages", [...currentMessages, message]);
          }
        }
      }
    }

    function updateAgentChatMessage({ tempMessageId, conversationId, newMessage }) {
      let conversationIndex = chatConversations.value.findIndex((conversation) => conversation.id === conversationId);

      if (conversationIndex >= 0) {
        let conversationMessages = _.get(chatConversations.value[conversationIndex], "Messages", []);

        let foundIndex = conversationMessages.findIndex((x) => x.id === tempMessageId);

        if (foundIndex === -1) return;
        conversationMessages.splice(foundIndex, 1, newMessage);
        Vue.set(chatConversations.value[conversationIndex], "Messages", conversationMessages);
      }
    }

    function deleteAgentChatMessage({ tempMessageId, conversationId }) {
      let conversationIndex = chatConversations.value.findIndex((conversation) => conversation.id === conversationId);

      if (conversationIndex >= 0) {
        let conversationMessages = _.get(chatConversations.value[conversationIndex], "Messages", []);

        let foundIndex = conversationMessages.findIndex((x) => x.id === tempMessageId);

        if (foundIndex === -1) return;
        conversationMessages.splice(foundIndex, 1);
        Vue.set(chatConversations.value[conversationIndex], "Messages", conversationMessages);
      }
    }
    function updateConversationUpdatedAt({ conversationId }) {
      let foundIndex = chatConversations.value.findIndex((x) => x.id === conversationId);
      if (foundIndex === -1) return;
      let newConversation = {
        ...chatConversations.value[foundIndex],
        UpdatedAt: format(getTimeObject().getOffsetTime(), "YYYY-MM-DDTHH:mm:ss.SSSZ"),
      };
      Vue.set(chatConversations.value, foundIndex, newConversation);
    }

    function setConversationLoaded({ conversationId }) {
      let foundIndex = chatConversations.value.findIndex((x) => x.id === conversationId);
      if (foundIndex === -1) return;
      let newConversation = {
        ...chatConversations.value[foundIndex],
        IsLoaded: true,
      };
      Vue.set(chatConversations.value, foundIndex, newConversation);
    }

    function initChat() {
      showChatDrawer.value = false;
      userSelected.value = {};
      showProfileDetail.value = false;
      chatConversations.value = [];
      messages.value = {};
    }

    function updateState(value) {
      showChatDrawer.value = value.showChatDrawer;
      userSelected.value = value.userSelected;
      showProfileDetail.value = value.showProfileDetail;
      chatConversations.value = value.chatConversations;
      messages.value = value.messages;
    }

    function setQuickResponsesIsEnabled({ isEnabled }) {
      quickResponses.value.isEnabled = isEnabled;
    }

    function setQuickResponsesItems({ items }) {
      quickResponses.value.items = items;
    }
    function setRecentUsers(payload) {
      recentUsers.value = payload;
    }
    function setMessages(payload) {
      messages.value = payload;
    }

    const cleanQuickResponsesData = ({ quickResponses }) => {
      return quickResponses.map((result) => ({
        content: result.contents.plainText.content,
        markdown: result.contents.markdown.content,
        quickResponseId: result.quickResponseId,
      }));
    };
    return {
      //States
      showChatDrawer,
      userSelected,
      showProfileDetail,
      chatConversations,
      messages,
      suggestions,
      quickResponses,
      recentUsers,
      //Getters
      showSuggestions,
      showChatDrawerGetter,
      viewUser,
      showDetails,
      getConversationByParticipants,
      getMessagesByConversationId,
      quickResponsesItems,
      quickResponsesIsEnabled,
      getChatConversations,
      getRecentUsers,
      //Actions
      getSupportAI,
      setDrawer,
      setUserSelected,
      onNewAgentChatParticipantEvent,
      subscribeToNewAgentChatMessageEvent,
      subscribeToNewAgentChatMessageEventReceiver,
      onFetchAgentChatConversations,
      createAgentChatConversation,
      updateRecentUsers,
      loadAgentChatMessages,
      getAvatar,
      markMessageReadIfOpen,
      markSingleMessageRead,
      markAllMessagesRead,
      setConversationCount,
      playRingtone,
      setQuickResponses,
      //Mutations
      setSuggestions,
      setDrawerMutation,
      setUserSelectedMutation,
      updateUserSelected,
      showProfileDetailMutation,
      setConversations,
      addNewConversation,
      addNewChatMessage,
      updateAgentChatMessage,
      deleteAgentChatMessage,
      updateConversationUpdatedAt,
      setConversationLoaded,
      initChat,
      updateState,
      setQuickResponsesIsEnabled,
      setQuickResponsesItems,
      setRecentUsers,
      setMessages,
    };
  },
  {
    persist: true,
  }
);
